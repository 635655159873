import React, { CSSProperties, useContext, useMemo, useRef } from "react";
import Tabs from "../../design-system-components/Tabs/Tabs";
import { useIntl, defineMessages } from "react-intl";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import {
    NotificationAggregate,
    RawNotification,
    NotificationStatus,
    StudentInfo,
} from "./Types";
import Loader from "../Loader/Loader";
import { useNotificationStatusMutation } from "./api/useMutations";
import useNotifications, { insertResourceNames } from "./useNotifications";
import { useQueryClient } from "@tanstack/react-query";
import { useUserType } from "../../hooks/useUserInfo";
import { UserType } from "../../interfaces/User";
import classNames from "classnames";
import { queryKeys } from "./api/queryKeys";
import * as Sentry from "@sentry/react";
import NotificationsTab from "./NotificationsTab/NotificationsTab";
import { Item } from "react-stately";
import { TabKeys, useNotificationsTabs } from "./useNotificationsTabs";
import { getUserColor } from "./utils";
import { dataStore } from "../../contexts/DataContext";

import "./Notifications.scss";

interface Props {
    list: NotificationAggregate[];
    headerId: string;
}

const Notifications = ({ list, headerId }: Props) => {
    const {
        data: { modules },
    } = useContext(dataStore);
    const {
        isSuccess,
        isLoading,
        playlistsResult,
        duosResult,
        workshopsAssignments,
    } = useNotifications();
    const intl = useIntl();
    const { mutateAsync } = useNotificationStatusMutation();
    const queryClient = useQueryClient();
    const notificationsList = useMemo(
        () =>
            insertResourceNames(
                list,
                playlistsResult,
                duosResult,
                workshopsAssignments,
                modules
            ),
        [list, playlistsResult, duosResult, workshopsAssignments, modules]
    );

    const userType = useUserType();
    const userColor = getUserColor(userType);
    const notificationReadStatusChange = async (
        id: string | undefined,
        students: StudentInfo[],
        status: NotificationStatus
    ) => {
        if (!id) return;
        const mutationPromises = students.map((student) => {
            return mutateAsync({
                notificationId: student.notificationId,
                status: status,
            });
        });
        await Promise.all(mutationPromises);
        queryClient.invalidateQueries(queryKeys.getNotifications());
    };
    const onError = (error: unknown) => {
        console.error(error);
        Sentry.captureException(error);
    };

    const { clearReadFromUnreadTab, ...tabsProps } = useNotificationsTabs(
        notificationsList,
        notificationReadStatusChange,
        onError
    );

    const markAllNotificationsAsRead = async (opts?: { clear?: boolean }) => {
        const notificationsToUpdate = notificationsList.filter(
            (nl) => nl.status !== NotificationStatus.READ
        );
        if (notificationsToUpdate.length === 0) return;
        const mutationPromises: Promise<RawNotification>[] = [];
        notificationsToUpdate.forEach((n) => {
            n.students.forEach((student) => {
                mutationPromises.push(
                    mutateAsync({
                        notificationId: student.notificationId,
                        status: NotificationStatus.READ,
                    })
                );
            });
        });
        try {
            await Promise.all(mutationPromises);
            queryClient.invalidateQueries(queryKeys.getNotifications());
            if (opts?.clear)
                // Ignore current state because it is not synced yet
                clearReadFromUnreadTab({ ignoreCurrentState: true });
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
    };

    const tabsPropsInOrder =
        userType === UserType.Teacher
            ? [tabsProps[TabKeys.unread], tabsProps[TabKeys.all]]
            : [tabsProps[TabKeys.all], tabsProps[TabKeys.unread]];

    const previousTabRef = useRef<TabKeys>();
    const handleTabChange = (tabKey: React.Key) => {
        if (previousTabRef.current === tabKey) return;

        if (
            tabKey === TabKeys.all &&
            previousTabRef.current === TabKeys.unread
        ) {
            clearReadFromUnreadTab();
        } else if (tabKey === TabKeys.unread) {
            markAllNotificationsAsRead();
        }
        previousTabRef.current = tabKey as TabKeys;
    };

    return (
        <article
            className="notifications"
            style={{ "--user-color": userColor } as CSSProperties}
        >
            <header>
                <h2 id={headerId}>
                    {intl.formatMessage(messages.notifications)}
                </h2>
                {notificationsList.filter(
                    (n) => n.status === NotificationStatus.CREATED
                ).length > 0 && (
                    <button
                        onClick={() => {
                            markAllNotificationsAsRead({ clear: true });
                        }}
                        className={classNames({
                            "--teacher-color": userType === UserType.Teacher,
                            "--student-color": userType === UserType.Student,
                        })}
                        style={{ color: getUserColor(userType) }}
                    >
                        <Icon path="notifs_markAll" />
                        {intl.formatMessage(messages.MarkAllAsRead)}
                    </button>
                )}
            </header>
            <Loader
                isLoading={isLoading}
                loadingMessage={intl.formatMessage(messages.loading)}
                loadedMessage={intl.formatMessage(messages.loaded)}
            >
                {isSuccess && (
                    <Tabs
                        onSelectionChange={handleTabChange}
                        variant="accent-border"
                        accentColor={userColor}
                    >
                        <Item {...tabsPropsInOrder[0].tab}>
                            <NotificationsTab
                                {...tabsPropsInOrder[0].content}
                            />
                        </Item>
                        <Item {...tabsPropsInOrder[1].tab}>
                            <NotificationsTab
                                {...tabsPropsInOrder[1].content}
                            />
                        </Item>
                    </Tabs>
                )}
            </Loader>
        </article>
    );
};

export default Notifications;

const messages = defineMessages({
    notifications: {
        id: "notifications",
        defaultMessage: "Notifications",
    },
    MarkAllAsRead: {
        id: "notifications-MarkAllAsRead",
        defaultMessage: "Mark all as read",
    },
    seeAll: {
        id: "notifications-seeAll",
        defaultMessage: "See all notifications",
    },
    read: {
        id: "notifications-read",
        defaultMessage: "read",
    },
    loading: {
        id: "notifications-loading",
        defaultMessage: "Loading notifications...",
    },
    loaded: {
        id: "notifications-loaded",
        defaultMessage: "Notifications loaded",
    },
});
