import { lastItem } from "../../utils/array-manipulations";
import { deepCompare } from "../../utils/compare";
import { HierarchyPath, TreeNode, Props } from "./types";

/**
 * Returns true if the props are considered to be the same. Uses React's usual
 * method by comparing object except for checkedLeaves which goes through a
 * deep compare to avoid unnecessary renderings if the list of checked nodes
 * hasn't changed for a specific branch
 */
export const areSameProps = <
    T extends Pick<Props, "checkedLeaves" | "expandedNodes">
>(
    {
        checkedLeaves: prevCheckedLeaves,
        expandedNodes: prevExpandedNodes = [],
        ...prevProps
    }: T,
    {
        checkedLeaves: nextCheckedLeaves,
        expandedNodes: nextExpandedNodes = [],
        ...nextProps
    }: T
): boolean => {
    const sameCheckedLeaves =
        [...prevCheckedLeaves].sort().join("") ===
        [...nextCheckedLeaves].sort().join("");
    const sameExpandedNodes =
        [...prevExpandedNodes].sort().join("") ===
        [...nextExpandedNodes].sort().join("");
    const sameProps =
        Object.entries(prevProps).length === Object.entries(nextProps).length &&
        Object.entries(prevProps).every(([key, value]) =>
            Object.is(
                nextProps[
                    key as keyof Omit<T, "checkedLeaves" | "expandedNodes">
                ],
                value
            )
        );
    return sameProps && sameCheckedLeaves && sameExpandedNodes;
};

export const getNodeCheckboxId = (nodePath: HierarchyPath) =>
    `tree-checkbox-${nodePath}`;

export const getAccordionHeaderId = (nodePath: HierarchyPath) =>
    `tree-checkbox-accordion-header-${nodePath}`;

export const isLeaf = (node: TreeNode): boolean => !node.childrenIds;

export const getChildLeaves = (
    currentNode: TreeNode,
    nodes: Record<string, TreeNode>,
    parentHierarchyPath?: HierarchyPath
): (TreeNode & { hierarchyPath: HierarchyPath })[] => {
    if (isLeaf(currentNode))
        return [
            {
                ...currentNode,
                hierarchyPath: makeHierarchyPath(
                    parentHierarchyPath,
                    nodePath(currentNode)
                ),
            },
        ];
    return currentNode.childrenIds!.flatMap((childId) => {
        const child = nodes[childId];
        if (!child) throw new Error("Child not found");
        return getChildLeaves(
            child,
            nodes,
            makeHierarchyPath(parentHierarchyPath, nodePath(currentNode))
        );
    });
};

export const HIERARCHY_PATH_SEPARATOR = "/";

export const makeHierarchyPath = (
    ...args: (string | undefined | HierarchyPath)[]
): string => args.filter((arg) => !!arg).join(HIERARCHY_PATH_SEPARATOR);

export const hierarchyPathToIds = (path: HierarchyPath): string[] =>
    path.split(HIERARCHY_PATH_SEPARATOR);

export const changeLeafCheckStatus = (
    checkedLeaves: HierarchyPath[],
    leafPath: HierarchyPath
): HierarchyPath[] => {
    if (checkedLeaves.includes(leafPath))
        return checkedLeaves.filter((path) => path !== leafPath);
    return [...checkedLeaves, leafPath];
};

export const getBranchCheckedStatus = (
    childLeaves: (TreeNode & { hierarchyPath: HierarchyPath })[],
    checkedLeaves: HierarchyPath[]
): boolean | "mixed" => {
    if (childLeaves.length === 0) return false;
    const checkedChildLeaves = childLeaves.filter((leaf) =>
        checkedLeaves.includes(leaf.hierarchyPath)
    );
    if (childLeaves.length === checkedChildLeaves.length) return true;
    if (checkedChildLeaves.length > 0) return "mixed";
    return false;
};

export const changeBranchCheckStatus = (
    checkedLeaves: HierarchyPath[],
    branch: TreeNode,
    parentPath: HierarchyPath,
    nodes: Record<string, TreeNode>
): HierarchyPath[] => {
    const childLeaves = getChildLeaves(branch, nodes, parentPath);
    const checked = getBranchCheckedStatus(childLeaves, checkedLeaves);
    switch (checked) {
        case true: {
            return checkedLeaves.filter((nodePath) =>
                childLeaves.every((child) => nodePath !== child.hierarchyPath)
            );
        }
        case false: {
            return [
                ...checkedLeaves,
                ...childLeaves.map((child) => child.hierarchyPath),
            ];
        }
        case "mixed": {
            const unique = new Set<HierarchyPath>();
            checkedLeaves.forEach((path) => {
                unique.add(path);
            });
            childLeaves.forEach((path) => {
                unique.add(path.hierarchyPath);
            });
            return Array.from(unique);
        }
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = checked;
            throw new Error("Unhandled");
        }
    }
};

export const getExtremity = <
    T extends Pick<TreeNode, "id" | "childrenIds" | "idSuffix" | "idPrefix">
>(
    path: HierarchyPath,
    nodes: Record<string, T>,
    suffix: string[] = []
): T => {
    if (!path) throw new Error("Child not found");
    const extremityId = lastItem(hierarchyPathToIds(path));
    const extremity = nodes[extremityId];
    if (!extremity) {
        const ids = hierarchyPathToIds(path);
        if (ids.length === 1) throw new Error("Child not found");
        const pathUp = makeHierarchyPath(...ids.slice(0, -1));
        const possibleSuffix = lastItem(ids);
        return getExtremity(pathUp, nodes, [possibleSuffix, ...suffix]);
    }
    if (extremity && !deepCompare(extremity.idSuffix ?? [], suffix))
        throw new Error("Child not found");
    return extremity;
};

export const setCheckedStatusFalseOnAllBranches = (
    nodesToUncheck: string[],
    checkedLeaves: HierarchyPath[],
    nodes: Record<string, TreeNode>
): HierarchyPath[] => {
    return checkedLeaves.filter((checkedLeaf) => {
        const checkedLeafId = getExtremity(checkedLeaf, nodes).id;
        return nodesToUncheck.every((nodeId) => checkedLeafId !== nodeId);
    });
};

export const nodePathsToUniqueLeafId = (
    paths: HierarchyPath[],
    nodes: Record<string, TreeNode>
): string[] => {
    const ids = new Set<string>();
    paths.forEach((path) => {
        ids.add(getExtremity(path, nodes).id);
    });
    return Array.from(ids);
};

export const setCheckedStatusTrueOnAllBranches = (
    nodesToCheck: string[],
    checkedLeaves: HierarchyPath[],
    nodes: Record<string, TreeNode>
): HierarchyPath[] => {
    return [
        ...checkedLeaves,
        ...nodesToCheck.flatMap((nodeToCheck) =>
            getPossibleHierarchyPaths(nodeToCheck, nodes)
        ),
    ];
};

export const getPossibleHierarchyPaths = (
    path: HierarchyPath,
    nodes: Record<
        string,
        Pick<TreeNode, "childrenIds" | "id" | "idPrefix" | "idSuffix">
    >
): HierarchyPath[] => {
    const localRoot = getRoot(path, nodes);
    const parents = Object.values(nodes).filter((node) =>
        node.childrenIds?.includes(localRoot.id)
    );
    if (parents.length === 0) return [path];
    return parents.flatMap((parent) =>
        getPossibleHierarchyPaths(
            makeHierarchyPath(nodePath(parent), path),
            nodes
        )
    );
};

export const nodePath = (
    node: Pick<TreeNode, "id" | "idSuffix" | "idPrefix">
) =>
    makeHierarchyPath(
        ...(node.idPrefix ?? []),
        node.id,
        ...(node.idSuffix ?? [])
    );

const getRoot = <T extends Pick<TreeNode, "childrenIds" | "id" | "idPrefix">>(
    path: HierarchyPath,
    nodes: Record<string, T>,
    prefix: string[] = []
): T => {
    if (!path) throw new Error("Cannot find root");
    const [possibleRootId, ...ids] = hierarchyPathToIds(path);
    const possibleRoot = nodes[possibleRootId];
    if (!possibleRoot) {
        if (ids.length === 0) throw new Error("Cannot find root");
        return getRoot(makeHierarchyPath(...ids), nodes, [
            possibleRootId,
            ...prefix,
        ]);
    }
    if (!deepCompare(prefix, possibleRoot.idPrefix ?? []))
        throw new Error("Cannot find root");
    return possibleRoot;
};

export const getNodeLabel = (
    node: Pick<TreeNode, "label" | "labelFormatter">,
    index: number
): string => {
    if (node.labelFormatter) return node.labelFormatter(node.label, index);
    return node.label;
};

export const isNodeInBranch =
    (branchPath: HierarchyPath) => (nodePath: HierarchyPath) =>
        nodePath.startsWith(branchPath);
