import { UserExtra } from "../../interfaces/User";
import { PadAssignment } from "../../pages/Workmodes/Workshop/types";

export enum NotificationStatus {
    CREATED = "created",
    READ = "read",
}

export enum RawNotificationType {
    RESOURCE_ASSIGNED = "resource_assigned",
    ASSIGNMENT_COMPLETED = "assignment_completed",
    MULTIPLAYER_SESSION_CREATED = "multiplayer_session_created",
    MULTIPLAYER_SESSION_DONE = "multiplayer_session_done",
}

export enum NotificationType {
    ASSIGNED = "assigned",
    DONE = "assignment_completed",
    MULTIPLAYER_SESSION_DONE = "multiplayer_session_done",
}

export enum ResourceType {
    PLAYLIST = "playlist",
    WORKSHOP = "workshop",
    ADAPTIVE_SET = "adaptive_set",
    MODULE = "module",
    TUTORIAL = "tutorial",
    OTHER = "other",
    DUO = "multi_player_session",
}
export interface StudentInfo {
    notificationId: string;
    firstname: string;
    lastname: string;
    extra?: Partial<UserExtra>;
}
export interface Resource {
    type: ResourceType;
    name?: string;
    extra?: any;
}
export interface NotificationContent {
    resource?: Resource;
    type: NotificationType;
}
export interface NotificationAggregate {
    id: string; // same as the notified_content.resource_id of the RawNotification
    content: NotificationContent;
    timestamp: string;
    status: NotificationStatus;
    students: StudentInfo[];
}

type RawWorkmodeResourceNotificationContent = {
    student_id?: string;
    resource_id: string;
    resource_type: ResourceType;
};

type RawNotificationContent = RawWorkmodeResourceNotificationContent;

export type RawNotification<Content = RawNotificationContent> = {
    /** id of the notification */
    id: string;
    /** Recipient of the notification */
    notified_object: {
        id: string;
    };
    notified_content: {
        type: RawNotificationType;
        extra?: any;
    } & Content;
    status: NotificationStatus;
    created_at: string;
    extra: {
        testing: boolean; // TODO: document
    };
};

type NotficationIdentifiers = {
    id: string;
    author_id: string;
};

export type PadsNotifComplement = NotficationIdentifiers & {
    extra: PadAssignment;
};
