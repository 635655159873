import React, { CSSProperties, memo } from "react";
import { Props } from "./types";
import TreeCheckboxGroupLevel from "./Components/TreeCheckboxGroupLevel";
import { areSameProps, nodePath } from "./utils";

import "./TreeCheckboxGroup.scss";

/**
 * Recursively renders a hierarchical list of items. Branches are placed in
 * nested accordions and associated with a checkbox. Items can appear in
 * multiple branches.
 */
const TreeCheckboxGroupRoot = ({
    rootChildrenIds,
    depth = 0,
    startingHeadingLevel = 3,
    checkedLeaves,
    accentColor,
    nodes,
    ...props
}: Props) => {
    return (
        <ol style={{ "--accent-color": accentColor } as CSSProperties}>
            {rootChildrenIds.map(
                (id, index) =>
                    nodes[id] && (
                        <li key={id}>
                            <TreeCheckboxGroupLevel
                                {...props}
                                depth={depth}
                                startingHeadingLevel={startingHeadingLevel}
                                index={index}
                                currentId={id}
                                nodes={nodes}
                                checkedLeaves={checkedLeaves.filter((path) =>
                                    path.startsWith(nodePath(nodes[id]))
                                )}
                            />
                        </li>
                    )
            )}
        </ol>
    );
};
export default memo(TreeCheckboxGroupRoot, areSameProps);
