import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";
import { UserType } from "../interfaces/User";
import { v4 as uuid } from "uuid";
import Session, { StatementHistory } from "../interfaces/Session";
import { ThemeType } from "../interfaces/Theme";
interface SessionContext {
    session: Session;
    setSession: Dispatch<SetStateAction<Session>>;
}

const initialState: Session = {
    sessionId: uuid(),
    userId: "",
    firstname: "",
    lastname: "",
    userProvider: "",
    appProvider: "",
    appVariation: "",
    extra: {},
    statementsFetched: false,
    userType: undefined as any as UserType,
    evidencebId: "",
    app: "",
    soloAI: undefined,
    version: "",
    theme: ThemeType.Light,
    dismissMessages: {
        prmObjectiveLockingConfirmationModal: false,
        prmObjectiveUnlockingConfirmationModal: false,
        specimenGetFullVersionBanner: false,
        specimenSwitchUserModal: false,
        specimenTeacherDashboardModal: false,
        playlistRemoveStudentFromRecipients: false,
        offlineBanner: false,
        deppTestManual: false,
        dahsboardHelpInfo: false,
    },
    flags: {
        useHistoryFrom: StatementHistory.LRS,
        displaySignIn: false,
        logExerciseDetails: false,
    },
    fragments: [],
    athenaEvents: [],
};

// Context init
const sessionStore = createContext<SessionContext>({
    session: initialState,
    setSession: () => null,
});

// Provider init
const { Provider } = sessionStore;

// Final setup
const SessionProvider = ({ children }: { children: React.ReactNode }) => {
    const [session, setSession] = useState(initialState);
    return <Provider value={{ session, setSession }}>{children}</Provider>;
};

export { sessionStore, SessionProvider };
