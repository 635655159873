import { DEPPTest } from "../pages/DashBoard/StudentDashboard/Assessment/DEPPTest/types";
import { PRLockStatus } from "./PedagogicalResourcesManagement";
import { DismissableMessages } from "./Session";

// These interfaces are responses from the backend

export interface TokenPayload {
    app: string;
    branch: string;
    role: "teacher" | "student";
    sub: string; // id
    version: string;
}
export interface UserExtra {
    type?: string;
    /** Corresponds to id in mapping tree */
    subjects?: string[];
    activeSubject?: string;
    deppTest?: DEPPTest;
    avatarCharacter?: { src: string; alt: string };
    avatarBackground?: { code: string; alt: string };
    dismissedMessages?: DismissableMessages[];
}

//
export type RawExtra = Partial<UserExtra> | "none" | "null";

export interface User {
    id: string;
    external_id: string;
    provider: string;
    first_name: string;
    last_name: string;
    /**
     * Key-value store related to the user. Can be updated freely without
     * requiring new backend development (see useUserExtra))
     */
    extra?: RawExtra;
    variation?: string;
    config: {
        fragments_config: string[];
    };
    school?: string;
}

export interface Student extends User {
    /**
     * Tag to tell the backend to create a code for the user when creating one
     */
    createCode?: boolean;
    code?: string;
    classrooms: string[];
    groups: string[];
    mentor_sessions?: DuoSession[];
    mentee_sessions?: DuoSession[];
    resources_assignments?: Assignment[];
}
export interface Teacher extends User {
    classrooms: Group[];
    groups: Group[];
}

export type Group = {
    id: string;

    name: string;
    lockStatus: Partial<PRLockStatus>;
    students: TeacherStudent[];
    external_id: string;
};
export type TeacherStudent = {
    id: string;
    first_name: string;
    last_name: string;
    mentor_sessions?: DuoSession[];
    mentee_sessions?: DuoSession[];
    teacher: string;
    classrooms: string[];
    extra?: RawExtra;
};
export type DuoSession = {
    id: string;
    name: string;
    mentor: DuoParticipant;
    mentee: DuoParticipant;
    teacher: string;
    status: "inactive" | "active" | "closed";
    config: {
        type: "multi_player_session";
        module_id: string;
    };
};
type DuoParticipant = {
    id: string;
    first_name: string;
    last_name: string;
};

export interface Classroom {
    id: string;
    external_id: string;
    provider: string;
    variation: string;
    name: string;
    level: string;
    school: string;
    students: Student[];
    teacher: string;
    lock_status: {
        moduleIds: string[];
        activityIds: string[];
        objectiveIds: string[];
    };
    extra: string;
    code: string;
}

export enum UserType {
    Teacher = "TEACHER",
    Student = "STUDENT",
}

export type SubjectColors = {
    default: string;
    hover: string;
    disabled: string;
    defaultDarkBg: string;
    disabledDarkBg: string;
};

export type Subject = {
    id: string;
    name: string;
    tags: string[];
    colors: SubjectColors;
};

export type Assignment = {
    id: string;
    students: { id: string; first_name: string; last_name: string }[];
    teacher: string;
    assigned_resource?: {
        resource_id: string;
        resource_type: string;
        extra: any;
    };
};
