import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { configStore } from "../../contexts/ConfigContext";
import { sessionStore } from "../../contexts/SessionContext";
import { UserType } from "../../interfaces/User";
import { useIntl } from "react-intl";
import SpecimenBanner from "../Specimen/SpecimenBanner/SpecimenBanner";

import { useFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";
import { Image } from "@evidenceb/athena-common/modules/Assets";
import { Button } from "@evidenceb/athena-common/design-system/Button";
import useAssetsDetails from "../../hooks/useAssetsDetails";
import { commonMessages } from "../../utils/messages";
import useBreakpoints from "../../hooks/useBreakpoints";
import NotificationsWidget from "../NotificationsWidget/NotificationsWidget";
import UserTypeSwitch from "./UserTypeSwitch/UserTypeSwitch";
import DesktopNav from "./DesktopNav/DesktopNav";
import { useUrl } from "../../hooks/usePage";
import { Pagetype } from "../../interfaces/Config";
import UserSettings from "./UserSettings/UserSettings";

import "./Header.scss";
import { isSpecimenVersion } from "../../utils/init";
import { SubjectSwitch } from "../SubjectSwitch/SubjectSwitch";

function Header() {
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const { isMobile } = useBreakpoints();
    const intl = useIntl();
    const assetsDetails = useAssetsDetails();
    const notificationsFlag = useFeatureFlag("notifications");
    const { pathname } = useLocation();
    const homeUrl = useUrl(Pagetype.HOME);

    if (pathname !== homeUrl && isMobile) return null;

    return (
        <>
            <header className="header-top">
                <div className="header__logo">
                    {session.userType === UserType.Teacher ? (
                        <Link to={`/`}>
                            <Image
                                src={config.logos.header_client}
                                assetsDetails={assetsDetails}
                                alt={intl.formatMessage(commonMessages.goHome)}
                            />
                        </Link>
                    ) : (
                        <Image
                            src={config.logos.header_client}
                            assetsDetails={assetsDetails}
                            alt={config.client_name}
                        />
                    )}
                </div>

                {config.features.backlinkURL && (
                    <Button
                        className="header__backlink"
                        asLink={{
                            href: config.features.backlinkURL,
                        }}
                        label={intl.formatMessage(
                            {
                                id: "backlink-label",
                                defaultMessage: "Go back to {appName}",
                            },
                            { appName: config.client_name }
                        )}
                        color="secondary"
                        variant="secondary"
                        icons={[
                            {
                                path: "arrow_right",
                                placement: "left",
                            },
                        ]}
                    />
                )}

                {!isMobile && <DesktopNav />}

                <div className="header__right-part">
                    {notificationsFlag && <NotificationsWidget />}

                    <UserTypeSwitch />
                    {isSpecimenVersion() &&
                        session.userType === UserType.Teacher && (
                            <SubjectSwitch />
                        )}
                    {!isSpecimenVersion() && <UserSettings />}
                </div>
            </header>
            {session.specimen && <SpecimenBanner />}
        </>
    );
}

export default Header;
