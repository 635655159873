import {
    ModuleStatus,
    ObjectiveStatus,
    ExerciceStatus,
    CacheStatus,
    CacheState,
    AssetStatus,
} from "./cacheState";

export interface CacheResult {
    cacheType: "module" | "objective";
    id: string;
    result: ModuleStatus | ObjectiveStatus;
}

export function openExerciceAssetsCache(): Promise<Cache> {
    return caches.open("exerciceAssets");
}

export function findModuleForObjectiveId(
    state: CacheState,
    objectiveId: string
): ModuleStatus | undefined {
    return state.modules.find((module) =>
        module.objectives.some((objective) => objective.id === objectiveId)
    );
}

export function buildModuleStatus(
    moduleId: string,
    objectiveStatuses: Array<ObjectiveStatus>
): ModuleStatus {
    let moduleStatus: CacheStatus;
    if (
        objectiveStatuses.every(
            (objectiveStatus) => objectiveStatus.status === "downloaded"
        )
    ) {
        moduleStatus = "downloaded";
    } else {
        moduleStatus = "not_downloaded";
    }

    return {
        id: moduleId,
        status: moduleStatus,
        objectives: objectiveStatuses,
    };
}

export function buildObjectiveStatus(
    objectiveId: string,
    exerciceStatuses: Array<ExerciceStatus>
): ObjectiveStatus {
    let objectiveStatus: CacheStatus;
    if (
        exerciceStatuses.every(
            (exerciceStatus) => exerciceStatus.status === "downloaded"
        )
    ) {
        objectiveStatus = "downloaded";
    } else {
        objectiveStatus = "not_downloaded";
    }

    return {
        id: objectiveId,
        status: objectiveStatus,
        exercices: exerciceStatuses,
    };
}

export function buildExerciceStatus(
    exerciceId: string,
    assetStatuses: Array<AssetStatus>
): ExerciceStatus {
    let exerciceStatus: CacheStatus;
    if (
        assetStatuses.every(
            (assetStatus) => assetStatus.status === "downloaded"
        )
    ) {
        exerciceStatus = "downloaded";
    } else {
        exerciceStatus = "not_downloaded";
    }

    return {
        id: exerciceId,
        status: exerciceStatus,
        assets: assetStatuses,
    };
}
