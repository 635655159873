import { useContext, useMemo } from "react";
import { useLang } from "./useConfig";
import {
    relativeTimeFormat,
    displayedDateFormat,
    duration,
} from "../utils/time";
import { configStore } from "../contexts/ConfigContext";
import { useIntl } from "react-intl";

const useTimeFormat = () => {
    const lang = useLang();
    const {
        config: { displayedDateFormat: format },
    } = useContext(configStore);
    const intl = useIntl();

    return useMemo(
        () => ({
            /**
             * Express time between now and the given date (rounding to the
             * nearest number of months/weeks/days/hours/minutes depending on
             * what is more appropriate)
             */
            relativeTimeFormat: relativeTimeFormat(lang),
            /**
             * Format date according to the format given in the config.json
             */
            displayedDateFormat: displayedDateFormat(format),
            /**
             * Express a duration in hours/minutes/seconds
             */
            duration: duration({
                seconds: intl.formatMessage({
                    id: "seconds-short",
                    defaultMessage: "sec",
                }),
                minutes: intl.formatMessage({
                    id: "minutes-short",
                    defaultMessage: "min",
                }),
                hours: intl.formatMessage({
                    id: "hours-short",
                    defaultMessage: "h",
                }),
            }),
        }),
        [lang, format, intl]
    );
};
export default useTimeFormat;
