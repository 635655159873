import axios from "axios";

import { MicroServices } from "../../interfaces/Config";
import { msConfigResolver } from "../init";
import * as localStorage from "../localStorage";
import Error from "../../pages/Error/Error";
import appConfig from "../../config";
import { isTokenInvalidatedError } from "../../hooks/queries/useAthenaAPIClient/utils";

export default class AxiosClient {
    private _apiUrls?: MicroServices;
    private _apiToken?: string;
    private _tokenInvalidatedInterceptor:
        | undefined
        | {
              id: number;
              callback: () => void;
          };
    public axios;

    get apiUrls(): MicroServices {
        if (!this._apiUrls) {
            console.log(
                "apiUrls is undefined! AthenaAPIClient is still initializing"
            );
            throw Error;
        }
        return this._apiUrls;
    }

    get apiToken(): string {
        if (!this._apiToken) {
            console.log("apiToken is undefined! App is still initializing");
            throw Error;
        }
        return this._apiToken;
    }

    set apiToken(apiToken: string) {
        this._apiToken = apiToken;
    }

    get tokenInvalidatedInterceptor() {
        return this._tokenInvalidatedInterceptor?.callback;
    }

    constructor() {
        this.axios = axios.create();
    }

    private async renewApiToken(error: any) {
        const originalRequest = error.config;

        if (
            originalRequest._retry ||
            originalRequest.url.includes(REFRESH_TOKEN_ROUTE) ||
            (error.response?.status !== 403 &&
                error.response?.status !== 401) ||
            isTokenInvalidatedError(error) ||
            error.response?.data?.detail?.message ===
                "Impossible to decode token with given secret"
        ) {
            throw error;
        }

        originalRequest._retry = true;
        const newApiToken = await this.getAndUpdateToken();
        originalRequest.headers["Authorization"] = `Bearer ${newApiToken}`;
        return this.axios.request(originalRequest);
    }

    async getAndUpdateToken(): Promise<string> {
        try {
            let headers = { Authorization: `Bearer ${this.apiToken}` };
            // URL set in .env for now
            const res = await this.axios.get(
                `${this.apiUrls.endpoints.auth}${REFRESH_TOKEN_ROUTE}`,
                {
                    headers: headers,
                }
            );
            let newToken = res.data.token;
            this._apiToken = newToken;
            localStorage.setItem(localStorage.Key.TOKEN, newToken);
            return res.data.token as Promise<string>;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async init(): Promise<MicroServices | any> {
        let origin = window.location.origin;
        try {
            const { data } = await this.axios.get(
                `${origin + (appConfig.basePath ?? "/")}json/msConfigs.json`
            );
            try {
                this._apiUrls = msConfigResolver(data, window.location.href);
            } catch (error) {
                return error;
            }
        } catch (error) {
            return error;
        }

        this.axios.interceptors.response.use(
            undefined,
            this.renewApiToken.bind(this)
        );
        return this._apiUrls;
    }

    updateTokenInvalidatedInterceptor(callback: () => void) {
        if (typeof this._tokenInvalidatedInterceptor !== "undefined") {
            this.axios.interceptors.response.eject(
                this._tokenInvalidatedInterceptor.id
            );
            this._tokenInvalidatedInterceptor = undefined;
        }
        const id = this.axios.interceptors.response.use(undefined, (error) => {
            if (isTokenInvalidatedError(error)) {
                callback();
            } else {
                throw error;
            }
        });
        this._tokenInvalidatedInterceptor = { id, callback };
    }
}

export const axiosClient = new AxiosClient();

const REFRESH_TOKEN_ROUTE = "/session/refresh-token";
