export enum KnownMappingNodeKeys {
    Variation = "variation",
    Subject = "Matière",
    Source = "Source",
}

export interface MappingNode {
    depth: number;
    id: string;
    key: KnownMappingNodeKeys | string;
    value: string;
    children: MappingNode[];
}

export const adaptiveTestResultNode = (
    moduleCode: string,
    testStatus: string
) => `${moduleCode}-TAI${testStatus}`;

export const objectiveBlockedResultNode = (objectiveCode: string) =>
    `${objectiveCode}-blocked`;
