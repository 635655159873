import React, { useMemo } from "react";
import pick from "lodash/pick";
import { useLayoutEffect, useState } from "react";
import useScrollDirection, {
    Opts as ScrollDirectionOpts,
    defaultOpts as defaultScrollDirectionOpts,
} from "../useScrollDirection";
import useAlphaNumId from "../useAlphaNumId";
import useDefault from "../useDefault";

type Options = {
    /**
     * Element on which the scroll listener should be applies
     * @default window
     */
    scrollElem: HTMLElement | Window;
    /**
     * Strategy to hide the element (targets the specified css property)
     * @default "transform"
     */
    strategy: "transform" | "height";
} & Pick<ScrollDirectionOpts, "forceScrollDetection">;
const defaultOptions: Options = {
    ...pick(defaultScrollDirectionOpts, ["forceScrollDetection"]),
    strategy: "transform",
    scrollElem: window,
};

const useHideOnScroll = (
    /**
     * Ref to the element to hide on scroll
     */
    ref: React.RefObject<HTMLDivElement>,
    options?: Partial<Options>
): { styles: React.CSSProperties; reset: () => void } => {
    const id = useAlphaNumId();
    const { strategy, scrollElem, forceScrollDetection } = useDefault(
        options,
        defaultOptions
    );
    const scroll = useScrollDirection(scrollElem, {
        forceScrollDetection,
    });
    const [height, setHeight] = useState<number>();

    useLayoutEffect(() => {
        if (height) {
            const updateHeight = () => {
                if (ref.current) setHeight(ref.current.offsetHeight);
            };
            window.addEventListener("resize", updateHeight);
            return () => {
                window.removeEventListener("resize", updateHeight);
            };
        } else if (ref.current) {
            setHeight(ref.current.offsetHeight);
        } else {
            const getHeight = () => {
                if (ref.current) setHeight(ref.current.offsetHeight);
            };
            const intervalId = setInterval(getHeight, 200);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [ref, height]);

    return useMemo(
        () => ({
            styles: {
                [`--${id}`]: height ? `${height}px` : undefined,
                transition: `${strategy} ease-in-out 200ms`,
                transform:
                    strategy === "transform" && scroll.direction === "down"
                        ? `translateY(calc(-1 * var(--${id})))`
                        : undefined,
                height:
                    strategy === "height"
                        ? scroll.direction === "down"
                            ? 0
                            : height
                        : undefined,
                padding:
                    strategy === "height" && scroll.direction === "down"
                        ? 0
                        : undefined,
                overflow:
                    strategy === "height" && scroll.direction === "down"
                        ? "hidden"
                        : undefined,
            },
            reset: () => {
                scroll.reset();
                setHeight(undefined);
            },
        }),
        [strategy, scroll, height, id]
    );
};
export default useHideOnScroll;
