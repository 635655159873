import { Subject } from "../interfaces/User";

export function setActiveSubjectCSSVariables(
    activeSubject: Subject,
    element: HTMLElement
) {
    element.style.setProperty(
        "--element-subjectActive-default",
        activeSubject.colors.default
    );
    element.style.setProperty(
        "--element-subjectActive-hover",
        activeSubject.colors.hover
    );
    element.style.setProperty(
        "--element-subjectActive-disabled",
        activeSubject.colors.disabled
    );
}

export function setActiveSubjectCSSVariablesOnBody(
    activeSubject: Subject | undefined
) {
    if (!activeSubject) return;
    const body = document.body;
    setActiveSubjectCSSVariables(activeSubject, body);
}
