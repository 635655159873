import React from "react";
import { defineMessages, useIntl } from "react-intl";
import "./OfflineBanner.scss";
import VisuallyHidden from "../../components/VisuallyHidden/VisuallyHidden";
import { commonMessages } from "../../utils/messages";
import {
    useDismissableMessage,
    useMessageDismiss,
} from "../../components/MessageDismiss/MessageDismiss";
import { DismissableMessages } from "../../interfaces/Session";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import { withFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";

function OfflineBanner() {
    const intl = useIntl();
    const isOnline = useOnlineStatus();
    const bannerHasBeenClosed = useDismissableMessage(
        DismissableMessages.offlineBanner
    );
    const closeBannerHandler = useMessageDismiss(
        DismissableMessages.offlineBanner
    );
    const displayOfflineBanner = !isOnline && !bannerHasBeenClosed;
    return (
        <>
            {displayOfflineBanner && (
                <div
                    className="offline-banner inline-center-space-between"
                    aria-live="polite"
                >
                    <div className="inline-center-space-between">
                        <Icon
                            path="wifi_off"
                            size="medium"
                            className="wifi-off"
                        />
                        <p>{intl.formatMessage(messages.offlineBanner)}</p>
                    </div>
                    <button onClick={closeBannerHandler}>
                        <Icon path="close" size="medium" />
                        <VisuallyHidden as="span">
                            {intl.formatMessage(commonMessages.close)}
                        </VisuallyHidden>
                    </button>
                </div>
            )}
        </>
    );
}
export default withFeatureFlag("offlineFeatures")(OfflineBanner);

const messages = defineMessages({
    offlineBanner: {
        id: "offline-banner",
        defaultMessage: "You are offline. Some data may not be available.",
    },
});
