let RTF: Intl.RelativeTimeFormat | undefined = undefined;

const getRTF = (lang: string, override = false) => {
    if (RTF && !override) {
        return RTF;
    }
    RTF = new Intl.RelativeTimeFormat(lang);
    return RTF;
};

export const relativeTimeFormat =
    (lang: string, override = false) =>
    (input: string | Date): string => {
        const rtf = getRTF(lang, override);
        const date = typeof input === "string" ? new Date(input) : input;

        const relativeMs = Math.abs(Date.now() - date.getTime());
        const sign = date.getTime() - Date.now() > 0 ? 1 : -1;
        const relativeS = relativeMs / 1000;
        if (relativeS / 60 / 60 / 24 / 30 >= 1) {
            return rtf.format(
                sign * Math.round(relativeS / 60 / 60 / 24 / 30),
                "months"
            );
        } else if (relativeS / 60 / 60 / 24 / 7 >= 1) {
            return rtf.format(
                sign * Math.round(relativeS / 60 / 60 / 24 / 7),
                "weeks"
            );
        } else if (relativeS / 60 / 60 / 24 >= 1) {
            return rtf.format(
                sign * Math.round(relativeS / 60 / 60 / 24),
                "days"
            );
        } else if (relativeS / 60 / 60 >= 1) {
            return rtf.format(sign * Math.round(relativeS / 60 / 60), "hours");
        } else {
            return rtf.format(sign * Math.round(relativeS / 60), "minutes");
        }
    };

type DatePart = {
    type: "formatter";
    letter: "D" | "M" | "Y" | "d" | "m" | "y";
    length: number;
};
type Separator = { type: "separator"; value: string };
const getters: {
    [key in "D" | "M" | "Y" | "d" | "m" | "y"]: (date: Date) => number;
} = {
    D: (date) => date.getDate(),
    d: (date) => date.getDate(),
    M: (date) => date.getMonth() + 1,
    m: (date) => date.getMonth() + 1,
    Y: (date) => date.getFullYear(),
    y: (date) => date.getFullYear(),
};
class Formatter {
    formatter: (DatePart | Separator)[];
    constructor(format: string) {
        const formatter: (DatePart | Separator)[] = [];
        for (const letter of format) {
            if (!(letter in getters)) {
                formatter.push({ type: "separator", value: letter });
            } else {
                const lastItem = formatter[formatter.length - 1];
                if (
                    lastItem?.type === "formatter" &&
                    lastItem.letter === letter
                ) {
                    lastItem.length += 1;
                } else {
                    formatter.push({
                        type: "formatter",
                        length: 1,
                        letter: letter as keyof typeof getters,
                    });
                }
            }
        }
        this.formatter = formatter;
    }

    format(date: Date) {
        return this.formatter
            .map((item) => {
                if (item.type === "separator") {
                    return item.value;
                } else {
                    const value = getters[item.letter](date).toString();
                    if (item.length === 1) {
                        return value;
                    } else if (item.length === 2) {
                        if (value.length === 2) {
                            return value;
                        } else if (value.length > 2) {
                            return value.slice(value.length - 2, value.length);
                        } else {
                            return "0" + value;
                        }
                    } else {
                        return value;
                    }
                }
            })
            .join("");
    }
}

export const displayedDateFormat =
    (format: string) => (input: string | Date) => {
        const formatter = new Formatter(format);
        return formatter.format(
            typeof input === "string" ? new Date(input) : input
        );
    };

const padNumber = (nb: number, length = 2): string => {
    const str = nb.toString();
    if (str.length >= length) {
        return str;
    } else {
        const padding = new Array(length - str.length).fill("0");
        return padding + str;
    }
};

export const duration =
    (intl: { hours: string; minutes: string; seconds: string }) =>
    (duration: number) => {
        const parts: string[] = [];
        if (duration / (60 * 60) >= 1) {
            parts.push(
                `${padNumber(Math.floor(duration / (60 * 60)))} ${intl.hours}`
            );
            duration = duration % (60 * 60);
        }
        if (duration / 60 >= 1) {
            parts.push(
                `${padNumber(Math.floor(duration / 60))} ${intl.minutes}`
            );
            duration = duration % 60;
        }
        parts.push(`${padNumber(duration)} ${intl.seconds}`);
        return parts.join(" ");
    };
