import { useMenu, useMenuItem } from "@react-aria/menu";
import React from "react";
import { TreeProps, TreeState, useTreeState } from "react-stately";
import { Node } from "@react-types/shared";
import cn from "classnames";

type Props = TreeProps<any> & { className?: string };

const Menu = (props: Props) => {
    const state = useTreeState(props);

    const ref = React.useRef(null);
    const { menuProps } = useMenu(props, state, ref);

    return (
        <ul {...menuProps} ref={ref} className={props.className}>
            {[...state.collection].map((item) => (
                <MenuItem key={item.key} item={item} state={state} />
            ))}
        </ul>
    );
};

interface MenuItemProps {
    item: Node<any>;
    state: TreeState<any>;
}
const MenuItem = ({ item, state }: MenuItemProps) => {
    const ref = React.useRef(null);
    const { menuItemProps } = useMenuItem({ key: item.key }, state, ref);

    return (
        <li
            {...menuItemProps}
            className={cn("popover-menu__item", menuItemProps.className)}
            ref={ref}
        >
            {item.rendered}
        </li>
    );
};

export default Menu;
