/**
 * Returns the id of the classroom of the student.
 *
 * TEMP This is very rudimentary while we assume that a student is only in one
 * class
 */
export const chooseStudentClassroom = (classroomIds: string[]): string => {
    return classroomIds[0];
};

export const classroomAlphabeticalSort = (
    a: { name: string },
    b: { name: string }
): -1 | 0 | 1 => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
};

export const userAlphabeticalSort = (
    a:
        | { last_name: string; first_name: string }
        | { lastname: string; firstname: string },
    b:
        | { last_name: string; first_name: string }
        | { lastname: string; firstname: string }
): -1 | 0 | 1 => {
    const lastNameA = (
        "last_name" in a ? a.last_name : a.lastname
    ).toUpperCase();
    const lastNameB = (
        "last_name" in b ? b.last_name : b.lastname
    ).toUpperCase();

    if (lastNameA < lastNameB) {
        return -1;
    }
    if (lastNameA > lastNameB) {
        return 1;
    }

    const firstNameA = (
        "first_name" in a ? a.first_name : a.firstname
    ).toUpperCase();
    const firstNameB = (
        "first_name" in b ? b.first_name : b.firstname
    ).toUpperCase();

    if (firstNameA < firstNameB) {
        return -1;
    }
    if (firstNameA > firstNameB) {
        return 1;
    }
    return 0;
};
