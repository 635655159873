import { Statement } from "@xapi/xapi";
import { DuoParticipant } from "../pages/Workmodes/Duo/Duo.types";
import { PRLockStatus } from "./PedagogicalResourcesManagement";
import { ErrorLoader, Loader } from "./Status";
import { UserExtra, UserType } from "./User";
import { ThemeType } from "./Theme";
import { AIError } from "../hooks/useAI/useAI.types";
import { Maybe } from "../utils/types";
import { PerModule } from "@evidenceb/gameplay-interfaces";
import { Butler } from "@evidenceb/ai-butler";
import { AthenaEvent } from "@evidenceb/athena-event-storage-schemas";

export interface Session {
    // COMMON

    sessionId: string;
    userId: string;
    firstname: string;
    lastname: string;
    userProvider: string;
    appProvider: string;
    appVariation: string;
    userType: UserType;
    evidencebId: string;
    version: string;
    extra: Partial<UserExtra>;
    theme: ThemeType;
    flags: {
        useHistoryFrom: StatementHistory;
        displaySignIn: boolean;
        /**
         * In the player, allow to see the exercise hierarchy & result in the console (for POs & AI team)
         * @default false
         */
        logExerciseDetails: boolean;
    };
    dismissMessages: { [key in DismissableMessages]: boolean };
    /**
     * Fragments are needed when creating students in the dashboard.
     * It will differentiate the origin from which the teacher is from (ENT or other)
     */
    fragments: string[];
    specimen?: {};
    /**
     * App shortname
     * NB: only available for authentication
     */
    app?: string;

    // STUDENT
    soloAI: Maybe<PerModule<ErrorLoader<Butler, AIError>>>;
    /**
     * Whether the statements were fetched from the LRS
     */
    statementsFetched: boolean;
    statements?: Statement[];
    /** All locally-stored AthenaEvents. */
    athenaEvents: AthenaEvent<any, any>[];
    prLockStatus?: Loader<PRLockStatus>;
    duos?: DuoSession[];
    resourceAssignments?: // Word of caution: For now assignments are only fetched at the
    // initialization of the app or when the user has a new assignment
    // notification. When refactoring with react-query, make sure that when
    // new assignments are received, notifications are also refetched to get
    // the notification associated with the new assignement. See
    // src/components/Notifications/api/useQueries.ts for the reverse sync
    // that is already in place (new notif triggers refetching assignments)
    ResourceAssignment[];
    classroomIds?: string[];

    // TEACHER
    school?: string;
    classrooms?: Group[];
    groups?: Group[];
}
export default Session;

export type DuoSession = {
    id: string;
    name: string;
    moduleId: string;
    mentee: DuoParticipant;
    mentor: DuoParticipant;
    status: DuoStatus;
    teacherId: string;
};

export enum DuoStatus {
    Inactive = "inactive",
    Active = "active",
    Completed = "completed",
}

export enum DismissableMessages {
    prmObjectiveLockingConfirmationModal = "prmObjectiveLockingConfirmationModal",
    prmObjectiveUnlockingConfirmationModal = "prmObjectiveUnlockingConfirmationModal",
    specimenGetFullVersionBanner = "specimenGetFullVersionBanner",
    specimenSwitchUserModal = "specimenSwitchUserModal",
    specimenTeacherDashboardModal = "specimenTeacherDashboardModal",
    playlistRemoveStudentFromRecipients = "playlistRemoveStudentFromRecipients",
    offlineBanner = "offlineBanner",
    deppTestManual = "deppTestManual",
    dashboardHelpInfo = "dahsboardHelpInfo",
}

export enum StatementHistory {
    localHistory = "LOCAL_HISTORY",
    noHistory = "NO_HISTORY",
    LRS = "LRS",
}

export type Group = {
    id: string;
    name: string;
    lockStatus: PRLockStatus;
    students: Student[];
    duos?: DuoSession[];
    external_id: string;
};

export type Student = {
    id: string;
    firstname: string;
    lastname: string;
    extra?: Partial<UserExtra>;
};

export type ResourceAssignment = {
    teacher: string;
    assigned_resource: {
        resource_id: string;
        resource_type: "playlist" | "workshop";
        extra: any;
    };
    extra: any;
};
