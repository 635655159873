import { defineMessages } from "react-intl";

export const subjectChoiceMessages = defineMessages({
    welcomeMessage: {
        id: "subjectChoice-welcomeMessage",
        defaultMessage: "Discover this project",
    },
    subjectTitle: {
        id: "subjectChoice-subjectTitle",
        defaultMessage: "What subject do you teach?",
    },
    subjectMessage: {
        id: "subjectChoice-subjectMessage",
        defaultMessage:
            "You will always be able to modify your subject in the application from your profile settings.",
    },
    thanksTitle: {
        id: "subjectChoice-thanksTitle",
        defaultMessage: "Thank you!",
    },
    thanksMessage: {
        id: "subjectChoice-thanksMessage",
        defaultMessage: "Discover the subject application now.",
    },
    errorValidation: {
        id: "subjectChoice-errorValidation",
        defaultMessage: "We are unable to save your subject. Try Again.",
    },
    errorMappingTree: {
        id: "subjectChoice-errorMappingTree",
        defaultMessage: "An error has occured. Please refresh the page.",
    },
    errorContactSupport: {
        id: "subjectChoice-errorContactSupport",
        defaultMessage: "If the problem persists please contact our support.",
    },
});
