import React, { HTMLAttributes, PropsWithChildren } from "react";
import cn from "classnames";

import "./PageWrapper.scss";

export type Props = PropsWithChildren<{
    className?: string;
}> &
    HTMLAttributes<HTMLDivElement>;

const PageWrapper = ({ className, children, ...props }: Props) => {
    return (
        <div {...props} className={cn("page-wrapper", className)}>
            {children}
        </div>
    );
};

export default PageWrapper;
