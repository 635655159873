import React, { useContext } from "react";
import { fullName } from "../../utils/format";
import { RawExtra } from "../../interfaces/User";

import "./Avatar.scss";
import { sessionStore } from "../../contexts/SessionContext";
import { useFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";
import { backgroundColors } from "../../pages/Account/utils";
import { ThemeType } from "../../interfaces/Theme";
import useAssetsDetails from "../../hooks/useAssetsDetails";
import { Image } from "@evidenceb/athena-common/modules/Assets";

export type AvatarProps = {
    name: string;
    character?: { src: string; alt: string };
    colors?: {
        text?: string;
        background?: string;
        border?: string;
    };
    fontSize?: string;
    className?: string;
    "aria-hidden"?: boolean;
};

const Avatar = ({
    name,
    character,
    colors,
    fontSize,
    className,
    "aria-hidden": ariaHidden,
}: AvatarProps) => {
    const {
        session: { theme },
    } = useContext(sessionStore);
    const assetsDetails = useAssetsDetails();

    return (
        <span
            className={`avatar ${className ?? ""}`}
            style={{
                background: colors?.background,
                border: colors?.border
                    ? "2px solid " + colors.border
                    : getAvatarBorder(theme, colors?.background),
            }}
            aria-hidden={ariaHidden}
        >
            {character ? (
                <Image
                    assetsDetails={assetsDetails}
                    src={character.src}
                    alt={character.alt}
                />
            ) : (
                <span
                    style={{
                        fontSize: fontSize,
                        color: getInitialsDefaultTextColors(
                            theme,
                            colors?.background
                        ),
                    }}
                >
                    {getInitials(name)}
                </span>
            )}
        </span>
    );
};
export default Avatar;

export const UserAvatar = ({
    user,
    ...props
}: Omit<AvatarProps, "name" | "character"> & {
    user:
        | { firstname: string; lastname: string; extra?: RawExtra }
        | { first_name: string; last_name: string; extra?: RawExtra };
}) => {
    const avatarFlag = useFeatureFlag("chooseAvatar");

    return (
        <Avatar
            {...props}
            name={fullName(user)}
            character={
                avatarFlag && typeof user.extra === "object"
                    ? user.extra?.avatarCharacter
                    : undefined
            }
            colors={
                avatarFlag &&
                typeof user.extra === "object" &&
                user.extra?.avatarBackground
                    ? {
                          background: user.extra.avatarBackground.code,
                      }
                    : props.colors
            }
        />
    );
};

interface OwnAvatarProps
    extends Omit<AvatarProps, "name" | "character" | "colors"> {}

export function OwnAvatar(props: OwnAvatarProps) {
    const { session } = useContext(sessionStore);

    return <UserAvatar {...props} user={session} />;
}

const getInitials = (string: string) => {
    const words = string.split(" ");
    const initials = words.map((word) => word.charAt(0));

    return initials.join("").substring(0, 2);
};

export const getAvatarBorder = (
    theme: ThemeType,
    backgroundColorCode: string | undefined
) => {
    const backgroundCode = backgroundColors.find(
        (color) => color.code === backgroundColorCode
    );
    let borderColor: string;

    if (
        theme === ThemeType.Light &&
        (!backgroundCode || backgroundCode.alias === "white")
    ) {
        borderColor = "var(--element-grey20)";
    } else {
        borderColor = "transparent";
    }
    return `2px solid ${borderColor}`;
};

export const getInitialsDefaultTextColors = (
    theme: ThemeType,
    backgroundColorCode: string | undefined
) => {
    if (
        theme === ThemeType.Light &&
        backgroundColors.find((color) => color.code === backgroundColorCode)
            ?.alias === "white"
    )
        return "var(--text-main-default)";
    return undefined;
};
