export enum PageState {
    Loading = "LOADING",
    StatementsError = "STATEMENTS_ERROR",
    StatementsRetry = "STATEMENTS_RETRY",
    Loaded = "LOADED",
}

export enum LoaderStatus {
    Loading = "loading",
    Success = "success",
    Idle = "idle",
    Error = "Error",
}
type LoaderIdle = { status: LoaderStatus.Idle };
export type LoaderLoading = { status: LoaderStatus.Loading };
export type LoaderSuccess<P> = { status: LoaderStatus.Success; payload: P };
export type LoaderError<E> = { status: LoaderStatus.Error; err: E };
export type Loader<P, E = unknown> =
    | LoaderLoading
    | LoaderSuccess<P>
    | LoaderIdle
    | LoaderError<E>;
export type IdlelessLoader<P, E = unknown> =
    | LoaderLoading
    | LoaderSuccess<P>
    | LoaderError<E>;
export type ErrorLoader<P, E = unknown> = LoaderSuccess<P> | LoaderError<E>;
