import React, { memo, useMemo } from "react";
import Leaf from "./Leaf";
import {
    areSameProps,
    getBranchCheckedStatus,
    getChildLeaves,
    getNodeLabel,
    isLeaf,
    isNodeInBranch,
    makeHierarchyPath,
    nodePath,
} from "../utils";
import {
    Accordion,
    AccordionPanel,
} from "@evidenceb/athena-common/design-system/Accordion";
import BranchCheckbox from "./BranchCheckbox";
import AccordionHeaderWithActive from "./AccordionHeaderWithActive";
import { HierarchyPath, Props as TreeCheckboxGroupLevelProps } from "../types";

// A11y reference for checkboxes: https://www.w3.org/WAI/ARIA/apg/patterns/checkbox/

type Props = Omit<TreeCheckboxGroupLevelProps, "rootChildrenIds" | "depth"> &
    Pick<
        Required<TreeCheckboxGroupLevelProps>,
        "depth" | "startingHeadingLevel"
    > & {
        /**
         * Index of the current item among its siblings
         */
        index: number;
        currentId: string;
        parentHierarchyPath?: HierarchyPath;
    };

/**
 * Recursively renders a hierarchical list of nodes. Branches are placed in
 * nested accordions and associated with a checkbox. Nodes can appear in
 * multiple branches.
 */
const UnmemoTreeCheckboxGroupLevel = ({
    nodes,
    onCheckNode,
    selectBranchLabel,
    selectLeafLabel,
    checkedLeaves,
    depth = 0,
    parentHierarchyPath,
    currentId,
    index,
    startingHeadingLevel = 3,
    activeNode,
    onActivateNode,
    expandedNodes,
    onExpandNode,
}: Props) => {
    const currentNode = nodes[currentId];

    const currentHierarchyPath = makeHierarchyPath(
        parentHierarchyPath,
        nodePath(currentNode)
    );

    const expandCurrentNode = useMemo(
        () =>
            onExpandNode ? () => onExpandNode(currentHierarchyPath) : undefined,
        [onExpandNode, currentHierarchyPath]
    );

    if (!currentNode) return null;

    if (isLeaf(currentNode))
        return (
            <Leaf
                node={currentNode}
                onCheckNode={onCheckNode}
                selectLeafLabel={selectLeafLabel}
                checked={checkedLeaves.includes(currentHierarchyPath)}
                parentHierarchyPath={parentHierarchyPath ?? ""}
                depth={depth}
                index={index}
                isActiveNode={
                    activeNode ? activeNode === currentHierarchyPath : false
                }
                onActivateNode={onActivateNode}
            />
        );

    const groupId = `checkbox-group-label-${currentNode.id}`;
    const label = getNodeLabel(currentNode, index);

    const childLeaves = getChildLeaves(currentNode, nodes, parentHierarchyPath);
    const checked = getBranchCheckedStatus(childLeaves, checkedLeaves);

    return (
        <Accordion
            className="tree-checkbox"
            expanded={expandedNodes?.includes(currentHierarchyPath)}
            onExpand={expandCurrentNode}
        >
            {(accordionProps) => (
                <>
                    <AccordionHeaderWithActive
                        item={currentNode}
                        hierarchyPath={currentHierarchyPath}
                        depth={depth}
                        groupId={groupId}
                        label={label}
                        startingHeadingLevel={startingHeadingLevel}
                        onActivateNode={onActivateNode}
                        isActiveNode={
                            activeNode
                                ? activeNode === currentHierarchyPath
                                : false
                        }
                        {...accordionProps}
                    />

                    {!currentNode.readOnly && (
                        <BranchCheckbox
                            hierarchyPath={currentHierarchyPath}
                            label={label}
                            checked={checked}
                            childLeaves={childLeaves}
                            onCheckNode={onCheckNode}
                            selectBranchLabel={selectBranchLabel}
                            variant={
                                activeNode &&
                                activeNode === currentHierarchyPath
                                    ? "inverted"
                                    : "default"
                            }
                        />
                    )}

                    <AccordionPanel {...accordionProps}>
                        <fieldset aria-labelledby={groupId}>
                            <ol>
                                {currentNode.childrenIds!.map((id, index) => (
                                    <li key={id}>
                                        <TreeCheckboxGroupLevel
                                            onCheckNode={onCheckNode}
                                            selectBranchLabel={
                                                selectBranchLabel
                                            }
                                            selectLeafLabel={selectLeafLabel}
                                            activeNode={
                                                activeNode &&
                                                isNodeInBranch(
                                                    currentHierarchyPath
                                                )(activeNode)
                                                    ? activeNode
                                                    : undefined
                                            }
                                            onActivateNode={onActivateNode}
                                            depth={depth + 1}
                                            currentId={id}
                                            nodes={nodes}
                                            checkedLeaves={checkedLeaves.filter(
                                                isNodeInBranch(
                                                    currentHierarchyPath
                                                )
                                            )}
                                            parentHierarchyPath={
                                                currentHierarchyPath
                                            }
                                            index={index}
                                            startingHeadingLevel={
                                                startingHeadingLevel
                                            }
                                            expandedNodes={expandedNodes?.filter(
                                                isNodeInBranch(
                                                    currentHierarchyPath
                                                )
                                            )}
                                            onExpandNode={onExpandNode}
                                        />
                                    </li>
                                ))}
                            </ol>
                        </fieldset>
                    </AccordionPanel>
                </>
            )}
        </Accordion>
    );
};
const TreeCheckboxGroupLevel = memo(UnmemoTreeCheckboxGroupLevel, areSameProps);
export default TreeCheckboxGroupLevel;
