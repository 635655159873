import { CustomColors } from "../interfaces/Theme";

export const activateCustomColors = (customColors: Partial<CustomColors>) => {
    const root = document.getElementById("root");
    if (!root) return;

    Object.keys(customColors).forEach((customColor) => {
        root.classList.add(`--custom-color-${customColor}`);
    });
};
