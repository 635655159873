import {
    Exercise,
    ExerciseDefinition,
} from "@evidenceb/athena-common/interfaces/Exercise";
import { Gameplay } from "@evidenceb/athena-common/interfaces/Gameplay";
import Case from "case";

export class UnavailableGameplayError extends Error {}

/**
 * Function that returns a list of exercises with their associated gameplay. If
 * the gameplay required for a provided exercise cannot be found, it if not
 * added to the returned list.
 * NB: If more than logging is required from the callback, have a look at https://github.com/EvidenceB/evb_application/pull/6#pullrequestreview-573054253
 * @param exerciseDefinitions The list of raw exercises
 * @param onExerciseError A callback that is called for every exercise for which the Gameplay cannot be imported (should be used for logging)
 */
export const getExercisesWithAvailableGameplays = async <
    T extends { type: ExerciseDefinition["type"] }
>(
    exerciseDefinitions: T[]
): Promise<(T & { Gameplay: Exercise["Gameplay"] })[]> => {
    const settledPromises = await fetchGameplays(exerciseDefinitions);
    return settledPromises.map((settledPromise) => {
        if (
            settledPromise.status === "fulfilled" &&
            typeof settledPromise.value !== "undefined"
        )
            return settledPromise.value;
        else {
            throw new Error(); // TODO: error details
        }
    });
};

/**
 * An asynchronous function that returns a list of promise results from
 * fetching a component for each provided raw exercise
 * @param exerciseDefinitions The list of raw exercise
 */
const fetchGameplays = async <T extends { type: ExerciseDefinition["type"] }>(
    exerciseDefinitions: T[]
): Promise<
    (
        | PromiseFulfilledResult<T & { Gameplay: Exercise["Gameplay"] }>
        | PromiseRejectedResult
    )[]
> => {
    return Promise.allSettled(
        exerciseDefinitions.map(async (exerciseDefinition) => ({
            ...exerciseDefinition,
            Gameplay: await fetchComponent(exerciseDefinition.type),
        }))
    );
};

/**
 * An asynchronous function that fetches the gameplay associated with a type
 * @param gameplayType
 */
export const fetchComponent = async (
    gameplayType: string
): Promise<Gameplay<any, any>> => {
    // NB: Case needs to be used outside of the import statement, otherwise
    // otherwise webpack magic means that it will not be defined at the time of
    // the import and an error will be thrown. (TL;DR: Do not refactor plz)
    const gameplayPascal = Case.pascal(gameplayType);
    try {
        const module = await import(
            `@evidenceb/gameplays/build/Components/Gameplays/${gameplayPascal}/${gameplayPascal}.js`
        );
        return module.default;
    } catch (err) {
        const message = `Gameplay ${gameplayPascal} not available.`;
        console.error(message, err);
        throw new Error(message);
    }
};

export default fetchGameplays;
