import React from "react";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

import { VisuallyHidden } from "@evidenceb/athena-common/ui/VisuallyHidden";
import useAthenaAPIClient from "../../hooks/queries/useAthenaAPIClient/useAthenaAPIClient";
import AccessibleButton from "../../components/AccessibleButton/AccessibleButton";
import { ClickableCardProps } from "./types";
import Card from "./Card";
import queryKeys from "../../hooks/queries/useAthenaAPIClient/queryKeys";

// Designed using the absolute positioning strategy outlined in https://www.nomensa.com/blog/how-build-accessible-cards-block-links.
// To use when its drawbacks (not being able to select text in the card) are ok to disregard.

const ClickableCard = ({
    clickAction,
    colors,
    className,
    style,
    ...cardProps
}: ClickableCardProps) => {
    const location = useLocation();
    const athenaAPIClient = useAthenaAPIClient();
    const { data: externalUrl } = useQuery({
        queryKey:
            clickAction.type == "externalRedirection"
                ? queryKeys.getExternalRedirection(clickAction.to)
                : ["externalRedirection", ""],
        queryFn: athenaAPIClient.redirectToExternalLink,
        enabled: clickAction.type == "externalRedirection",
        staleTime: Infinity,
    });

    return (
        <div
            className={cn("ds-card clickable-card", className)}
            style={{
                ...style,
                /* @ts-ignore */
                "--active-color": colors?.activeBorder,
            }}
        >
            {!clickAction.disabled &&
                clickAction.type === "externalRedirection" && (
                    <a
                        // Quick fix: return normal link if athena-auth failed to provide
                        // GAR link. Must be fixed when we know where the user came from.
                        href={externalUrl ? externalUrl : clickAction.to}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <VisuallyHidden>{clickAction.altTxt}</VisuallyHidden>
                    </a>
                )}
            {!clickAction.disabled &&
                clickAction.type === "internalRedirection" && (
                    <Link
                        to={{
                            pathname: clickAction.to,
                            state: { from: location.pathname },
                        }}
                    >
                        <VisuallyHidden>{clickAction.altTxt}</VisuallyHidden>
                    </Link>
                )}
            {clickAction.type === "actionableButton" && (
                <AccessibleButton
                    className="clickable-card__button"
                    onPress={clickAction.onPress}
                    disabled={clickAction.disabled}
                >
                    <VisuallyHidden as="span">
                        {clickAction.label}
                    </VisuallyHidden>
                </AccessibleButton>
            )}
            <Card
                overrideDSClassname
                className={cn("ds-clickable-card__content")}
                {...cardProps}
            />
        </div>
    );
};
export default ClickableCard;
