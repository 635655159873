import { useEffect, useState } from "react";
import {
    isMobileView,
    isTabletView,
    isDesktopView,
} from "../utils/breakpoints";

const useBreakpoints = () => {
    const [isMobile, setMobile] = useState(isMobileView());
    const [isTabletOrSmaller, setTablet] = useState(isTabletView());
    const [isDesktop, setDesktop] = useState(isDesktopView());

    useEffect(() => {
        const onWindowResize = () => {
            setMobile(isMobileView());
            setTablet(isTabletView());
            setDesktop(isDesktopView());
        };
        window.addEventListener("resize", onWindowResize);

        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    return {
        isMobile,
        isTabletOrSmaller,
        isDesktop,
    };
};

export default useBreakpoints;
