import React, {
    useEffect,
    useState,
    useRef,
    PropsWithChildren,
    useId,
} from "react";
import "./NotificationsWidget.scss";
import { Dialog } from "@evidenceb/athena-common/design-system/Modal";
import { VisuallyHidden } from "@evidenceb/athena-common/ui/VisuallyHidden";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import { defineMessages, useIntl } from "react-intl";
import { NotificationStatus } from "../Notifications/Types";
import Notifications from "../Notifications/Notifications";
import useNotifications from "../Notifications/useNotifications";
import { FocusScope } from "@react-aria/focus";
const NotificationsWidget = () => {
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { notifications } = useNotifications();
    const notificationsRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                notificationsRef.current &&
                !notificationsRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                setIsOpen(false);
            }
        };
        document.addEventListener("keydown", handleEscapeKey);
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("keydown", handleEscapeKey);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const notificationsNumber =
        (notifications &&
            notifications.filter((n) => n.status !== NotificationStatus.READ)
                .length) ??
        0;

    const Container = isOpen ? FocusTrap : React.Fragment;
    const headerId = useId();

    return (
        <Container>
            <button
                id="notifications-widget__button"
                onClick={() => setIsOpen(!isOpen)}
                aria-haspopup
                aria-expanded={isOpen}
            >
                <VisuallyHidden as="span">
                    {isOpen
                        ? intl.formatMessage(messages.closeNotifications)
                        : intl.formatMessage(messages.openNotifications, {
                              notificationsCount: notificationsNumber,
                          })}
                </VisuallyHidden>
                <Icon path="bell" size="medium" />
                {notificationsNumber > 0 && (
                    <span
                        className="notifications-widget__button__number"
                        aria-hidden={true}
                    >
                        {notificationsNumber}
                    </span>
                )}
            </button>

            {isOpen && (
                <Dialog
                    showDialog={isOpen}
                    id="notifications-widget__dialog"
                    contentClassName="notifications-widget__content"
                    aria-labelledby={headerId}
                    marginTop={0}
                >
                    <div ref={notificationsRef}>
                        {notifications && (
                            <Notifications
                                list={notifications}
                                headerId={headerId}
                            />
                        )}
                    </div>
                </Dialog>
            )}
        </Container>
    );
};
export default NotificationsWidget;

const FocusTrap = ({ children }: PropsWithChildren) => (
    <FocusScope contain restoreFocus>
        {children}
    </FocusScope>
);

const messages = defineMessages({
    notifications: {
        id: "notifications",
        defaultMessage: "Notifications",
    },
    MarkAllAsRead: {
        id: "notifications-MarkAllAsRead",
        defaultMessage: "Mark all as read",
    },
    seeAll: {
        id: "notifications-seeAll",
        defaultMessage: "See all notifications",
    },
    unread: {
        id: "notifications-unread",
        defaultMessage: "unread",
    },
    read: {
        id: "notifications-read",
        defaultMessage: "read",
    },
    openNotifications: {
        id: "notifications-open",
        defaultMessage: "Open notifications ({notificationsCount} new)",
    },
    closeNotifications: {
        id: "notifications-close",
        defaultMessage: "Close notifications",
    },
});
