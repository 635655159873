import axios from "axios";
import { AuthMode, Config } from "../interfaces/Config";
import { DeepPartial } from "./types";
import { isSemiAutonomousRoute } from "../pages/Authentication/routes";
import { joinPath } from "./path";
import config from "../config";

/**
 * @param authMode authmode or list of authomodes configured for the app
 * @param orMatches list of possible matches. Seperate arguments are combines
 * with the OR operator, and list parameters are combined with the AND operator
 * @returns true if the authMode matches, false otherwise
 */
export const authMatch = (
    authMode: AuthMode | AuthMode[],
    ...orMatches: (AuthMode | AuthMode[])[]
) => {
    if (orMatches.length === 0) throw new Error("Provide at least one match");
    if (tempMatchesCurrentTransition(authMode, orMatches)) return true; // TEMP (remove when merge in main and deployed to all product and it's safe to handle authMode as a list)
    return orMatches.some((_andMatches) => {
        const andMatches =
            typeof _andMatches === "string" ? [_andMatches] : _andMatches;
        return andMatches.every((match) => {
            if (typeof authMode === "string") return authMode === match;
            return authMode.includes(match);
        });
    });
};

const tempMatchesCurrentTransition = (
    authMode: AuthMode | AuthMode[],
    orMatches: (AuthMode | AuthMode[])[]
) => {
    return (
        authMode === AuthMode.Authentication &&
        orMatches.length === 1 &&
        orMatches[0].length === 2 &&
        orMatches[0].includes(AuthMode.Authentication) &&
        orMatches[0].includes(AuthMode.DirectAccess)
    );
};

export const redirectIfNotAuthPath = async (
    endpoints: { assetsProxy: string; auth: string },
    minimalConfigPath: string
): Promise<boolean> => {
    if (isSemiAutonomousRoute()) return false;

    const {
        data: { config: minimalConfig },
    } = await getMinimalConfig(endpoints.assetsProxy, minimalConfigPath);
    if (!minimalConfig.auth?.athenaAuthPathForRedirectionQuery) return false;
    try {
        const { data: redirectTo } = await getRedirectionUrl(
            endpoints.auth,
            minimalConfig.auth.athenaAuthPathForRedirectionQuery
        );
        window.location.replace(redirectTo);
        return true;
    } catch (err) {
        return false;
    }
};

const getMinimalConfig = (assetsProxy: string, minimalConfigPath: string) =>
    axios.get<{
        config: DeepPartial<Config>;
    }>(`${assetsProxy}${minimalConfigPath}`);

const getRedirectionUrl = (endpoint: string, path: string) =>
    axios.get<string>(`${endpoint}/${path}`);

const getLogoutRedirectionUrl = (partialUrl: string): string => {
    if (partialUrl.startsWith("/"))
        return joinPath(
            window.location.origin,
            config.basePath ?? "",
            partialUrl
        );
    return partialUrl;
};

export const redirectAfterLogout = (
    config: Omit<Config, "theme">,
    appProvider: string
) => {
    const provider = config.logoutUrl.find(
        (item) => item.provider === appProvider
    );
    const defaultProvider = config.logoutUrl.find(
        (item) => item.provider === "default"
    );
    let url: string;
    if (provider) url = getLogoutRedirectionUrl(provider.url);
    else if (defaultProvider)
        url = getLogoutRedirectionUrl(defaultProvider.url);
    else url = window.location.origin;
    window.location.assign(url);
};
