import { UserType } from "../../interfaces/User";
import { Page, Pagetype } from "../../interfaces/Config";
import { NavLink } from "react-router-dom";
import { isWorkmodeNavActive } from "../../pages/Workmodes/routing";
import { isStudentDashboardNavActive } from "../../pages/DashBoard/StudentDashboard/routing";
import { isTeacherDashboardNavActive } from "../../pages/DashBoard/DashboardTeacher/routing";

export const showInHeader = (userType: UserType, page: Page): boolean => {
    return (
        page.authorized?.some((authorization) => {
            if (typeof authorization === "string" && authorization === userType)
                return page.inTopNav;
            if (
                typeof authorization !== "string" &&
                authorization.for === userType
            )
                return authorization.inTopNav;
            return false;
        }) ?? false
    );
};

export const showInHeaderNav = (userType: UserType, page: Page): boolean => {
    return (
        showInHeader(userType, page) &&
        page.type !== Pagetype.LOGOUT &&
        page.type !== Pagetype.SWITCH_USER_TYPE
    );
};

export const isNavlinkActive = (
    page: Page,
    pages: Page[],
    userType: UserType
): Parameters<typeof NavLink>[0]["isActive"] | undefined => {
    switch (page.type) {
        case Pagetype.WORK_MODES:
            return isWorkmodeNavActive(pages, userType);
        case Pagetype.DASHBOARD:
            return userType === UserType.Student
                ? isStudentDashboardNavActive(pages)
                : isTeacherDashboardNavActive(pages);
        default:
            return undefined;
    }
};
