import React, { useEffect, useState } from "react";

type Args = {
    container?: React.RefObject<HTMLElement>;
    onSwipeUp?: () => void;
    onSwipeDown?: () => void;
};

const useSwipe = ({ container: _container, onSwipeUp, onSwipeDown }: Args) => {
    const [container, setContainer] = useState<HTMLElement | Window>();

    useEffect(() => {
        if (container) {
            return;
        } else if (typeof _container === "undefined") {
            setContainer(window);
        } else if (_container.current) {
            setContainer(_container.current);
        } else {
            const intervalId = setInterval(() => {
                if (_container.current) {
                    setContainer(_container.current);
                }
            }, 200);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [_container, container]);

    useEffect(() => {
        if (!container) return;

        let touchStartY: number = 0;
        const handleStart = (e: TouchEvent) => {
            touchStartY = e.changedTouches?.[0]?.screenY;
        };
        const handleEnd = (e: TouchEvent) => {
            const deltaY = e.changedTouches?.[0]?.screenY - touchStartY;
            if (deltaY < 0 && onSwipeDown) {
                onSwipeDown();
            } else if (deltaY > 0 && onSwipeUp) {
                onSwipeUp();
            }
        };
        // @ts-ignore
        container.addEventListener("touchstart", handleStart);
        // @ts-ignore
        container.addEventListener("touchend", handleEnd);
        return () => {
            // @ts-ignore
            container.removeEventListener("touchstart", handleStart);
            // @ts-ignore
            container.removeEventListener("touchend", handleEnd);
        };
    }, [container, onSwipeDown, onSwipeUp]);
};
export default useSwipe;
