import { InitAssetsCachePayload } from "./action";
import { LearningAssetsCacheState } from "./state";
import * as localStorage from "../../../utils/localStorage";
import { CachedVersion } from "./state";

export function initLearningAssetsCacheState(
    payload: InitAssetsCachePayload
): LearningAssetsCacheState {
    const availableItemsinLocalStorage = localStorage.getItem<
        [String, CachedVersion][]
    >(localStorage.Key.LEARNING_SETS_CACHE_STATE);

    let learningSetState: Map<String, CachedVersion> = new Map();

    if (availableItemsinLocalStorage) {
        learningSetState = new Map([...availableItemsinLocalStorage]);
    }

    return {
        learningSetState: learningSetState,
        baseAssetsUrl: payload.baseAssetsUrl,
        resourcesEndpoint: payload.resourcesEndpoint,
        version: payload.version,
        currentDownloadTask: undefined,
    };
}
