import React, { useEffect } from "react";
import { FeatureFlagsProvider } from "@evidenceb/athena-common/modules/FeatureFlags";
import { MathJaxProvider } from "@evidenceb/athena-common/modules/HTMLParser";
import { ConfigProvider } from "./contexts/ConfigContext";
import { HomeProvider } from "./contexts/HomeContext";
import { ContentPagesProvider } from "./contexts/ContentPagesContext";
import { DataProvider } from "./contexts/DataContext";
import Root from "./router/Root";
import "./scss/App.scss";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { SessionProvider } from "./contexts/SessionContext";
import { ErrorProvider } from "./contexts/ErrorContext";
import { HelmetProvider } from "react-helmet-async";
import { SyncProvider } from "./contexts/Sync";
import { CacheProvider } from "./contexts/cache/CacheContext";
import { LearningAssetsCacheProvider } from "./contexts/cache/LearningAssetsCacheContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LangProvider from "./contexts/LangContext";
import { QueryClientContext } from "./contexts/QueryClientContext";
import { EventStreamProvider } from "./contexts/EventStreamContext";
import { OnlineStatusProvider } from "./contexts/OnlineStatusContext";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./pages/ErrorFallback/ErrorFallback";
const App = () => {
    // Remove React.StrictMode
    const { enableLinkTracking, trackPageView } = useMatomo();

    // 🚒 while we figure out what's going on with the query client in the
    // gameplays
    const queryClient = new QueryClient();
    const gameplaysQueryClient = new QueryClient();

    enableLinkTracking();
    useEffect(() => {
        trackPageView({});
    }, [trackPageView]);

    return (
        <QueryClientProvider
            client={gameplaysQueryClient}
            context={QueryClientContext}
        >
            <QueryClientProvider client={queryClient}>
                <ErrorProvider>
                    <DataProvider>
                        <ConfigProvider>
                            <FeatureFlagsProvider>
                                <HomeProvider>
                                    <ContentPagesProvider>
                                        <SessionProvider>
                                            <EventStreamProvider>
                                                <MathJaxProvider>
                                                    <HelmetProvider>
                                                        <SyncProvider>
                                                            <CacheProvider>
                                                                <LearningAssetsCacheProvider>
                                                                    <LangProvider>
                                                                        <OnlineStatusProvider>
                                                                            <ErrorBoundary
                                                                                FallbackComponent={
                                                                                    ErrorFallback
                                                                                }
                                                                            >
                                                                                <Root />
                                                                            </ErrorBoundary>
                                                                        </OnlineStatusProvider>
                                                                    </LangProvider>
                                                                </LearningAssetsCacheProvider>
                                                            </CacheProvider>
                                                        </SyncProvider>
                                                    </HelmetProvider>
                                                </MathJaxProvider>
                                            </EventStreamProvider>
                                        </SessionProvider>
                                    </ContentPagesProvider>
                                </HomeProvider>
                            </FeatureFlagsProvider>
                        </ConfigProvider>
                    </DataProvider>
                </ErrorProvider>
            </QueryClientProvider>
        </QueryClientProvider>
    );
};

export default App;
