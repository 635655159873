import {
    ActivityShell,
    PartialExerciseResult,
} from "@evidenceb/gameplay-interfaces";
import {
    ExerciseDefinition,
    GameplayType,
} from "@evidenceb/athena-common/interfaces/Exercise";
import { GameplayProps } from "@evidenceb/athena-common/interfaces/Gameplay";
import { UntypedFeedback } from "@evidenceb/athena-common/modules/Content";
import {
    LearningItem,
    LearningSet,
    Resources,
} from "../../../interfaces/AthenaResources";
import { Shell } from "../../../interfaces/Player";

// These are the types for the ResourceSeries providers/etc. They can be used as
// is, where default types are provided for the generics, or customized with
// more specific types as needed. It is then the responsibility of the developer
// to make sure that more precise types are used within a provider that also
// implements those same custom types

/**
 * State of the resource series.
 *
 * Can be customized with a specific format for resource items (learning item
 * and learning sets), otherwise the default are any learning sets and learning
 * items containing ExerciseDefinition data. Can also be customized with
 * additional information.
 */
export type ResourceSeries<
    Res extends ResourceSeriesRecord = ResourceSeriesRecord,
    AdditionalInformation = { totalItems: number; isInitialTest?: boolean }
> = {
    /** id of module/playlist/etc. that is the outermost learning set */
    entryLearningSetId: string;
    /**
     * key-value record of the learning items and sets available in the
     * resourceSeries. Children items and sets of learning sets are referenced
     * by id.
     *
     * It doesn't need to be exhaustive (there can be missing learning items or
     * sets) as long as a strategy is in place in a ResourceSeriesSideEffect to
     * fetch the missing data when the ResourceSeries is in the
     * FetchingResources execution stage
     */
    resources: Res;
    /**
     * List of the results given to items that appeared earlier in the
     * resourceSeries
     */
    results: ResourceResult<AdditionalInformation>[];
    /**
     * percentage (of 100) corresponding to the progress of the user in the
     * resourceSeries
     */
    progression: number;
    /**
     * Current step of execution of the resource series
     */
    executionStage: ResourceSeriesExecutionStage;

    /**
     * Ids of the current item and its parents, from item to highest level
     * parent
     */
    hierarchy?: string[];
    /**
     * Result for the current item, if a result was submitted
     */
    result?: ResourceResult<AdditionalInformation>;
    /**
     * Starting at 1, number of the try for the same item the user is on
     */
    currentTry: number;
    /**
     * If the user is retrying, list of the previous results of the same item
     */
    _prevTriesResults: ResourceResult<AdditionalInformation>[];
    /**
     * Timestamp of the start of the current item
     */
    _startTimestamp?: number;

    /**
     * Execution stage that comes after the current item. Is undefined while the
     * current stage is playing the current item
     */
    comingNext:
        | undefined
        | Exclude<
              ResourceSeriesExecutionStage,
              ResourceSeriesExecutionStage.ShowingCurrentExerciseResultFeedback
          >;

    /**
     * Additional props that are passed to the Gameplay component
     */
    additionalGPProps?: Partial<GameplayProps>;
    /**
     * Additional props that are passed to the Shell
     */
    additionalShellOpts?: Partial<Shell["opts"]>;
    /**
     * Any additional information required for the resource series
     */
    additionalInformation: AdditionalInformation;
};

export type ResourceSeriesAction<
    Record extends ResourceSeriesRecord,
    AdditionalInfo
> =
    | {
          type: "recordCurrentExerciseResult";
          result: PartialExerciseResult;
      }
    | { type: "confirmCurrentExerciseResult" }
    | { type: "retryCurrentExercise" }
    | { type: "goToNextExercise" }
    | {
          type: "goToExercise";
          hierarchy: string[];
      }
    | {
          type: "fetch-resources";
          resources: Record;
      }
    | {
          type: "escHatch-updateResults";
          value:
              | ResourceSeries<Record, AdditionalInfo>["results"]
              | ((
                    value: ResourceSeries<Record, AdditionalInfo>["results"]
                ) => ResourceSeries<Record, AdditionalInfo>["results"]);
      }
    | {
          type: "escHatch-updateResourceSeries";
          value:
              | ResourceSeries<Record, AdditionalInfo>
              | ((
                    curr: ResourceSeries<Record, AdditionalInfo>
                ) => ResourceSeries<Record, AdditionalInfo>);
      }
    | {
          type: "escHatch-resetStartTimestamp";
      };

export type ResourceResult<AdditionalInfo = unknown> = PartialExerciseResult & {
    try: number;
    feedback?: UntypedFeedback;
    feedbackType?: "correct" | "incorrect" | "moderate";
    /**
     * Ids of the parent of the item, from most direct parent to entry
     * learning set id
     */
    parentsHierarchy: string[];
    itemId: string;
    duration: number;
    additionalInformation: AdditionalInfo;
};

export type ResourceSeriesItem<
    Data extends ResourceSeriesItemData = ResourceSeriesItemData,
    Context = unknown
> = LearningItem<Data, Context, GameplayType> & {
    /** @default Wizard */
    shell?: ActivityShell;
};

export type ResourceSeriesSet<
    Item extends string | undefined = string | undefined,
    Set extends string | undefined = string | undefined,
    Context = unknown,
    Status = unknown,
    Type extends string = string
> = LearningSet<Item, Set, Context, Status, Type>;

export type ResourceSeriesRecord<
    ItemData extends ResourceSeriesItemData = ResourceSeriesItemData,
    ItemContext = unknown,
    SetItem extends string | undefined = string | undefined,
    SetSet extends string | undefined = string | undefined,
    SetContext = unknown,
    SetStatus = unknown,
    SetType extends string = string
> = Resources<
    ResourceSeriesItem<ItemData, ItemContext>,
    ResourceSeriesSet<SetItem, SetSet, SetContext, SetStatus, SetType>
>;

export type ResourceSeriesReducer<
    Res extends ResourceSeriesRecord = ResourceSeriesRecord,
    AdditionalInfo = unknown
> = (
    state: ResourceSeries<Res, AdditionalInfo>,
    action: ResourceSeriesAction<Res, AdditionalInfo>
) => ResourceSeries<Res, AdditionalInfo>;

export type ResourceSeriesItemData = Omit<ExerciseDefinition, "type" | "id">;

export enum ResourceSeriesExecutionStage {
    PlayingCurrentExercise = "PLAYING_CURRENT_EXERCISE",
    RetryingExercise = "RETRYING_EXERCISE",
    ShowingCurrentExerciseResultFeedback = "SHOWING_CURRENT_EXERCISE_RESULT_FEEDBACK",
    ShowEndOfResourceSeries = "SHOWING_END_OF_RESOURCE_SERIES",
    FetchingResource = "FETCHING_RESOURCE",
}

export type ComingNext = Exclude<
    ResourceSeriesExecutionStage,
    ResourceSeriesExecutionStage.ShowingCurrentExerciseResultFeedback
>;
