import React, { useContext } from "react";
import { configStore } from "../../../contexts/ConfigContext";
import { showInHeader } from "../utils";
import { useUserType } from "../../../hooks/useUserInfo";
import { Pagetype } from "../../../interfaces/Config";

import { useBreakpoints } from "@evidenceb/athena-common/helpers/breakpoints";
import { Button } from "@evidenceb/athena-common/design-system/Button";
import { defineMessages, useIntl } from "react-intl";
import { UserType } from "../../../interfaces/User";
import { useHistory } from "react-router-dom";
import { sessionStore } from "../../../contexts/SessionContext";
import { useOverlayTriggerState } from "react-stately";
import { useDismissMessage } from "../../MessageDismiss/MessageDismiss";
import SwitchUserModal from "../../Specimen/Modal/Modal";
import { DismissableMessages } from "../../../interfaces/Session";
import { commonMessages } from "../../../utils/messages";

const UserTypeSwitch = () => {
    const { isMobile } = useBreakpoints();
    const intl = useIntl();
    const history = useHistory();
    const { setSession } = useContext(sessionStore);
    const {
        config: { pages },
    } = useContext(configStore);

    const userType = useUserType();
    const userTypeMessage = intl.formatMessage(
        commonMessages[userType === UserType.Student ? "teacher" : "student"]
    );

    const hasUserSwitch = pages.some(
        (page) =>
            showInHeader(userType, page) &&
            page.type === Pagetype.SWITCH_USER_TYPE
    );

    const switchUserModal = useOverlayTriggerState({});
    const [switchPromptDismissed, dismissSwitchPrompt] = useDismissMessage(
        DismissableMessages.specimenSwitchUserModal
    );
    const switchUserHandler = () => {
        setSession((session) => ({
            ...session,
            userType:
                session.userType === UserType.Teacher
                    ? UserType.Student
                    : UserType.Teacher,
        }));
        history.push("/");
    };

    if (!hasUserSwitch) return null;

    const buttonClickHandler = () => {
        if (switchPromptDismissed) switchUserHandler();
        else switchUserModal.open();
    };

    return (
        <>
            <Button
                key={`navItem-${Pagetype.SWITCH_USER_TYPE}`}
                className="switch-btn"
                icons={[
                    {
                        path: "switch-user",
                    },
                ]}
                label={
                    isMobile
                        ? userTypeMessage
                        : intl.formatMessage(messages.switchUserType, {
                              userType: userTypeMessage.toLowerCase(),
                          })
                }
                onClick={buttonClickHandler}
                size={isMobile ? "small" : "medium"}
            />

            {switchUserModal.isOpen && (
                <SwitchUserModal
                    state={switchUserModal}
                    onModalDismiss={switchUserModal.close}
                    onClick={() => {
                        switchUserHandler();
                        switchUserModal.close();
                        dismissSwitchPrompt();
                    }}
                    title={intl.formatMessage(messages.switchTitle)}
                    content={intl.formatMessage(messages.modalDescription)}
                    buttonLabel={intl.formatMessage(messages.buttonTitle)}
                />
            )}
        </>
    );
};
export default UserTypeSwitch;

const messages = defineMessages({
    switchUserType: {
        id: "a11y-switchUserType",
        defaultMessage: "Switch to the {userType} version",
    },
    switchTitle: {
        id: "header-specimen-switchToStudent-modalTitle",
        defaultMessage: "Access the student version",
    },
    modalDescription: {
        id: "header-specimen-switchToStudent-modalDescription",
        defaultMessage:
            "On the student interface, you can start a personalized course. The algorithm selects, in a zone of the activity bank that evolves as you go along, the most useful exercises to help the student progress. Each student benefits from a different exercise path.",
    },
    buttonTitle: {
        id: "header-specimen-switchToStudent-modalButton",
        defaultMessage: "Access the student version",
    },
});
