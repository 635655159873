import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { commonMessages } from "../../utils/messages";
import "./ErrorFallback.scss";
import { Button } from "@evidenceb/athena-common/design-system/Button";

const ErrorFallback = () => {
    const intl = useIntl();
    return (
        <div className="error-fallback">
            <div className="error-fallback__content">
                <h1>{intl.formatMessage(messages.title)}</h1>
                <p>{intl.formatMessage(messages.message)}</p>
                <div className="error-fallback__action">
                    <Button
                        label={intl.formatMessage(commonMessages.goHome)}
                        asLink={{
                            href: "/",
                            rel: "noreferrer",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ErrorFallback;

const messages = defineMessages({
    title: {
        id: "error-fallback-title",
        defaultMessage: "An error has occured",
    },
    message: {
        id: "error-fallback-message",
        defaultMessage:
            "We are unable to retrieve the necessary information. Please try again later.",
    },
});
