import React, { CSSProperties } from "react";
import cn from "classnames";
import "./Tabs.scss";

import {
    AriaTabListProps,
    useTab,
    useTabList,
    useTabPanel,
} from "@react-aria/tabs";
import { TabListProps, TabListState, useTabListState } from "react-stately";
import { Node } from "@react-types/shared";

export interface TabsProps {
    className?: string;
    /**
     * @default "round"
     */
    appearance?: "round" | "rectangular";

    /**
     * @default true
     */
    defaultColor?: boolean;
    style?: CSSProperties;
    variant?: "default" | "accent" | "accent-border";
    accentColor?: string;
    defaultSelectedKey?: string;
}

const Tabs = ({
    className,
    appearance = "rectangular",
    defaultColor = true,
    style,
    defaultSelectedKey,
    variant = "default",
    accentColor = "var(--element-teacher-default)",
    ...props
}: TabsProps & TabListProps<any> & AriaTabListProps<any>) => {
    let state = useTabListState(props);
    let ref = React.useRef<HTMLDivElement>(null);
    let { tabListProps } = useTabList(props, state, ref);

    if (!state.selectedItem && defaultSelectedKey)
        state.setSelectedKey(defaultSelectedKey);

    return (
        <div
            className={cn(
                "ds-tabs",
                `ds-tabs--${appearance}`,
                className,
                `ds-tabs--variant-${variant}`,
                {
                    "ds-tabs--default-color": defaultColor,
                }
            )}
            // @ts-ignore
            style={{ "--ds-tabs-accent-color": accentColor, ...style }}
        >
            <div {...tabListProps} ref={ref}>
                {[...state.collection].map((item) => (
                    <Tab
                        key={item.key}
                        item={item}
                        state={state}
                        orientation={props.orientation}
                    />
                ))}
            </div>
            <TabPanel key={state.selectedItem?.key} state={state} />
        </div>
    );
};

interface TabProps {
    item: Node<any>;
    state: TabListState<any>;
    orientation: AriaTabListProps<any>["orientation"];
}

function Tab({ item, state }: TabProps) {
    let { key, rendered } = item;
    let ref = React.useRef<HTMLButtonElement>(null);
    let { tabProps } = useTab({ key }, state, ref);
    return (
        <button {...tabProps} ref={ref}>
            {rendered}
        </button>
    );
}

interface TabPanelProps {
    state: TabProps["state"];
}

function TabPanel({ state, ...props }: TabPanelProps) {
    let ref = React.useRef<HTMLDivElement>(null);
    let { tabPanelProps } = useTabPanel(props, state, ref);
    return (
        <div {...tabPanelProps} tabIndex={-1} ref={ref}>
            {state.selectedItem?.props.children}
        </div>
    );
}

export default Tabs;
