import {
    ExerciseDefinition,
    GameplayType,
} from "@evidenceb/athena-common/interfaces/Exercise";
import { HierarchyPath } from "../../../components/TreeCheckboxGroup";
import {
    LearningItem,
    LearningSet,
    LearningSetTypes,
} from "../../../interfaces/AthenaResources";
import {
    ActivityShell,
    PartialExerciseResult,
} from "@evidenceb/gameplay-interfaces";
import { ResourcePath } from "./Teacher/utils";

export enum CreateSteps {
    select = "SELECT",
    organize = "ORGANIZE",
    describeAndSend = "DESCRIBE_AND_SEND",
}

export type Playlist<Item = string> = Omit<
    LearningSet<
        Item,
        undefined,
        undefined,
        { status: PlaylistStatus },
        "playlist"
    >,
    "id" | "type"
> & {
    learning_set_id: string;
    author_id: string;
};

export enum PlaylistStatus {
    SENT = "sent",
    ARCHIVED = "archived",
    DRAFT = "draft",
}

export type PlaylistItem = Omit<
    LearningItem<
        Omit<ExerciseDefinition, "id" | "type">,
        PlaylistItemContext,
        GameplayType
    >,
    "id" | "type"
> & {
    learning_item_id: string;
};

export type MinimalPlaylistItem = Pick<
    PlaylistItem,
    "learning_item_id" | "learning_item_context"
>;

export type PlaylistItemContext = {
    /**
     * Ids of the parent learning sets from most distant parent to closest
     * parent
     */
    parents: string[];
    shell: ActivityShell;
    /**
     * If undefined, assume LearningSetTypes.Module
     */
    itemType?: LearningSetTypes;
};

export type EditablePlaylist<T = string> = Omit<
    Playlist<T>,
    "created" | "updated"
>;

export type DraftPlaylist<Item = ResourcePath> = Pick<
    EditablePlaylist<Item>,
    "author_id" | "status" | "learning_items" | "description"
> & {
    dirty: boolean;
    learning_set_id?: EditablePlaylist["learning_set_id"];
    name?: string;
};

export interface PlaylistWithProgress extends Playlist<PlaylistItem> {
    progress?: PlaylistProgress;
}

export type Assignment = {
    id: string;
    students: { id: string; first_name: string; last_name: string }[];
    teacher: string;
    assigned_resource?: {
        resource_id: string;
        resource_type: "playlist" | "workshop";
    };
};

export type EditableAssignment = Omit<Assignment, "students"> & {
    students: string[];
};

export type DraftAssignment = Omit<
    EditableAssignment,
    "id" | "students" | "assigned_resource"
> & {
    assigned_resource: Omit<
        Required<EditableAssignment>["assigned_resource"],
        "resource_type"
    >;
    students: HierarchyPath[];
};

export type StudentPlaylistProgress = {
    id: string;
    first_name: string;
    last_name: string;
    exercises_done: number;
};

export type ClassroomsProgress = {
    id: string;
    name: string;
    students_progress: StudentPlaylistProgress[];
};

export type PlaylistProgress = {
    id: string;
    exercises_count: number;
    average_progress: number;
    classrooms_progress: ClassroomsProgress[];
};

export type PlaylistAnswer = {
    exerciseId: string;
    result: PartialExerciseResult<string> | undefined;
    timestamp: string;
};

export type ExerciseResult = {
    answer?: string;
    duration: string;
    score: {
        scales: number;
        raw: number;
        min: number;
    };
    success: boolean;
};

export type ResultsPayload = {
    student_answers: {
        [exerciseId: string]: {
            result: ExerciseResult;
            timestamp: string;
        }[];
    };
};

export type PartialClassroomsProgress = Pick<
    ClassroomsProgress,
    "id" | "name"
> & {
    students_progress: PartialStudentPlaylistProgress[];
};
type PartialStudentPlaylistProgress = Omit<
    StudentPlaylistProgress,
    "exercises_done"
> &
    Partial<Pick<StudentPlaylistProgress, "exercises_done">>;
