import { Page, Pagetype } from "../../interfaces/Config";

export const headerIcons: Partial<Record<Pagetype, string>> = {
    [Pagetype.HOME]: "home",
    [Pagetype.WORK_MODES]: "resources",
    [Pagetype.MODULELIST]: "work",
    [Pagetype.DASHBOARD]: "dashboard",
    [Pagetype.CONTENTPAGE]: "guide",
    [Pagetype.WORK]: "work",
};

export interface PageTypeWithIcon extends Page {
    iconPath: string;
}
