/**
 * Transforms an array of objects with and id attribute into an id-object record
 *
 * Usage: array.reduce(...recordById)
 */
export const recordById = [
    <Obj extends { id: string }>(
        record: Record<string, Obj>,
        item: Obj
    ): Record<string, Obj> => ({
        ...record,
        [item.id]: item,
    }),
    {},
] as const;

export const mapObjectValues = <T, U>(
    obj: { [key: string]: T },
    mapFn: (elem: T) => U
): { [key: string]: U } => {
    return Object.entries(obj)
        .map<[string, U]>(([key, value]) => [key, mapFn(value)])
        .reduce<{ [key: string]: U }>(
            (obj, [key, value]) => ({ ...obj, [key]: value }),
            {}
        );
};

export const filterObjectKeys = <T>(
    obj: { [key: string]: T },
    filterFn: (entry: [key: string, elem: T]) => boolean
): { [key: string]: T } => {
    return Object.entries(obj)
        .filter((entry) => filterFn(entry))
        .reduce<{ [key: string]: T }>(
            (obj, [key, value]) => ({ ...obj, [key]: value }),
            {}
        );
};

export const filterDefined = <T>(item: T | undefined): item is T =>
    typeof item !== "undefined";

export const lastItem = <T>(array: T[]) => array[array.length - 1];

export const getKey =
    <T extends string>(key: T) =>
    <U extends { [key in T]: any }>(item: U) =>
        item[key];
