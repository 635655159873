import { defineMessages } from "react-intl";

export const accountMessages = defineMessages({
    subjectChoiceTitle: {
        id: "account-subjectChoiceTitle",
        defaultMessage: "Subject",
    },
    subjectChoiceMessage: {
        id: "account-subjectChoiceMessage",
        defaultMessage:
            "Choose the subject you teach to see resources tailored to your needs",
    },
    subjectChoiceModalTitle: {
        id: "account-subjectChoiceModalTitle",
        defaultMessage: "Do you want to change the subject?",
    },
    subjectChoiceModalTitleMobile: {
        id: "account-subjectChoiceModalTitleMobile",
        defaultMessage: "Change subject?",
    },
    subjectChoiceModalMessage: {
        id: "account-subjectChoiceModalMessage",
        defaultMessage:
            "If you change your subject, you will no longer have access to the resources of the previous subject in your application.",
    },
    subjectChoiceModalBtnConfirm: {
        id: "account-subjectChoiceModalBtnChangeSubject",
        defaultMessage: "Change subject",
    },
    avatarChoiceTitle: {
        id: "account-avatarChoiceTitle",
        defaultMessage: "Avatar",
    },
    avatarChoiceMessage: {
        id: "account-avatarChoiceMessage",
        defaultMessage: "Choose an avatar",
    },
    avatarChoiceMessageTeacher: {
        id: "account-avatarChoiceMessageTeacher",
        defaultMessage: "Choose an avatar",
    },
    backgroundChoiceMessage: {
        id: "account-avatarBackgroundChoiceMessage",
        defaultMessage: "Choose a background color",
    },
    backgroundChoiceMessageTeacher: {
        id: "account-avatarBackgroundChoiceMessageTeacher",
        defaultMessage: "Choose a background color",
    },
    currentAvatarMessage: {
        id: "account-currentAvatarMessage",
        defaultMessage: "My avatar:",
    },
    defaultAvatarButton: {
        id: "account-defaultAvatarButton",
        defaultMessage: "Default avatar: my initials",
    },
});
