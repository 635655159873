import { useContext } from "react";
import { sessionStore } from "../contexts/SessionContext";
import { configStore } from "../contexts/ConfigContext";
import { Subject } from "../interfaces/User";
import { mappingTreeToSubjects } from "../utils/mapping";

export const PLACEHOLDER_SUBJECT: Subject = {
    id: "subject",
    name: "Subject",
    tags: ["subject"],
    colors: {
        default: `var(--element-subject1-default)`,
        hover: `var(--element-subject1-hover)`,
        disabled: `var(--element-subject1-disabled)`,
        defaultDarkBg: `var(--element-subject1-default-dark-bg)`,
        disabledDarkBg: `var(--element-subject1-disabled-dark-bg)`,
    },
};

export const useSubjects = (): Subject[] => {
    const {
        config: { mappingTree },
    } = useContext(configStore);

    if (!mappingTree) return [PLACEHOLDER_SUBJECT];

    return mappingTreeToSubjects(mappingTree);
};

/**
 * Returns the active subject. Should not be used in a context where the active
 * subject has not been determined yet
 */
export const useActiveSubject = (): Subject => {
    const {
        session: {
            extra: { activeSubject },
        },
    } = useContext(sessionStore);
    const subjects = useSubjects();
    const activeSubjectObj = subjects.find(
        (subject) => subject.id === activeSubject
    );
    if (!activeSubjectObj)
        throw new Error("No subject matching active subject id");
    return activeSubjectObj;
};
