import {
    ResourceSeries,
    ResourceSeriesExecutionStage,
    ResourceSeriesItem,
    ResourceSeriesItemData,
    ResourceSeriesRecord,
} from "./types";

export const placeholderResourceSeries = (entryId: string): ResourceSeries => ({
    entryLearningSetId: entryId,
    resources: {},
    hierarchy: [],
    progression: 0,
    results: [],
    _prevTriesResults: [],
    currentTry: 1,
    executionStage: ResourceSeriesExecutionStage.PlayingCurrentExercise,
    comingNext: undefined,
    additionalInformation: {
        totalItems: 1,
    },
});

export function getLearningItem<
    Data extends ResourceSeriesItemData,
    Context,
    AdditionalInfo
>({
    resources,
    hierarchy,
}: Pick<
    ResourceSeries<ResourceSeriesRecord<Data, Context>, AdditionalInfo>,
    "resources" | "hierarchy"
>): ResourceSeriesItem<Data, Context> | undefined {
    if (!hierarchy) return undefined;
    const resource = resources[hierarchy[0]];
    if (!resource) return undefined;
    if (resource.type === "item") return resource;
    throw new Error(
        `Lowest hierarchy should be an item, not a set. Found ${resource.type} for ${resource.id}`
    );
}

export const isResourceSeriesFinished = (
    resourceSeries: Pick<ResourceSeries, "executionStage">
) =>
    resourceSeries.executionStage ===
    ResourceSeriesExecutionStage.ShowEndOfResourceSeries;
