import * as React from "react";
import type { OverlayTriggerState } from "react-stately";
import type { AriaPopoverProps } from "@react-aria/overlays";
import { usePopover, DismissButton, Overlay } from "@react-aria/overlays";
import cn from "classnames";

export interface Props extends Omit<AriaPopoverProps, "popoverRef"> {
    children: React.ReactNode;
    state: OverlayTriggerState;
    className?: string;
    popoverRef?: React.RefObject<HTMLDivElement>;
    parentContainerRef?: React.RefObject<HTMLDivElement>;
    /**
     * The container element in which the overlay portal will be placed.
     * @default document.body
     */
    portalContainer?: Element;
}

function Popover({ portalContainer, ...props }: Props) {
    const ref = React.useRef<HTMLDivElement>(null);
    const {
        offset = 8,
        popoverRef = ref,
        state,
        children,
        className,
        isNonModal,
    } = props;

    const { popoverProps, underlayProps } = usePopover(
        {
            ...props,
            popoverRef,
            offset,
        },
        state
    );

    if (props && props.parentContainerRef?.current) {
        popoverProps.style = {
            ...popoverProps.style,
            width: props.parentContainerRef.current.clientWidth + "px",
        };
    }

    return (
        <Overlay portalContainer={portalContainer}>
            {!isNonModal && (
                <div {...underlayProps} className="ds-popover__underlay" />
            )}
            <div
                {...popoverProps}
                ref={popoverRef}
                className={cn("ds-popover", className)}
            >
                {children}
                {!isNonModal && <DismissButton onDismiss={state.close} />}
            </div>
        </Overlay>
    );
}

export default Popover;
