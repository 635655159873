import React from "react";
import cn from "classnames";
import {
    AccordionHeader,
    AccordionInternalProps,
} from "@evidenceb/athena-common/design-system/Accordion";
import { HierarchyPath, TreeNode } from "../types";

type Props = AccordionInternalProps & {
    item: TreeNode;
    label: string;
    depth: number;
    hierarchyPath: HierarchyPath;
    /** id of the fieldset/group the header labels */
    groupId: string;
    startingHeadingLevel: number;
    onActivateNode?: (hierarchyPath: HierarchyPath) => void;
    isActiveNode: boolean;
};

const AccordionHeaderWithActive = ({
    item,
    depth,
    label,
    hierarchyPath,
    groupId,
    onActivateNode,
    startingHeadingLevel,
    isActiveNode,
    ...accordionProps
}: Props) => {
    const onClick =
        item.readOnly || !onActivateNode
            ? undefined
            : () => {
                  onActivateNode(hierarchyPath);
              };

    return (
        <AccordionHeader
            {...accordionProps}
            onClick={onClick}
            className={cn("tree-checkbox-list__title", `--indent-${depth}`, {
                "--btn": !item.readOnly,
                "tree-checkbox-list__title--active": isActiveNode,
            })}
            /* @ts-ignore see constraints in index file */
            container={`h${startingHeadingLevel + depth}`}
        >
            <div id={groupId} className="tree-checkbox-list__title-content">
                {label}
            </div>
        </AccordionHeader>
    );
};
export default AccordionHeaderWithActive;
