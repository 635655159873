import { openExerciceAssetsCache } from "../cacheCommon";
import { learningSetsIdsAndVersions } from "./action";
import {
    fetchLearningSetData,
    retrieveLearningAssetUrls,
} from "./retrieveMethods";
import pMap from "p-map";

const getRelatedLearningSetIdsAndVersions = (
    learningSetData: any,
    learningSetParentId: string
) =>
    learningSetData[learningSetParentId]?.learning_sets
        ? {
              learningSets: learningSetData[learningSetParentId]?.learning_sets,
              version: learningSetData[learningSetParentId]?.version,
          }
        : {};

const recursivelyGetRelatedLearningSetIdsAndVersions = (
    learningSetData: any,
    learningSetParentId: string
) => {
    const everyRelatedLearningSetId = [];

    // push parent id first
    const parentIdToAdd = {
        learningSets: [learningSetParentId],
        version: learningSetData[learningSetParentId]?.version,
    };
    everyRelatedLearningSetId.push(parentIdToAdd);

    const learningSetParentIds = getRelatedLearningSetIdsAndVersions(
        learningSetData,
        learningSetParentId
    );

    if (learningSetParentIds?.learningSets?.length > 0) {
        // add each id with its corresponding version to ids array
        learningSetParentIds?.learningSets.map((id: string) => {
            everyRelatedLearningSetId.push({
                learningSets: [id],
                version: learningSetData[id]?.version ?? null,
            });
        });

        learningSetParentIds.learningSets.map((id: string) => {
            const learningSetNestedIds =
                recursivelyGetRelatedLearningSetIdsAndVersions(
                    learningSetData,
                    id
                );

            if (learningSetNestedIds.length > 0) {
                learningSetNestedIds.map((item) => {
                    everyRelatedLearningSetId.push(item);
                });
            }
        });
    }

    if (learningSetParentIds?.learningSets?.length === 1) {
        const learningSetNestedIds: learningSetsIdsAndVersions[] =
            recursivelyGetRelatedLearningSetIdsAndVersions(
                learningSetData,
                learningSetParentIds?.learningSets[0]
            );

        if (learningSetNestedIds.length > 0) {
            everyRelatedLearningSetId.push(learningSetNestedIds[0]);
        }
    }

    return everyRelatedLearningSetId;
};

export const downloadToCache = async (
    cache: Cache,
    assetUrl: string
): Promise<void> => {
    try {
        const isCached = await cache.match(assetUrl);
        if (!isCached) {
            const response = await fetch(assetUrl, { mode: "no-cors" });
            cache.put(assetUrl, response);
        }
    } catch (error) {
        throw new Error(`Asset caching failed for ${assetUrl} !`);
    }
};

export const deleteFromCache = async (
    cache: Cache,
    assetUrl: string
): Promise<void> => {
    try {
        cache.delete(assetUrl);
    } catch (error) {
        throw new Error(`Asset delete failed for ${assetUrl} !`);
    }
};

export const performCacheOperation = async (
    learningSetId: string,
    abortSignal: AbortSignal,
    baseAssetsUrl: string,
    version: string,
    cacheOperation: (cache: Cache, assetUrl: string) => Promise<void>,
    endpoint: string
) => {
    try {
        abortSignal.throwIfAborted();
        const cache = await openExerciceAssetsCache();
        abortSignal.throwIfAborted();
        const learningAssetUrls = await retrieveLearningAssetUrls(
            learningSetId,
            baseAssetsUrl,
            version,
            endpoint
        );

        const LearningSetData = await fetchLearningSetData(
            learningSetId,
            endpoint
        );

        const everyLearningSetId =
            recursivelyGetRelatedLearningSetIdsAndVersions(
                LearningSetData,
                learningSetId
            );

        abortSignal.throwIfAborted();

        await pMap(
            Array.from(learningAssetUrls),
            (assetUrl) => cacheOperation(cache, assetUrl),
            { concurrency: 10 }
        );
        abortSignal.throwIfAborted();

        return { everyLearningSetId, status: true };
    } catch (error) {
        console.error("cacheOperation aborted", error);
    }
};
