import { AIType } from "@evidenceb/ai-handler";
import { makeGraphFromData } from "@evidenceb/parametric-graph";
import { Config } from "../interfaces/Config";
import { MinimalData } from "../interfaces/Data";
import { getActivitiesInModule, getModuleById } from "./dataRetrieval";

export const AI_IDS = {
    [AIType.BanditManchot]: (config: Config["ai"]) => config.id,
    [AIType.AdaptiveTest]: () => "adaptiveTest",
    [AIType.CNEDAdaptiveTest]: () => "CNED_adaptiveTest",
};

export const getParams = (
    config: Required<Config>["ai"],
    data: MinimalData,
    moduleId: string
) => {
    const mod = getModuleById(moduleId, data);
    return {
        ...config.baseConfig,
        graph: {
            ...config.baseConfig.graph,
            graph: config.moduleConfig[mod.id],
            main_act: mod.id,
            exercises_ontology: getActivitiesInModule(mod, data),
            exercisesMetaData: [
                ...new Set(
                    data.activities.flatMap(({ exerciseIds }) => exerciseIds)
                ),
            ].map((id) => ({ id })),
            initialTest: config.initialTest![mod.id],
        },
    };
};

export const getGraph = (
    config: Required<Config>["ai"],
    data: MinimalData,
    moduleId: string
): any => {
    const params = getParams(config, data, moduleId);
    return makeGraphFromData(params, moduleId);
};
