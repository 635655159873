import React, { PropsWithChildren, useContext, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useActiveSubject, useSubjects } from "../../hooks/useActiveSubject";
import {
    Control,
    SegmentedControl,
} from "../../design-system-components/SegmentedControl/SegmentedControl";
import MiniLoader from "../../components/Loader/MiniLoader";
import { accountMessages } from "./accountMessages";

import { useBreakpoints } from "@evidenceb/athena-common/helpers/breakpoints";
import { Modal } from "@evidenceb/athena-common/design-system/Modal";
import Card from "../../design-system-components/Card/Card";
import { useSubjectChoice } from "../SubjectChoice/ChoiceSteps/api/useSubjectChoice";
import { commonMessages } from "../../utils/messages";
import { useOverlayTriggerState } from "react-stately";
import { sessionStore } from "../../contexts/SessionContext";
import useAthenaAPIClient from "../../hooks/queries/useAthenaAPIClient/useAthenaAPIClient";
import { useUserId } from "../../hooks/useUserInfo";
import { CacheNames, updateCache } from "../../utils/sw-cache";

import "./Account.scss";
import useOnlineStatus from "../../hooks/useOnlineStatus";

export default function Subject() {
    const intl = useIntl();
    const { setSession } = useContext(sessionStore);
    const isOnline = useOnlineStatus();

    const subjects = useSubjects();
    const activeSubject = useActiveSubject();
    const [subjectId, setSubjectId] = useState<string>(activeSubject.id);
    const prevSubjectId = useRef(activeSubject.id);

    const [_, { mutateAsync, isLoading }] = useSubjectChoice();

    const subjectChoiceModal = useOverlayTriggerState({ defaultOpen: false });
    const subjectModalWasOpenedRef = useRef(false);

    const updateSWCache = useUpdateSWCache();
    async function subjectChangeRequest(nextSubjectId: string) {
        await mutateAsync(nextSubjectId);
        setSession((session) => {
            return {
                ...session,
                extra: {
                    ...session.extra,
                    activeSubject: nextSubjectId,
                },
            };
        });
        updateSWCache();
    }

    async function onSegmentChange(nextSubjectId: string) {
        prevSubjectId.current = subjectId;
        setSubjectId(nextSubjectId);
        if (subjectModalWasOpenedRef.current) {
            try {
                await subjectChangeRequest(nextSubjectId);
            } catch (err) {
                console.error(err);
                setSubjectId(prevSubjectId.current);
            }
        } else {
            subjectChoiceModal.open();
            subjectModalWasOpenedRef.current = true;
        }
    }

    async function onSubjectChoiceConfirm() {
        subjectChoiceModal.close();
        try {
            await subjectChangeRequest(subjectId);
        } catch (err) {
            console.error(err);
            setSubjectId(prevSubjectId.current);
        }
    }

    function onSubjectChoiceCancel() {
        setSubjectId(prevSubjectId.current);
        subjectChoiceModal.close();
    }

    return (
        <>
            <div className="account-settings__parameters__subject">
                <h2>
                    {intl.formatMessage(accountMessages.subjectChoiceTitle)}
                </h2>
                <ContentContainer>
                    <div>
                        <p>
                            {intl.formatMessage(
                                accountMessages.subjectChoiceMessage
                            )}
                        </p>
                        <SegmentedControl
                            defaultValue={subjectId}
                            value={subjectId}
                            onChange={onSegmentChange}
                            isDisabled={isLoading || !isOnline}
                            color={isLoading ? "var(--text-main-disabled)" : ""}
                        >
                            {subjects.map((subject) => (
                                <Control value={subject.id} key={subject.id}>
                                    {subject.name}
                                </Control>
                            ))}
                        </SegmentedControl>
                        {isLoading && <MiniLoader />}
                    </div>
                </ContentContainer>
            </div>

            <ConfirmationModal
                open={subjectChoiceModal.isOpen}
                onClose={subjectChoiceModal.close}
                onConfirm={onSubjectChoiceConfirm}
                onCancel={onSubjectChoiceCancel}
            />
        </>
    );
}
const ContentContainer = ({ children }: PropsWithChildren) => {
    const { isMobile } = useBreakpoints();
    if (isMobile)
        return (
            <Card
                className="account-settings__parameters"
                contentClassName="account-settings__parameters__subject"
            >
                {children}
            </Card>
        );
    return <>{children}</>;
};

type ConfirmationModalProps = {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    onConfirm: () => void;
};

const ConfirmationModal = ({
    open,
    onCancel,
    onConfirm,
    onClose,
}: ConfirmationModalProps) => {
    const intl = useIntl();
    const { isMobile } = useBreakpoints();

    return (
        <Modal
            title={
                isMobile
                    ? intl.formatMessage(
                          accountMessages.subjectChoiceModalTitleMobile
                      )
                    : intl.formatMessage(
                          accountMessages.subjectChoiceModalTitle
                      )
            }
            open={open}
            closable={false}
            backdropClosable={false}
            onClose={onClose}
            footerButtons={[
                {
                    label: intl.formatMessage(commonMessages.cancel),
                    onClick: onCancel,
                    variant: "secondary",
                },
                {
                    label: intl.formatMessage(
                        accountMessages.subjectChoiceModalBtnConfirm
                    ),
                    onClick: onConfirm,
                },
            ]}
        >
            <p>
                {isMobile
                    ? intl.formatMessage(
                          accountMessages.subjectChoiceModalTitle
                      )
                    : intl.formatMessage(
                          accountMessages.subjectChoiceModalMessage
                      )}
            </p>
        </Modal>
    );
};

// To make sure that the teacher has their subject up to date with their latest
// change (because it would otherwise cause a big disruption in the use of the
// app), invalidate the service worker cache and recache the request
// see https://evidencebprod.atlassian.net/wiki/spaces/ExausTeam/pages/818511898/240311+Sync+cache+invalidation+strategy
const useUpdateSWCache = () => {
    const athenaAPIClient = useAthenaAPIClient();
    const userId = useUserId();

    return () => {
        updateCache(CacheNames.userTeacher, () => {
            athenaAPIClient.getTeacher(userId);
        });
    };
};
