import React from "react";
import {
    NotificationAggregate,
    NotificationStatus,
    StudentInfo,
} from "../Types";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import Notification from "../Notification/Notification";

export type Props = {
    notifications: NotificationAggregate[];
    emptyMessage: string;
    onNotificationReadStatusChange: (
        id: string | undefined,
        students: StudentInfo[],
        status: NotificationStatus
    ) => void;
};

const NotificationsTab = ({
    notifications,
    emptyMessage,
    onNotificationReadStatusChange,
}: Props) => {
    return (
        <>
            {notifications.length === 0 && (
                <div className="notification_no-notifications">
                    <Icon
                        path="notifs_none"
                        size={{ width: 280, height: 280 }}
                    />
                    <p>{emptyMessage}</p>
                </div>
            )}

            {notifications.length > 0 && (
                <section className="notifications__list">
                    {notifications.map((notification) => {
                        return (
                            <Notification
                                key={notification.id}
                                notification={notification}
                                onNotificationReadStatusChange={
                                    onNotificationReadStatusChange
                                }
                            />
                        );
                    })}
                </section>
            )}
        </>
    );
};
export default NotificationsTab;
