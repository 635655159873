import { Statement } from "@xapi/xapi";
import {
    AI_CATEGORIES,
    VERBS,
    WORKMODES_CATEGORIES,
    XAPI_REGISTRY,
} from "./const";
import {
    HierarchyIds,
    ResourceSeriesProperties,
} from "@evidenceb/gameplay-interfaces";
import { Emotion } from "../../interfaces/EmotionalReport";
import { WorkmodesWithStatements } from "./types";
import { durationToISO } from "./utils";
import { AIType } from "@evidenceb/ai-handler";

export const makeAIStatementParts = (
    aiId: string,
    aiType?: AIType
): Partial<Statement> => {
    const extensions = {
        [`${XAPI_REGISTRY}/extenstions/ai`]: {
            id: aiId,
        },
    };
    if (aiType)
        return {
            context: {
                contextActivities: {
                    category: [{ id: AI_CATEGORIES[aiType] }],
                },
                extensions,
            },
        };
    return {
        context: {
            extensions,
        },
    };
};

export const makeStandaloneAdaptiveTestStatementParts = (
    adaptiveTestNumber: number
): Partial<Statement> => {
    return {
        context: {
            extensions: {
                [`${XAPI_REGISTRY}/extensions/adaptive-test`]:
                    adaptiveTestNumber,
            },
        },
    };
};

export const makeExerciseResultStatementParts = (
    historyItem: HierarchyIds &
        Partial<ResourceSeriesProperties> & {
            score: number;
            success: boolean;
            answer?: any;
            duration: number;
        }
): Partial<Statement> => {
    return {
        verb: historyItem.success ? VERBS.passed : VERBS.failed,
        object: {
            id: XAPI_REGISTRY + "/exercise/" + historyItem.exerciseId,
            objectType: "Activity",
        },
        result: {
            score: {
                scaled: historyItem.score,
                raw: historyItem.score,
                min: 0,
                max: 1,
            },
            success: historyItem.success,
            response: JSON.stringify(historyItem.answer),
            duration: durationToISO(historyItem.duration / 1000),
        },
        context: {
            contextActivities: {
                parent: [
                    {
                        id: `${XAPI_REGISTRY}/module/${historyItem.moduleId}/objective/${historyItem.objectiveId}/activity/${historyItem.activityId}`,
                        objectType: "Activity",
                    },
                ],
            },
            extensions: {
                [`${XAPI_REGISTRY}/extensions/common`]: {
                    initialTest: historyItem.isInitialTest,
                    isAdaptiveTest: historyItem.isAdaptiveTest,
                },
            },
        },
    };
};

export const makeEmotionalStatementParts = (
    emotion: Emotion,
    reportType: "FORCED" | "SELF-REPORT"
): Partial<Statement> => {
    return {
        verb: VERBS.expressed,
        object: {
            id: XAPI_REGISTRY + "/emotion/" + emotion.toLowerCase(),
            objectType: "Activity",
        },
        context: {
            contextActivities: {
                other: [
                    {
                        id: `${XAPI_REGISTRY}/emotional-report-type/${reportType.toLowerCase()}`,
                        objectType: "Activity",
                    },
                ],
            },
        },
    };
};

export const makeLoggedInStatementParts = (
    variation: string
): Partial<Statement> => {
    return {
        verb: VERBS["logged-in"],
        object: {
            objectType: "Activity",
            id: `${XAPI_REGISTRY}/objects/variation/${variation}`,
        },
    };
};

export const makeRecommendationsClickStatementParts = (
    linkUrl: string,
    moduleId: string
): Partial<Statement> => {
    return {
        verb: VERBS["accessed"],
        object: {
            objectType: "Activity",
            id: linkUrl,
            definition: {
                type: `${XAPI_REGISTRY}/activities/recommendation`,
            },
        },
        context: {
            contextActivities: {
                parent: [
                    {
                        id: `${XAPI_REGISTRY}/module/${moduleId}`,
                        objectType: "Activity",
                    },
                ],
            },
        },
    };
};

export const makeInternalResourceViewedStatementParts = (
    type: "voluntary" | "compulsory"
): Partial<Statement> => {
    return {
        verb: VERBS["viewed"],
        context: {
            extensions: {
                [`${XAPI_REGISTRY}/extensions/imposed-viewing`]:
                    type === "compulsory",
            },
        },
    };
};

export const makeActivityVideoTutorialStatementParts = (
    linkUrl: string,
    activityId: string
): Partial<Statement> => {
    return {
        object: {
            objectType: "Activity",
            id: linkUrl,
            definition: {
                type: `${XAPI_REGISTRY}/activities/activity-video-tutorial`,
            },
        },
        context: {
            extensions: {
                [`${XAPI_REGISTRY}/extensions/activity`]: {
                    id: activityId,
                },
            },
        },
    };
};

export const makeAdaptiveTestDiagnosisStatementParts = (
    moduleId: string,
    diagnosis: any
): Partial<Statement> => {
    return {
        verb: VERBS.completed,
        object: {
            objectType: "Activity",
            id: `${XAPI_REGISTRY}/module/${moduleId}`,
            definition: {
                type: `${XAPI_REGISTRY}/activities/adaptive-test`,
            },
        },
        result: {
            completion: true,
            extensions: {
                [`${XAPI_REGISTRY}/extensions/adaptive-test-diagnosis`]:
                    diagnosis,
            },
        },
    };
};

export const makeWorkmodeStatementParts = (
    workmode: WorkmodesWithStatements
): Partial<Statement> => ({
    context: {
        contextActivities: {
            category: [{ id: WORKMODES_CATEGORIES[workmode] }],
        },
    },
});

export const makeEntryLearningSetStatementParts = (
    learning_set_id: string
): Partial<Statement> => ({
    context: {
        contextActivities: {
            parent: [
                {
                    objectType: "Activity",
                    id: `${XAPI_REGISTRY}/learning-set/${learning_set_id}`,
                },
            ],
        },
    },
});
