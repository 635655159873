import {
    UseQueryResult,
    useQueries,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import queryKeys from "./queryKeys";
import { useContext } from "react";
import { sessionStore } from "../../../../contexts/SessionContext";
import usePlaylistAPIClient from "./usePlaylistAPIClient";
import {
    Assignment,
    PlaylistItem,
    Playlist,
    PlaylistProgress,
    PlaylistAnswer,
} from "../type";
import { useUserId } from "../../../../hooks/useUserInfo";
import { AxiosError } from "axios";
import { isAxiosErrorStatus } from "../../../../utils/api-client-utils";

export const usePlaylistQuery = (
    playlistId: string,
    options?: { enabled?: boolean }
) => {
    const {
        session: { userId },
    } = useContext(sessionStore);
    const { getPlaylist } = usePlaylistAPIClient();
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: queryKeys.getPlaylist(playlistId, { author_id: userId }),
        queryFn: getPlaylist,
        initialData: queryClient
            .getQueryData<Playlist<PlaylistItem>[]>(
                queryKeys.getPlaylists({ author_id: userId })
            )
            ?.find((playlist) => playlist.learning_set_id === playlistId),
        staleTime: Infinity,
        retry: (failureCount: number, error: AxiosError) =>
            failureCount < 3 && !isAxiosErrorStatus(error, 404),
        ...options,
    });
};

export const useAllPlaylistsQuery = () => {
    const author_id = useUserId();
    const { getPlaylists } = usePlaylistAPIClient();

    return useQuery({
        queryKey: queryKeys.getPlaylists({ author_id }),
        queryFn: getPlaylists,
        staleTime: Infinity,
    });
};

export const usePlaylistAssignmentsQuery = (
    playlistId: string,
    options?: { enabled?: boolean }
) => {
    const teacher = useUserId();
    const { getAssignments } = usePlaylistAPIClient();
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: queryKeys.getAssignments({ resource: playlistId }),
        queryFn: getAssignments,
        staleTime: Infinity,
        initialData: () =>
            queryClient
                .getQueryData<Assignment[]>(
                    queryKeys.getAssignments({ teacher })
                )
                ?.filter(
                    (assignment) =>
                        assignment.assigned_resource?.resource_id === playlistId
                ),
        ...options,
    });
};

const ignoredList: string[] = [];

export const usePlaylistsQueries = (
    playlists: { author_id: string; id: string }[] | undefined,
    options?: { enabled?: boolean }
) => {
    const { getPlaylist } = usePlaylistAPIClient();
    return useQueries({
        queries:
            playlists
                ?.filter((p) => !ignoredList.includes(p.id))
                .map(({ id, author_id }) => ({
                    queryKey: queryKeys.getPlaylist(id, {
                        author_id: author_id,
                    }),
                    queryFn: getPlaylist,
                    staleTime: 3 * 60 * 1000,
                    retry: (failureCount: number, error: AxiosError) => {
                        return (
                            failureCount < 3 && !isAxiosErrorStatus(error, 404)
                        );
                    },
                    onError: (error: AxiosError) => {
                        /**
                         * If an error occured we add the playlist id in the ignored list
                         * to avoid an infinite retry loop
                         */
                        if (isAxiosErrorStatus(error, 404))
                            ignoredList.push(id);
                    },
                    ...options,
                })) ?? [],
    });
};

export const useProgressQuery = () => {
    const userId = useUserId();
    const { getPlaylistProgress } = usePlaylistAPIClient();
    return useQuery({
        queryKey: queryKeys.getPlaylistProgress({ teacher_id: userId }),
        queryFn: getPlaylistProgress,
        staleTime: 2 * 60 * 10000,
    });
};

export const usePlaylistProgressQuery = (playlistId: string) => {
    const { data, ...progressQuery } = useProgressQuery();
    const filtered: Omit<
        UseQueryResult<PlaylistProgress | undefined>,
        "refetch"
    > = {
        ...progressQuery,
        data: data?.find((playlist) => playlist.id === playlistId),
    };
    return filtered;
};

export const useGetPlaylistAnswers = (
    playlistId: string,
    studentId: string
) => {
    const { getPlaylistAnswers } = usePlaylistAPIClient();
    const { data, ..._ } = useQuery({
        queryKey: queryKeys.getPlaylistAnswers({
            student_id: studentId,
            playlist_id: playlistId,
        }),
        queryFn: getPlaylistAnswers,
        staleTime: 2 * 60 * 10000,
    });
    const results: PlaylistAnswer[] = [];
    if (!data) return results;
    for (const exerciseId in data?.student_answers) {
        const answer = data.student_answers[exerciseId][0];
        results.push({
            exerciseId: exerciseId,
            result: {
                correct: answer.result.success,
                score: answer.result.score.raw,
                answer: answer.result.answer,
            },
            timestamp: answer.timestamp,
        });
    }
    return results;
};
