import { createContext } from "react";
import { QueryClient, useQueryClient } from "@tanstack/react-query";

/**
 * We must use our own context and not the default react-query to share
 * the same queryClient between evb_application and gameplays.
 */
export const QueryClientContext = createContext<QueryClient | undefined>(
    undefined
);

export const useAthenaQueryClient = () => {
    const context = useQueryClient({ context: QueryClientContext });
    return context;
};
