import React from "react";
import TriStateCheckbox, {
    Props as TriStateCheckboxProps,
} from "../../../design-system-components/TriStateCheckbox/TriStateCheckbox";

import { VisuallyHidden } from "@evidenceb/athena-common/ui/VisuallyHidden";
import { HierarchyPath, TreeNode } from "../types";
import { getNodeCheckboxId } from "../utils";
import { MessageDescriptor, useIntl } from "react-intl";

type Props = {
    hierarchyPath: HierarchyPath;
    label: string;
    checked: boolean | "mixed";
    childLeaves: (TreeNode & { hierarchyPath: HierarchyPath })[];
    onCheckNode: (hierarchyPath: HierarchyPath) => void;
    selectBranchLabel: (state: boolean | "mixed") => MessageDescriptor;
    variant?: TriStateCheckboxProps["variant"];
};

const BranchCheckbox = ({
    hierarchyPath,
    label,
    checked,
    childLeaves,
    onCheckNode,
    selectBranchLabel,
    variant,
}: Props) => {
    const intl = useIntl();

    return (
        <TriStateCheckbox
            className="tree-checkbox-list__title-checkbox"
            checked={checked}
            aria-controls={childLeaves
                .map((item) => getNodeCheckboxId(item.hierarchyPath))
                .join(" ")}
            onChange={() => onCheckNode(hierarchyPath)}
            variant={variant}
        >
            <VisuallyHidden>
                {intl.formatMessage(selectBranchLabel(checked), {
                    branch: label,
                })}
            </VisuallyHidden>
        </TriStateCheckbox>
    );
};
export default BranchCheckbox;
