import React from "react";
import { useBreakpoints } from "@evidenceb/athena-common/helpers/breakpoints";
import { WithIntrinsicAttributes } from "./withIntrinsicAttributes.type";

export const withBreakpoint =
    <T extends object | undefined>(
        Component: (props: WithIntrinsicAttributes<T>) => React.JSX.Element,
        breakpoint: keyof ReturnType<typeof useBreakpoints>
    ) =>
    (props: WithIntrinsicAttributes<T>) => {
        const breakpoints = useBreakpoints();
        if (breakpoints[breakpoint]) return <Component {...props} />;
        return null;
    };
