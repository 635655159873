import { useCallback, useContext } from "react";
import { redirectAfterLogout } from "../auth";
import { IntlShape, defineMessages, useIntl } from "react-intl";
import { errorStore } from "../../contexts/ErrorContext";
import { configStore } from "../../contexts/ConfigContext";
import { sessionStore } from "../../contexts/SessionContext";
import { IErrorModal } from "../../interfaces/Error";

const useSetInvalidatedTokenErrorModal = () => {
    const intl = useIntl();
    const { setErrorInfo } = useContext(errorStore);
    const { config } = useContext(configStore);
    const {
        session: { appProvider },
    } = useContext(sessionStore);

    return useCallback(
        (opts?: { preventImmediateDisplay?: boolean }) => {
            setErrorInfo((curr) => {
                if (curr.page || curr.modal) return curr;
                return {
                    displayModal: opts?.preventImmediateDisplay ? false : true,
                    modal: {
                        type: "POPUP",
                        content: tokenErrorModalContent(intl),
                        onClick: () => {
                            redirectAfterLogout(config, appProvider);
                        },
                    },
                };
            });
        },
        [setErrorInfo, intl, config, appProvider]
    );
};
export default useSetInvalidatedTokenErrorModal;

const tokenErrorModalContent = (intl: IntlShape): IErrorModal["content"] => ({
    title: intl.formatMessage(messages.expiredSessionErrorTitle),
    text: intl.formatMessage(messages.expiredSessionErrorContent),
    btn: {
        label: intl.formatMessage(messages.relog),
    },
});

const messages = defineMessages({
    expiredSessionErrorTitle: {
        id: "error-sessionExpiredModal-title",
        defaultMessage: "Votre session a expiré",
    },
    expiredSessionErrorContent: {
        id: "error-sessionExpiredModal-content",
        defaultMessage:
            "Vous devez vous connecter à nouveau pour pouvoir continuer à utiliser l'application",
    },
    relog: {
        id: "error-sessionExpiredModal-relog",
        defaultMessage: "Me reconnecter",
    },
});
