import { axiosClient } from "../../../utils/axios-client/axios-client";
import * as localStorage from "../../../utils/localStorage";
import { joinPath } from "../../../utils/path";

export const retrieveLearningAssetUrls = async (
    learningSetId: string,
    baseAssetsUrl: string,
    version: string,
    endpoint: string
): Promise<Set<string>> => {
    try {
        const learningSetDataResponse = await fetchLearningSetData(
            learningSetId,
            endpoint
        );
        const learningItemDataArray = findLearningItemData(
            learningSetDataResponse
        );
        return learningItemDataArray.reduce<Set<string>>(
            (urls, currentLearningItemData) => {
                const stringValues = getAssetsStringValues(
                    currentLearningItemData
                );
                const currentDataAssets = createAssetUrlList(
                    [...stringValues],
                    baseAssetsUrl,
                    version
                );
                return new Set([...urls, ...currentDataAssets]);
            },
            new Set<string>()
        );
    } catch (error) {
        console.error("Error fetching data: ", error);
        throw error;
    }
};

export const fetchLearningSetData = async (
    learningSetId: string,
    endpoint: string
) => {
    const learningSetsEndpoint = `${endpoint}/learning_sets/${learningSetId}`;

    const result = await axiosClient.axios.get(learningSetsEndpoint, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem<string>(
                localStorage.Key.TOKEN
            )}`,
            "x-evidenceb-app": "athena",
        },
    });

    return result.data;
};
/**
 * Use node server on port 5000 to send data from src/mock files. Mock server
 * can be found somewhere probably.
 * Psst it's here: https://evidencebprod.atlassian.net/wiki/spaces/ExausTeam/pages/713392202/Mock+server
 */
// const _mockFetchLearningSetData = async () => {
//     // Use /mockedNestedLearningSetsData endpoint with src/mock/mockedNestedLearningSetsData.json
//     // Use /mockedPlaylistWithAssets endpoint with src/mock/mockedPlaylistWithAssets.json
//     const mockedEndpoint = `/mockedNestedLearningSetsData`;
//     // const mockedEndpoint = `/mockedPlaylistWithAssets`;

//     const result = await axiosClient.axios.get(mockedEndpoint, {
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${localStorage.getItem<string>(
//                 localStorage.Key.TOKEN
//             )}`,
//             "x-evidenceb-app": "athena",
//         },
//     });

//     return result.data;
// };

const findLearningItemData = (object: any, itemData: any[] = []): any[] => {
    if (object && typeof object === "object" && "learning_item_data" in object)
        itemData.push(object.learning_item_data);
    else if (Array.isArray(object))
        object.forEach((item) => findLearningItemData(item, itemData));
    else if (typeof object === "object" && object !== null)
        findLearningItemData(Object.values(object), itemData);
    return itemData;
};

function getAssetsStringValues(
    object: any,
    resultSet: Set<string> = new Set(),
    /**
     * [TEMP] See
     * https://teams.microsoft.com/l/message/19:a56f06ff37b34b418d517afddf8f0031@thread.tacv2/1709130822138?tenantId=afad3be2-b0d8-4512-acf4-2cc0111e2132&groupId=d1981dd8-46b7-4c9b-a9b1-3ed55cd84a36&parentMessageId=1709130822138&teamName=%F0%9F%99%8C%F0%9F%87%A7%F0%9F%87%B7%20Goget%C3%A3o%20%F0%9F%A6%81%F0%9F%92%A1&channelName=Modes%20D%C3%A9connect%C3%A9%20-%20offline&createdTime=1709130822138
     * Currently the frontend is in charge of determining whether a file is
     * located in the /tss or /content folder. This causes a pb because some
     * audio files are not in /tts. The current strategy, waiting for the
     * backend to give the complete path to the files, is to look at whether the
     * object containing the string for the audio has a isTts key
     */
    isTts: boolean = false
): Set<string> {
    if (
        object &&
        typeof object === "string" &&
        (isAudio(object) || isImage(object))
    ) {
        resultSet.add(prepareAssetPath(object, isTts));
    } else if (Array.isArray(object))
        object.forEach((jsonValue) => {
            getAssetsStringValues(jsonValue, resultSet);
        });
    else if (object && typeof object === "object") {
        Object.values(object).forEach((value) => {
            getAssetsStringValues(
                value,
                resultSet,
                "isTts" in object && !!object.isTts
            );
        });
    }
    return resultSet;
}

const createAssetUrlList = (
    values: string[],
    baseAssetsUrl: string,
    version: string
): Set<string> => {
    return new Set(
        values.map((stringValue) =>
            prepareAssetUrl(stringValue, baseAssetsUrl, version)
        )
    );
};
const isImage = (string: string) =>
    string.endsWith(".png") || string.endsWith(".svg");
const isAudio = (string: string) =>
    string.endsWith(".mp3") || string.endsWith(".wav");

const prepareAssetUrl = (
    assetUrl: string,
    baseAssetsUrl: string,
    version: string
): string => {
    const versionParam = `?version=${version}`;
    if (assetUrl.startsWith("http")) {
        return assetUrl + versionParam;
    } else {
        return joinPath(baseAssetsUrl, assetUrl) + versionParam;
    }
};

const prepareAssetPath = (asset: string, isTts: boolean): string => {
    if (isAudio(asset) && isTts) {
        return joinPath("tts", asset);
    } else {
        return joinPath("content", asset);
    }
};
