import React from "react";
import cn from "classnames";
import { Item, useMenuTriggerState } from "react-stately";
import { AriaMenuOptions, useMenuTrigger } from "@react-aria/menu";
import Popover, { Props as PopoverProps } from "../Popover/Popover";
import { Placement } from "@react-types/overlays";
import Menu from "./Components/Menu";

import "./PopoverMenu.scss";

export type Props = {
    className?: string;
    /**
     * Button that opens the popover
     */
    trigger: React.JSX.Element;
    popoverPlacement: Placement;
    /**
     * Callback triggered when a menu item is clicked.
     */
    onAction?: AriaMenuOptions<any>["onAction"];
    /**
     * The container element in which the popover overlay portal will be placed.
     * To be used in particular when popover are placed in Modal components (the
     * element should be the Modal)
     * @default document.body
     */
    portalContainer?:
        | PopoverProps["portalContainer"]
        | (() => PopoverProps["portalContainer"]);
    children: any;
};

const PopoverMenu = ({
    className,
    trigger,
    popoverPlacement,
    children,
    portalContainer,
    ...props
}: Props) => {
    const state = useMenuTriggerState({});

    const ref = React.useRef(null);
    const { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref);

    return (
        <>
            {React.cloneElement(trigger, {
                ref,
                ...menuTriggerProps,
                className: cn("popover-menu__trigger", trigger.props.className),
            })}

            {state.isOpen && (
                <Popover
                    state={state}
                    triggerRef={ref}
                    placement={popoverPlacement}
                    portalContainer={
                        typeof portalContainer === "function"
                            ? portalContainer()
                            : portalContainer
                    }
                >
                    <Menu
                        {...props}
                        {...menuProps}
                        className={cn("popover-menu__menu", className)}
                    >
                        {children}
                    </Menu>
                </Popover>
            )}
        </>
    );
};

PopoverMenu.Item = Item;

export default PopoverMenu;
