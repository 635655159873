export const getPlatformDetails = () => {
    let nAgt = navigator.userAgent;
    let browserName: string = "Undefined";
    let fullVersion: string = "0";
    let majorVersion = 0;
    let nameOffset, verOffset, ix;

    if ((verOffset = nAgt.indexOf("OPR")) != -1) {
        // In Opera, the true version is after "OPR" or after "Version"
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 4);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
    } else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
        // In MS Edge, the true version is after "Edg" in userAgent
        browserName = "Microsoft Edge";
        fullVersion = nAgt.substring(verOffset + 4);
    } else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        // In MSIE, the true version is after "MSIE" in userAgent
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
        // In Chrome, the true version is after "Chrome"
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
        // In Safari, the true version is after "Safari" or after "Version"
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
    } else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
        // In Firefox, the true version is after "Firefox"
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
    } else if (
        // In most other browsers, "name/version" is at the end of userAgent
        (nameOffset = nAgt.lastIndexOf(" ") + 1) <
        (verOffset = nAgt.lastIndexOf("/"))
    ) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }

    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
        fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
        fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = "" + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    let OSName: string = "Unknown OS";
    if (navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.userAgent.indexOf("Mac") != -1) OSName = "MacOS";
    if (navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    const isTouch = isTouchDevice();

    return {
        browserName,
        fullVersion,
        OSName,
        isTouchDevice: isTouch,
    };
};

export const isTouchDevice = (): boolean =>
    "ontouchstart" in window || navigator.maxTouchPoints > 0;
