import React, { useContext } from "react";
import { sessionStore } from "../../contexts/SessionContext";
import { useAthenaEventStream } from "../../contexts/EventStreamContext";
import PlaySingleExercise from "./PlaySingleExercise/PlaySingleExercise";
import { featureFlagsStore } from "@evidenceb/athena-common/modules/FeatureFlags";
import * as localStorage from "./../../utils/localStorage";
import { isProductionEnvironment } from "../../utils/env";
import { Subscriber } from "@evidenceb/athena-events-tools";
import { logUserEventsSummary as _logUserEventsSummary } from "./logUserEventsSummary";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";

/**
 * This components adds development functionalities to the specimen.
 */
const Dev = () => {
    const { setFeatureFlags } = useContext(featureFlagsStore);

    const { data } = useContext(dataStore);
    const { session, setSession } = useContext(sessionStore);
    const { config } = useContext(configStore);
    const athenaEventStream = useAthenaEventStream();
    /*
     * Log Exercise details feature
     */
    const enableLogExerciseDetails = () => {
        setSession((curr) => {
            return {
                ...curr,
                flags: {
                    ...curr.flags,
                    logExerciseDetails: true,
                },
            };
        });
    };

    const logEventStream = () => {
        const eventStreamLogger: Subscriber = {
            id: "eventStreamLogger",
            async processAthenaEvent(athenaEvent: any) {
                console.log("Received AthenaEvent:", athenaEvent);
            },
        };
        athenaEventStream!.registerSubscriber(eventStreamLogger);
    };

    const stopEventStreamLog = () => {
        athenaEventStream!.unregisterSubscriber("eventStreamLogger");
    };

    const attachEventStreamToWindow = () => {
        (window as any).eventStream = athenaEventStream;
    };

    const detachEventStreamToWindow = () => {
        (window as any).eventStream = null;
    };

    const logUserEventsSummary = (
        filters: Parameters<typeof _logUserEventsSummary>[1]
    ) => _logUserEventsSummary(athenaEventStream!, filters);

    const logAppData = (type: "data" | "session" | "config") => {
        switch (type) {
            case "data":
                console.log("data", data);
                break;
            case "session":
                console.log("session", session);
                break;
            case "config":
                console.log("config", config);
                break;
            default:
                console.log("Error: Specify a type");
        }
    };

    (window as any).DEBUG_UTILS = {
        logAppData,
        enableLogExerciseDetails,
        logEventStream,
        stopEventStreamLog,
        attachEventStreamToWindow,
        detachEventStreamToWindow,
        logUserEventsSummary,
    };

    /*
     * toggle a feature flag
     */
    const toggleFeature = (featureName: string, toggle: boolean) => {
        const storageFlags = localStorage.getItem<string[]>(
            localStorage.Key.DEV_FLAGS
        );
        if (!storageFlags && toggle)
            localStorage.setItem(localStorage.Key.DEV_FLAGS, [featureName]);
        else if (storageFlags && storageFlags.includes(featureName) && !toggle)
            localStorage.setItem(
                localStorage.Key.DEV_FLAGS,
                storageFlags.filter((item) => item !== featureName)
            );
        else if (storageFlags && !storageFlags.includes(featureName) && toggle)
            localStorage.setItem(localStorage.Key.DEV_FLAGS, [
                ...storageFlags,
                featureName,
            ]);

        setFeatureFlags((flags) => {
            return {
                ...flags,
                [featureName]: toggle,
            };
        });
    };
    if (!isProductionEnvironment(window.location.hostname)) {
        (window as any).DEBUG_UTILS.toggleFeature = toggleFeature;
    }

    return (
        <>
            <PlaySingleExercise />
        </>
    );
};

export default Dev;
