import React, { CSSProperties } from "react";
// SCSS
import "./Card.scss";
import cn from "classnames";
// Interfaces
import { CardProps, ClickAction } from "./types";
import ClickableCard from "./ClickableCard";

const Card = ({
    cardTitle,
    children,
    className,
    overrideDSClassname = false,
    contentClassName,
    colors,
    clickAction,
    style,
    ...props
}: CardProps) => {
    const isClickable = clickAction && !clickAction.disabled;
    return (
        <>
            {!isClickable && (
                <div
                    className={cn(
                        { "ds-card": !overrideDSClassname },
                        className
                    )}
                    style={
                        {
                            ...style,
                            "--custom-background-color": colors?.background,
                        } as CSSProperties
                    }
                    {...props}
                >
                    {cardTitle && (
                        <cardTitle.as className="ds-card__card-title">
                            {cardTitle.content}
                        </cardTitle.as>
                    )}
                    <div
                        className={cn(
                            "ds-card__card-content",
                            contentClassName
                        )}
                    >
                        {children}
                    </div>
                </div>
            )}
            {isClickable && (
                <Wrapper
                    className={className}
                    clickAction={clickAction}
                    contentClassName={contentClassName}
                    style={
                        {
                            ...style,
                            "--hover-border-color": colors?.borderHover,
                            "--custom-background-color": colors?.background,
                        } as CSSProperties
                    }
                >
                    {cardTitle && (
                        <cardTitle.as className="ds-card__card-title">
                            {cardTitle.content}
                        </cardTitle.as>
                    )}
                    {children}
                </Wrapper>
            )}
        </>
    );
};

export default Card;

export type WrapperProps = {
    clickAction?: ClickAction;
} & CardProps;
export const Wrapper = ({ clickAction, ...props }: WrapperProps) => {
    if (clickAction)
        return (
            <ClickableCard
                clickAction={clickAction}
                {...props}
                colors={undefined}
            />
        );
    return <Card {...props} />;
};
