import * as localStorage from "./localStorage";

export const getLocalStorageFlags = (): { [key: string]: boolean } => {
    const flagsArray = localStorage.getItem<string[]>(
        localStorage.Key.DEV_FLAGS
    );
    return (
        flagsArray?.reduce(
            (flags, storageFlag) => ({
                ...flags,
                [storageFlag]: true,
            }),
            {}
        ) ?? {}
    );
};
