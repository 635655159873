import { useContext, useMemo } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { sessionStore } from "../../contexts/SessionContext";
import {
    NotificationAggregate,
    RawNotification,
    NotificationStatus,
    ResourceType,
    RawNotificationType,
    NotificationType,
    PadsNotifComplement,
} from "./Types";
import { DuoSession, UserType } from "../../interfaces/User";
import {
    Playlist,
    PlaylistItem,
    PlaylistStatus,
} from "../../pages/Workmodes/Playlist/type";
import { Group, Student } from "../../interfaces/Session";
import { useNotificationsQuery } from "./api/useQueries";
import { usePlaylistsQueries } from "../../pages/Workmodes/Playlist/api/useQueries";
import { useTeacherId } from "../../hooks/useUserInfo";
import { isIdle as isQueryIdle } from "../../hooks/queries/utils";
import { filterDefined, getKey } from "../../utils/array-manipulations";
import { useDuosQueries } from "../../pages/Workmodes/Duo/api/useQueries";
import { Module } from "@evidenceb/gameplay-interfaces";
import { not } from "../../utils/arrayMethods";
import { getPadTitle } from "../../pages/Workmodes/Workshop/utils";
import { PadAssignment } from "../../pages/Workmodes/Workshop/types";

const useNotifications = () => {
    const {
        session: { userId, userType, classrooms, lastname, firstname, extra },
    } = useContext(sessionStore);
    const teacherId = useTeacherId();

    const {
        data: rawNotifications,
        isSuccess,
        isLoading,
    } = useNotificationsQuery(userId, userType);

    const playlists = useMemo(
        () =>
            rawNotifications
                ?.filter(
                    (r) =>
                        r.notified_content.resource_type ===
                        ResourceType.PLAYLIST
                )
                .map((notif) => ({
                    author_id: teacherId!,
                    id: notif.notified_content.resource_id,
                })),
        [rawNotifications, teacherId]
    );

    const workshopsAssignments = useMemo(
        () =>
            rawNotifications
                ?.filter(
                    (r) =>
                        r.notified_content.resource_type ===
                        ResourceType.WORKSHOP
                )
                .map((notif) => ({
                    author_id: teacherId!,
                    id: notif.notified_content.resource_id,
                    extra: notif.notified_content
                        .extra as PadAssignment["extra"],
                })),
        [rawNotifications, teacherId]
    );

    const playlistsResult = usePlaylistsQueries(playlists, {
        enabled: !!rawNotifications && !!teacherId,
    });

    const duos = useMemo(() => {
        const $duos = rawNotifications
            ?.filter((r) => {
                return r.notified_content.resource_type === ResourceType.DUO;
            })
            .map((notif) => notif.notified_content.resource_id);
        return $duos;
    }, [rawNotifications]);

    const duosResult = useDuosQueries(duos, {
        enabled: !!rawNotifications,
    });

    const notifications = useMemo((): NotificationAggregate[] | undefined => {
        if (
            !rawNotifications ||
            playlistsResult.some(not(isQueryIdle)) ||
            duosResult.some(not(isQueryIdle))
        )
            return;
        const studentInfo =
            userType === UserType.Student
                ? {
                      firstname,
                      lastname,
                      extra,
                  }
                : undefined;
        const notifications = aggregatedNotifications(
            rawNotifications,
            classrooms,
            studentInfo,
            playlistsResult.map(getKey("data")).filter(filterDefined),
            duosResult.map(getKey("data")).filter(filterDefined)
        );
        return notifications;
    }, [
        rawNotifications,
        playlistsResult,
        duosResult,
        userType,
        firstname,
        lastname,
        classrooms,
        extra,
    ]);

    return {
        isLoading,
        isSuccess,
        notifications,
        playlistsResult,
        duosResult,
        workshopsAssignments,
    };
};

export default useNotifications;

export const insertResourceNames = (
    notifications: NotificationAggregate[],
    playlists: UseQueryResult<Playlist<PlaylistItem>, unknown>[],
    duos: UseQueryResult<DuoSession, unknown>[],
    pads: PadsNotifComplement[] | undefined,
    modules: Module[]
) => {
    const $notifications = [...notifications];
    if (playlists.length) {
        $notifications.forEach((notifications) => {
            const currentResource = playlists.find(
                (p) => p.data?.learning_set_id === notifications.id
            );
            if (currentResource) {
                notifications.content.resource = {
                    ...notifications.content.resource!,
                    name: currentResource.data?.name,
                };
            }
        });
    }

    if (duos.length) {
        $notifications.forEach((notifications) => {
            const currentResource = duos.find(
                (d) => d.data?.id === notifications.id
            );
            if (currentResource) {
                const moduleName = modules.find(
                    (m) => m.id === currentResource.data?.config.module_id
                )?.title.short;
                notifications.content.resource = {
                    ...notifications.content.resource!,
                    name: moduleName ?? "",
                };
            }
        });
    }

    let padNotifications: NotificationAggregate[] = [];
    if (pads?.length) {
        $notifications.forEach((notif) => {
            const complement = pads.find((pad) => pad.id === notif.id);
            if (complement && notif) {
                const newContent = {
                    ...notif.content,
                    resource: {
                        ...notif.content.resource!,
                        name: getPadTitle(notif.id),
                        ...complement,
                    },
                };
                padNotifications.push({
                    ...notif,
                    content: newContent,
                });
            }
        });
    }

    const filteredPlaylists = $notifications.filter(
        (notification) =>
            // Remove errored playlists notifications:
            // there is at least one playlist that is loading(and maybe is the one of the notification)
            // or is the one of the notification (and so is loaded)
            !playlists.every(
                (playlist) =>
                    !playlist.isLoading &&
                    playlist.data?.learning_set_id !== notification.id
            )
    );

    const filteredDuos = $notifications.filter(
        (notification) =>
            // Remove errored duos notifications
            !duos.every(
                (duo) => !duo.isLoading && duo.data?.id !== notification.id
            )
    );

    return filteredPlaylists
        .concat(filteredDuos)
        .concat(padNotifications)
        .sort(
            (a, b) =>
                new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime()
        );
};

type StudentInfo = Omit<Student, "id">;

const aggregatedNotifications = (
    rawNotifications: RawNotification[],
    classrooms: Group[] | undefined,
    student: StudentInfo | undefined,
    playlists: Playlist<PlaylistItem>[],
    duos: DuoSession[]
): NotificationAggregate[] | undefined => {
    if (!student && !classrooms) return;
    const notificationsAggregate: NotificationAggregate[] = [];
    rawNotifications?.forEach((rawNotification: RawNotification) => {
        const isPlaylist =
            rawNotification.notified_content.resource_type ===
            ResourceType.PLAYLIST;
        const isDuo =
            rawNotification.notified_content.resource_type === ResourceType.DUO;
        const isWorkshop =
            rawNotification.notified_content.resource_type ===
            ResourceType.WORKSHOP;
        const isAssignment = isPlaylist || isWorkshop;
        // Ignore archived playlists
        if (isPlaylist) {
            const playlist = playlists.find(
                ({ learning_set_id }) =>
                    learning_set_id ===
                    rawNotification.notified_content.resource_id
            );
            if (!playlist || playlist.status.status === PlaylistStatus.ARCHIVED)
                return;
        }

        /* 
            if "student" param is provided it means we are on the student side
            so NotificationAggregate["students"] will only have this student in it
            since all notifications are about this student
        */
        let currentStudent: StudentInfo | undefined = student;

        /* 
            if "student" params is not provided, we are on the teacher side
            so we need to find the specific student each time and add it to NotificationAggregate["students"]
        */
        if (!currentStudent && isPlaylist) {
            const students = classrooms!
                .map((classroom) => classroom.students)
                .flat();
            const $student: Student | undefined = students?.find(
                (s) => s.id === rawNotification.notified_content.student_id
            );
            currentStudent = $student && {
                firstname: $student.firstname,
                lastname: $student.lastname,
                extra: $student.extra,
            };
        }

        /* 
            Search if the notificaton aggregate already exists by looking for its index based on the resource id
            note: see NotificationAggregate TS type for the reason why
        */
        const notificationIndex = notificationsAggregate.findIndex(
            (n) => n.id === rawNotification.notified_content.resource_id
        );

        // if the notificationAggregate already exists
        if (notificationIndex !== -1) {
            // We update the students attached to this notification aggregate
            const users = notificationsAggregate[notificationIndex].students;
            if (currentStudent) {
                users.push({
                    notificationId: rawNotification.id,
                    firstname: currentStudent.firstname,
                    lastname: currentStudent.lastname,
                    extra: currentStudent.extra,
                });
                notificationsAggregate[notificationIndex].students = users.sort(
                    (a, b) => a.notificationId.localeCompare(b.notificationId)
                );
                /* 
                    We set the status. The rule is: if any of the rawNotifications has the CREATED (aka unread) status, 
                    it has priority over READ for the aggregate
                */
                notificationsAggregate[notificationIndex].status =
                    rawNotification.status === NotificationStatus.CREATED
                        ? NotificationStatus.CREATED
                        : notificationsAggregate[notificationIndex].status;
            }
        } else {
            // if not
            if (currentStudent && isAssignment) {
                const resourceType = getType(
                    rawNotification.notified_content.type
                );
                if (!resourceType) return;
                // we create a new NotificationAggregate object and push it into the array
                const newNotificationAggregate: NotificationAggregate = {
                    id: rawNotification.notified_content.resource_id,
                    content: {
                        type: resourceType,
                        resource: {
                            type: rawNotification.notified_content
                                .resource_type,
                        },
                    },
                    timestamp: rawNotification.created_at,
                    status: rawNotification.status,
                    students: [
                        {
                            notificationId: rawNotification.id,
                            firstname: currentStudent.firstname,
                            lastname: currentStudent.lastname,
                            extra: currentStudent.extra,
                        },
                    ],
                };
                notificationsAggregate.push(newNotificationAggregate);
            } else if (isDuo) {
                const resourceType = getType(
                    rawNotification.notified_content.type
                );
                if (!resourceType) return;
                const duo = duos.find(
                    (d) => d.id === rawNotification.notified_content.resource_id
                );
                if (duo) {
                    const anewNotificationAggregate: NotificationAggregate = {
                        id: rawNotification.notified_content.resource_id,
                        content: {
                            type: resourceType,
                            resource: {
                                type: rawNotification.notified_content
                                    .resource_type,
                                extra: {
                                    moduleId: duo.config.module_id,
                                },
                            },
                        },
                        timestamp: rawNotification.created_at,
                        status: rawNotification.status,
                        students: [
                            {
                                notificationId: rawNotification.id,
                                firstname: duo.mentee.first_name,
                                lastname: duo.mentee.last_name,
                            },
                            {
                                notificationId: rawNotification.id,
                                firstname: duo.mentor.first_name,
                                lastname: duo.mentor.last_name,
                            },
                        ],
                    };
                    notificationsAggregate.push(anewNotificationAggregate);
                }
            }
        }
    });
    return notificationsAggregate;
};

const getType = (rawType: RawNotificationType) => {
    switch (rawType) {
        case RawNotificationType.RESOURCE_ASSIGNED:
        case RawNotificationType.MULTIPLAYER_SESSION_CREATED:
            return NotificationType.ASSIGNED;
        case RawNotificationType.ASSIGNMENT_COMPLETED:
        case RawNotificationType.MULTIPLAYER_SESSION_DONE:
            return NotificationType.DONE;
        default:
            return undefined;
    }
};
