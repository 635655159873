import React, { useState } from "react";
import {
    Control,
    SegmentedControl,
} from "../../design-system-components/SegmentedControl/SegmentedControl";
import { useActiveSubject, useSubjects } from "../../hooks/useActiveSubject";
import { commonMessages } from "../../utils/messages";
import { useSubjectChoice } from "../../pages/SubjectChoice/ChoiceSteps/api/useSubjectChoice";
import Case from "case";

export type SubjectSwitchProps = {
    onSwitch?: () => void;
    className?: string;
};

export const SubjectSwitch = ({ onSwitch, className }: SubjectSwitchProps) => {
    const subjects = useSubjects();
    const activeSubject = useActiveSubject();
    const [currentSubject, setCurrentSubject] = useState(activeSubject);

    const [_, { mutateAsync }] = useSubjectChoice();

    return (
        <>
            {subjects.length > 1 && (
                <SegmentedControl
                    defaultValue={currentSubject.name}
                    onChange={async (value: string) => {
                        const newSubject = subjects.find(
                            (subject) => subject.name === value
                        );
                        muteExercisesLinks();
                        setCurrentSubject(newSubject!);
                        await mutateAsync(newSubject!.id);
                        if (onSwitch) onSwitch();
                    }}
                    aria-label={commonMessages.subject}
                    color={currentSubject.colors.default}
                    className={className}
                >
                    {subjects.map((subject) => (
                        <Control value={subject.name} key={subject.name}>
                            {Case.pascal(subject.name)}
                        </Control>
                    ))}
                </SegmentedControl>
            )}
        </>
    );
};

const muteExercisesLinks = () => {
    /*
    This function aims at solving the problem caused by the fact that the switch of
    subject takes some time.
    Without muting links, if a user starts the subject switch and then starts an exercise
    or workshop, once the subject switches completes, ActiveSubject will be incoherent
    with the current exercise, destroying the position data in the player header
    
    => solution implemented here: mute all links contained in resource cards and workshop
    cards, this should prevent users from having a wrong activeSubject
    */
    const links: any = document.querySelectorAll(
        ".ds-resource-card a, .work__resources-list-workshop-card a"
    );
    links.forEach((link: any) => {
        link.parentElement.classList.remove("--clickable");
        link.remove();
    });
};
