import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { sessionStore } from "../../contexts/SessionContext";
import { useUserExtra } from "../../hooks/queries/useUserExtra";
import { useUserType } from "../../hooks/useUserInfo";
import { UserType } from "../../interfaces/User";

import { useBreakpoints } from "@evidenceb/athena-common/helpers/breakpoints";
import { VisuallyHidden } from "@evidenceb/athena-common/ui/VisuallyHidden";
import Avatar, { OwnAvatar } from "../../components/Avatar/Avatar";
import { useAvatarCharacter } from "./utils";
import { accountMessages } from "./accountMessages";
import { fullName } from "../../utils/format";
import useAssetsDetails from "../../hooks/useAssetsDetails";
import { configStore } from "../../contexts/ConfigContext";
import { Image } from "@evidenceb/athena-common/modules/Assets";
import RadioGroup from "../../components/RadioGroup/RadioGroup";
import pick from "lodash/pick";
import cn from "classnames";

import "./Account.scss";
import useOnlineStatus from "../../hooks/useOnlineStatus";

export default function CharacterChoice() {
    const intl = useIntl();
    const isOnline = useOnlineStatus();
    const { isMobile } = useBreakpoints();
    const { session } = useContext(sessionStore);
    const { config } = useContext(configStore);
    const userType = useUserType();
    const userTypeColor =
        userType === UserType.Teacher
            ? "var(--element-teacher-default)"
            : "var(--element-evidenceb-default)";
    const [_, updateExtra] = useUserExtra();

    const avatarCharacter = useAvatarCharacter();
    const assetsDetails = useAssetsDetails();

    const avatarOptions:
        | { src: string; alt: string; userType?: UserType[] }[]
        | undefined = config.logos.usersAvatars?.filter((avatar) =>
        avatar.userType.includes(userType)
    );

    const [selectedAvatarSrc, setSelectedAvatarSrc] = useState<string>(
        avatarCharacter?.src ?? ""
    );
    const handleChange = (value: string) => setSelectedAvatarSrc(value);

    useEffect(() => {
        if (
            selectedAvatarSrc === avatarCharacter?.src ||
            (selectedAvatarSrc === "" && !avatarCharacter)
        )
            return;

        let avatar: { src: string; alt: string } | undefined;
        if (selectedAvatarSrc === "") {
            avatar = undefined;
        } else {
            const correspondingAvatar = avatarOptions?.find(
                (option) => option.src === selectedAvatarSrc
            );
            avatar = correspondingAvatar
                ? pick(correspondingAvatar, ["src", "alt"])
                : undefined;
        }
        const opts = { ignore: false };
        try {
            updateExtra({ avatarCharacter: avatar }, opts);
        } catch {
            // ignore
        }
        return () => {
            opts.ignore = true;
        };
    }, [selectedAvatarSrc, avatarOptions, updateExtra, avatarCharacter]);

    return (
        <>
            {isMobile && (
                <div className="character-and-background-choice__selection">
                    <div
                        className="character-and-background-choice__selected-option"
                        style={{
                            background: "var(--text-main-default)",
                        }}
                    >
                        {avatarCharacter ? (
                            // alt is a key of avatarCharacter
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <OwnAvatar className="character-and-background-choice__selected-option__avatar" />
                        ) : (
                            <Avatar
                                className="character-and-background-choice__selected-option__avatar"
                                name={`${session.firstname} ${session.lastname}`}
                                fontSize="40px"
                                colors={{
                                    background: userTypeColor,
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
            <RadioGroup
                label={
                    <h3>
                        {intl.formatMessage(
                            accountMessages[
                                userType === UserType.Teacher
                                    ? "avatarChoiceMessageTeacher"
                                    : "avatarChoiceMessage"
                            ]
                        )}
                    </h3>
                }
                value={selectedAvatarSrc}
                onChange={handleChange}
                className="character-and-background-choice__options"
                orientation="horizontal"
                isDisabled={!isOnline}
            >
                <RadioGroup.Radio
                    value=""
                    className={cn("character-and-background-choice__option", {
                        "--checked": selectedAvatarSrc === "",
                    })}
                >
                    <Avatar
                        name={fullName(session)}
                        fontSize="20px"
                        colors={{
                            text: "var(--text-main-default)",
                            background: "transparent",
                            border: "none",
                        }}
                    />
                    <VisuallyHidden as="span">
                        {intl.formatMessage(
                            accountMessages.defaultAvatarButton
                        )}
                    </VisuallyHidden>
                </RadioGroup.Radio>
                {avatarOptions?.map((avatar, index) => (
                    <RadioGroup.Radio
                        className={cn(
                            "character-and-background-choice__option",
                            { "--checked": selectedAvatarSrc === avatar.src }
                        )}
                        key={index}
                        value={avatar.src}
                    >
                        <Image
                            assetsDetails={assetsDetails}
                            src={avatar.src}
                            alt={avatar.alt}
                        />
                    </RadioGroup.Radio>
                ))}
            </RadioGroup>
        </>
    );
}
