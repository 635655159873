import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";

export const handleActionTokenError = (error: AxiosError) => {
    if (isAxiosErrorStatus(error, 401))
        // Don't capture for expired or consumed tokens
        return;
    Sentry.captureException(error);
};

export const isAxiosErrorStatus = (error: AxiosError, status: number) =>
    error.response?.status === status;
