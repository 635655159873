import React from "react";
import { OverlayTriggerState } from "react-stately";

import { Modal } from "@evidenceb/athena-common/design-system/Modal";

import "./Modal.scss";

interface ResetProgressionModalProps {
    state: OverlayTriggerState;
    onModalDismiss: () => void;
    onClick: () => void;

    title: string;
    content: string;
    buttonLabel: string;
}

const ResetProgressionModal = ({
    state,
    onModalDismiss,
    onClick,
    title,
    content,
    buttonLabel,
}: ResetProgressionModalProps) => {
    return (
        <Modal
            open={state.isOpen}
            title={title}
            onClose={onModalDismiss}
            footerButtons={[
                {
                    label: buttonLabel,
                    onClick: onClick,
                },
            ]}
        >
            <p>{content}</p>
        </Modal>
    );
};

export default ResetProgressionModal;
