import { useQuery } from "@tanstack/react-query";
import { queryKeys as notificationsQueryKeys } from "./queryKeys";
import useNotificationsApiClient from "./useNotificationsApiClient";
import { Student, UserType } from "../../../interfaces/User";
import { useCallback, useContext, useEffect, useRef } from "react";
import { deepCompare } from "../../../utils/compare";
import useAthenaAPIClient from "../../../hooks/queries/useAthenaAPIClient/useAthenaAPIClient";
import * as Sentry from "@sentry/react";
import { sessionStore } from "../../../contexts/SessionContext";
import { userToSession } from "../../../utils/session";
import { RawNotificationType } from "../Types";

export const useNotificationsQuery = (userId: string, userType: UserType) => {
    const {
        session: { evidencebId },
        setSession,
    } = useContext(sessionStore);

    const { getNotifications } = useNotificationsApiClient();
    const query = useQuery({
        queryKey: notificationsQueryKeys.getNotifications(),
        queryFn: () => getNotifications(userId, userType),
        staleTime: 2 * 60 * 1000,
    });

    // Sync notifications and assignments
    // TODO: Refactor when the assignments are refactored with react-query
    const prevAssignmentsNotificationsRef = useRef<string[]>();
    const { getUser } = useAthenaAPIClient();
    const refetchAssignments = useCallback(async () => {
        try {
            const data = (await getUser({
                role: "student",
                sub: evidencebId,
            })) as Student;
            setSession((curr) => ({
                ...curr,
                resourceAssignments: userToSession(data, userType)
                    .resourceAssignments,
            }));
        } catch (err) {
            Sentry.captureException(err);
            // Ignore error - state of the app might be inconsistent for
            // a few minutes
        }
    }, [userType, getUser, evidencebId, setSession]);

    useEffect(() => {
        if (!query.data || userType !== UserType.Student) return;
        const nextAssignmentsNotifications = query.data
            .filter(
                (notification) =>
                    notification.notified_content.type ===
                        RawNotificationType.RESOURCE_ASSIGNED ||
                    notification.notified_content.type ===
                        RawNotificationType.MULTIPLAYER_SESSION_CREATED
            )
            .map((notif) => notif.notified_content.resource_id);
        if (
            prevAssignmentsNotificationsRef.current &&
            !deepCompare(
                prevAssignmentsNotificationsRef.current,
                nextAssignmentsNotifications
            )
        )
            refetchAssignments();
        prevAssignmentsNotificationsRef.current = nextAssignmentsNotifications;
    }, [query.data, userType, refetchAssignments]);

    return query;
};
