import { ActivityShell, HierarchyIds } from "@evidenceb/gameplay-interfaces";
import {
    hierarchyPathToIds,
    makeHierarchyPath,
} from "../../../../components/TreeCheckboxGroup/utils";
import { LearningSetTypes } from "../../../../interfaces/AthenaResources";
import {
    MinimalPlaylistItem,
    Playlist,
    PlaylistItem,
    PlaylistStatus,
} from "../type";
import { CreateURLParams } from "./const";
import { getActivityById } from "../../../../utils/dataRetrieval";
import { Data } from "../../../../interfaces/Data";

type ConstructorArgs<T extends LearningSetTypes = LearningSetTypes> = {
    type: T;
    id: string;
    parents?: string[];
};

export class ResourcePath<T extends LearningSetTypes = LearningSetTypes> {
    type: T;
    id: string;
    /** from most distant ancestor to closest parent */
    parents: string[];

    constructor({ type, id, parents }: ConstructorArgs<T>) {
        this.type = type;
        this.id = id;
        this.parents = parents ?? [];
    }

    static fromEditorPath(path: string) {
        const ids = hierarchyPathToIds(path);
        const type = ids[0];
        const id = ids[ids.length - 1];
        const parents = ids.slice(1, -1);
        if (!checkType(type))
            throw new Error(`Invalid type of resource path (found ${type})`);
        const constructorArgs = { type, id, parents };
        if (constructorArgs.type === LearningSetTypes.Module)
            return new ModuleResourcePath(constructorArgs as any);
        return new CollectionResourcePath(constructorArgs as any);
    }

    static fromCreateUrl(params: CreateURLParams) {
        if (!checkType(params.resourceType))
            throw new Error("Missing type in url");
        if (isCollectionSet(params.resourceType))
            return CollectionResourcePath.fromCreateUrl(params);
        return ModuleResourcePath.fromCreateUrl(params);
    }

    static fromLearningItem(item: PlaylistItem) {
        if (isCollectionSet(item.learning_item_context.itemType))
            return CollectionResourcePath.fromLearningItem(item);
        return ModuleResourcePath.fromLearningItem(item);
    }

    toEditorPath() {
        return makeHierarchyPath(this.type, ...this.parents, this.id);
    }

    toPlaylistLearningItem(data: Data) {
        return {
            learning_item_id: this.id,
            learning_item_context: {
                parents: this.parents,
                shell:
                    this instanceof ModuleResourcePath
                        ? getActivityById(this.activityId, data).shell ??
                          ActivityShell.Wizard
                        : ActivityShell.Wizard,
                itemType: this.type,
            },
        };
    }
}

export class CollectionResourcePath extends ResourcePath<
    LearningSetTypes.Tutorial | LearningSetTypes.Workshop
> {
    static fromLearningItem(item: PlaylistItem) {
        if (!isCollectionSet(item.learning_item_context.itemType))
            throw new Error(
                `Invalid collection type ${item.learning_item_context.itemType}`
            );
        return new CollectionResourcePath({
            id: item.learning_item_id,
            parents: item.learning_item_context.parents,
            type: item.learning_item_context.itemType,
        });
    }

    static fromCreateUrl(params: CreateURLParams) {
        if (
            (params.resourceType !== LearningSetTypes.Tutorial &&
                params.resourceType !== LearningSetTypes.Workshop) ||
            !params.resourceId1 ||
            !params.resourceId2 ||
            !params.resourceId3
        )
            throw new Error("Cannot create CollectionResourcePath");
        return new CollectionResourcePath({
            type: params.resourceType,
            parents: [params.resourceId1, params.resourceId2],
            id: params.resourceId3,
        });
    }

    get setId() {
        return this.parents[this.parents.length - 1];
    }
}

export class ModuleResourcePath extends ResourcePath<LearningSetTypes.Module> {
    static fromCreateUrl(params: CreateURLParams) {
        if (
            params.resourceType !== LearningSetTypes.Module ||
            !params.resourceId1 ||
            !params.resourceId2 ||
            !params.resourceId3 ||
            !params.resourceId4
        )
            throw new Error("Cannot create ModuleResourcePath");
        return new ModuleResourcePath({
            type: LearningSetTypes.Module,
            id: params.resourceId4,
            parents: [
                params.resourceId1,
                params.resourceId2,
                params.resourceId3,
            ],
        });
    }

    static fromLearningItem(item: PlaylistItem) {
        return new ModuleResourcePath({
            type: LearningSetTypes.Module,
            id: item.learning_item_id,
            parents: item.learning_item_context.parents,
        });
    }

    static fromMOAE(item: HierarchyIds) {
        return new ModuleResourcePath({
            id: item.exerciseId,
            type: LearningSetTypes.Module,
            parents: [item.moduleId, item.objectiveId, item.activityId],
        });
    }

    get activityId() {
        if (this.type !== LearningSetTypes.Module)
            throw new Error("Not a module");
        return this.parents[2];
    }

    get objectiveId() {
        if (this.type !== LearningSetTypes.Module)
            throw new Error("Not a module");
        return this.parents[1];
    }

    get MOAE() {
        return {
            moduleId: this.parents[0],
            objectiveId: this.objectiveId,
            activityId: this.activityId,
            exerciseId: this.id,
        };
    }
}

const checkType = (type: unknown): type is LearningSetTypes => {
    if (
        typeof type === "string" &&
        (Object.values(LearningSetTypes) as string[]).includes(type)
    )
        return true;
    return false;
};

const isCollectionSet = (
    type: unknown
): type is LearningSetTypes.Tutorial | LearningSetTypes.Workshop =>
    typeof type === "string" &&
    (type === LearningSetTypes.Tutorial || type === LearningSetTypes.Workshop);

export const isLearningItemAuthorized =
    (tutosFlag: boolean, workshopsFlag: boolean) =>
    (item: MinimalPlaylistItem) =>
        !item.learning_item_context.itemType || // for retrocompatibility
        item.learning_item_context.itemType === LearningSetTypes.Module ||
        (tutosFlag &&
            item.learning_item_context.itemType ===
                LearningSetTypes.Tutorial) ||
        (workshopsFlag &&
            item.learning_item_context.itemType === LearningSetTypes.Workshop);

export const filterWithStatus =
    (status: PlaylistStatus) => (playlist: Playlist<PlaylistItem>) =>
        playlist.status.status === status;

export const authorizedItemType =
    (tutosFlag: boolean, workshopsFlag: boolean) =>
    (playlist: Playlist<PlaylistItem>) =>
        playlist.learning_items.every(
            isLearningItemAuthorized(tutosFlag, workshopsFlag)
        );
