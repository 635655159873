import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";
import { ErrorInfo } from "../interfaces/Error";

// Context typing
interface ErrorContext {
    errorInfo: ErrorInfo;
    setErrorInfo: Dispatch<SetStateAction<ErrorInfo>>;
}

// Initial State
const initialState: ErrorInfo = {
    displayModal: false,
};

// Context init
const errorStore = createContext<ErrorContext>({
    errorInfo: initialState,
    setErrorInfo: () => null,
});

// Provider init
const { Provider } = errorStore;

// Final setup
const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
    const [errorInfo, setErrorInfo] = useState(initialState);
    return <Provider value={{ errorInfo, setErrorInfo }}>{children}</Provider>;
};

export { errorStore, ErrorProvider };
