import React, {
    createContext,
    useState,
    useEffect,
    PropsWithChildren,
} from "react";
import { checkIsOnline } from "../utils/offline";
import { useFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";

const initialState = true;

const onlineStatusStore = createContext<boolean>(initialState);

const { Provider } = onlineStatusStore;

const DELAY = 30_000;

const OnlineStatusProvider = ({ children }: PropsWithChildren) => {
    const [isOnline, setIsOnline] = useState(initialState);
    const offlineFeaturesFlag = useFeatureFlag("offlineFeatures");

    useEffect(() => {
        if (!offlineFeaturesFlag) return;

        let ignore = false;
        const markOnline = async () => {
            const online = await checkIsOnline((err) => console.error(err));
            if (ignore) return;
            setIsOnline(online);
        };
        markOnline();
        const idInterval = setInterval(markOnline, DELAY);
        return () => {
            clearInterval(idInterval);
            ignore = true;
        };
    }, [offlineFeaturesFlag]);

    return <Provider value={isOnline}>{children}</Provider>;
};

export { onlineStatusStore, OnlineStatusProvider };
