import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { sessionStore } from "../../contexts/SessionContext";
import { useAvatarCharacter } from "./utils";
import { fullName } from "../../utils/format";
import { UserType } from "../../interfaces/User";
import { useUserType } from "../../hooks/useUserInfo";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import { Modal } from "@evidenceb/athena-common/design-system/Modal";
import { useBreakpoints } from "@evidenceb/athena-common/helpers/breakpoints";
import { withFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";
import Avatar, {
    getAvatarBorder,
    OwnAvatar,
} from "../../components/Avatar/Avatar";
import CharacterChoice from "./CharacterChoice";
import BackgroundChoice from "./BackgroundChoice";
import { accountMessages } from "./accountMessages";
import { useUserExtra } from "../../hooks/queries/useUserExtra";

import "./Account.scss";

function AvatarChoice() {
    const { isMobile } = useBreakpoints();

    if (isMobile) return <MobileAvatarChoice />;
    return <DesktopAvatarChoice />;
}
export default withFeatureFlag("chooseAvatar")(AvatarChoice);

function DesktopAvatarChoice() {
    const intl = useIntl();

    return (
        <>
            <div className="account-settings__parameters__avatar-title">
                <h2>{intl.formatMessage(accountMessages.avatarChoiceTitle)}</h2>
                <CurrentAvatar />
            </div>
            <div className="account-settings__parameters__avatar">
                <CharacterChoice />
                <BackgroundChoice />
            </div>
        </>
    );
}

const CurrentAvatar = () => {
    const intl = useIntl();
    const { session } = useContext(sessionStore);
    const userType = useUserType();
    const userTypeColor =
        userType === UserType.Teacher
            ? "var(--element-teacher-default)"
            : "var(--element-evidenceb-default)";
    const [{ avatarBackground }] = useUserExtra();
    const avatarCharacter = useAvatarCharacter();

    return (
        <div className="account-settings__parameters__current-avatar">
            <p>{intl.formatMessage(accountMessages.currentAvatarMessage)}</p>
            {avatarCharacter ? (
                <div
                    className="account-settings__parameters__current-avatar__preview__custom"
                    style={{
                        background: avatarBackground
                            ? avatarBackground.code
                            : "var(--element-grey20)",
                        border: getAvatarBorder(
                            session.theme,
                            avatarBackground?.code
                        ),
                    }}
                >
                    {/* alt is a key of avatarCharacter */}
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}

                    <Avatar
                        name={fullName(session)}
                        fontSize="20px"
                        colors={{
                            background: avatarBackground
                                ? avatarBackground.code
                                : userTypeColor,
                        }}
                        character={avatarCharacter}
                        className="account-settings__parameters__current-avatar__preview__default"
                    />
                </div>
            ) : (
                <Avatar
                    name={fullName(session)}
                    fontSize="20px"
                    colors={{
                        background: avatarBackground
                            ? avatarBackground.code
                            : userTypeColor,
                    }}
                    className="account-settings__parameters__current-avatar__preview__default"
                />
            )}
        </div>
    );
};

function MobileAvatarChoice() {
    const intl = useIntl();
    const { session } = useContext(sessionStore);
    const avatarCharacter = useAvatarCharacter();

    const [avatarModalOpenState, setAvatarModalOpenState] = useState(false);
    function handleAvatarModal() {
        setAvatarModalOpenState(!avatarModalOpenState);
    }
    const [backgroundModalOpenState, setBackgroundModalOpenState] =
        useState(false);
    function handleBackgroundModal() {
        setBackgroundModalOpenState(!backgroundModalOpenState);
    }
    const avatarChoiceText =
        session.userType === UserType.Student
            ? intl.formatMessage(accountMessages.avatarChoiceMessage)
            : intl.formatMessage(accountMessages.avatarChoiceMessageTeacher);

    const backGroundChoiceText =
        session.userType === UserType.Student
            ? intl.formatMessage(accountMessages.backgroundChoiceMessage)
            : intl.formatMessage(
                  accountMessages.backgroundChoiceMessageTeacher
              );

    return (
        <div className="account-settings__mobile-header">
            <div className="account-settings__mobile-header__avatar-container">
                <OwnAvatar className="account-settings__mobile-header__current-avatar" />
                <p>{`${session.firstname} ${session.lastname}`}</p>
            </div>
            <div className="account-settings__mobile-header__button-container">
                <button
                    className="account-settings__mobile-header__button"
                    onClick={handleAvatarModal}
                >
                    {avatarChoiceText}
                    <Icon path="chevron_right" />
                </button>
                <Modal
                    open={avatarModalOpenState}
                    onClose={handleAvatarModal}
                    disableFooter={true}
                    title={avatarChoiceText}
                >
                    <CharacterChoice />
                </Modal>
                <button
                    className="account-settings__mobile-header__button"
                    onClick={handleBackgroundModal}
                >
                    {backGroundChoiceText}
                    <Icon path="chevron_right" />
                </button>
                <Modal
                    open={backgroundModalOpenState}
                    onClose={handleBackgroundModal}
                    disableFooter={true}
                    title={backGroundChoiceText}
                >
                    <BackgroundChoice avatarCharacter={avatarCharacter} />
                </Modal>
            </div>
        </div>
    );
}
