import { defineMessages, IntlShape } from "react-intl";
import FeedbackForm from "../components/MotivationalMessage/FeedbackForm";
import React, { ReactNode } from "react";
import { AthenaEventStreamPublisher } from "../contexts/EventStreamContext";
import { AthenaEvent } from "@evidenceb/athena-event-storage-schemas";
import { toast } from "react-toastify";

export const catalog: { [key: string]: { ico: string | null } } = {
    activityDeactivationMessage: { ico: "planet" },
    objectiveActivationMessage: { ico: "planet4" },
    stableMessage: { ico: "rocket" },
    overcomedBlockingMessage: { ico: "rocket2" },
    improvementInstMessage: { ico: "star_angle" },
    activityActivationMessage: { ico: "star_fall" },
    improvementMessage: { ico: "star_rainbow" },
    regressionMessage: { ico: "stars_line" },
    objectiveDeactivationMessage: { ico: "stars_minimalistic" },
    blockedMessage: { ico: null },
};

export const messages = defineMessages({
    "mm-activityDeactivationMessage0": {
        id: "mm-activityDeactivationMessage0",
        defaultMessage:
            "Bravo, tu viens de terminer une de tes premières activités ! Et si tu veux la retrouver, va dans “Révision” !",
    },
    "mm-activityDeactivationMessage1": {
        id: "mm-activityDeactivationMessage1",
        defaultMessage:
            "Tes derniers exercices t’ont permis de terminer cette activité ! Retrouve la dans “Révision” quand tu veux !",
    },
    "mm-activityActivationMessage0": {
        id: "mm-activityActivationMessage0",
        defaultMessage:
            "Bien joué ! Tu viens de débloquer une nouvelle activité. Et tu vas bientôt en découvrir les exercices !",
    },
    "mm-activityActivationMessage1": {
        id: "mm-activityActivationMessage1",
        defaultMessage:
            "Bien joué ! Avec tes derniers exercices, tu viens encore de débloquer une nouvelle activité. Continue comme ça et tu débloqueras l’ensemble du module !",
    },
    "mm-stableMessage0": {
        id: "mm-stableMessage0",
        defaultMessage: "Continue tes efforts, tu y es presque !",
    },
    "mm-overcomedBlockingMessage0": {
        id: "mm-overcomedBlockingMessage0",
        defaultMessage:
            "Continue comme ça et cette notion sera bientôt maîtrisée !",
    },
    "mm-improvementMessage0": {
        id: "mm-improvementMessage0",
        defaultMessage: "Félicitations, tu progresses vraiment bien !",
    },
    "mm-regressionMessage0": {
        id: "mm-regressionMessage0",
        defaultMessage: "Ne te décourage pas, tu vas y arriver !",
    },
    "mm-objectiveDeactivationMessage0": {
        id: "mm-objectiveDeactivationMessage0",
        defaultMessage:
            "Bravo ! Objectif terminé : te voilà avec une nouvelle compétence !",
    },
    "mm-objectiveDeactivationMessage1": {
        id: "mm-objectiveDeactivationMessage1",
        defaultMessage:
            "Tes derniers exercices t’ont permis de valider cet objectif ! ",
    },
    "mm-objectiveActivationMessage0": {
        id: "mm-objectiveActivationMessage0",
        defaultMessage:
            "Bien joué ! Tu viens de débloquer un nouvel objectif. Et tu vas bientôt en découvrir les exercices !",
    },
    "mm-blockedMessage0": {
        id: "mm-blockedMessage0",
        defaultMessage: "Tu rencontres une difficulté?",
    },
    "mm-blockedMessage-feedback-submitButtonLabel": {
        id: "mm-blockedMessage-feedback-submitButtonLabel",
        defaultMessage: "Envoyer",
    },
    "mm-blockedMessage-feedback-postSubmitMessage-title": {
        id: "mm-blockedMessage-feedback-postSubmitMessage-title",
        defaultMessage: "Merci pour ta réponse !",
    },
    "mm-blockedMessage-feedback-postSubmitMessage-body": {
        id: "mm-blockedMessage-feedback-postSubmitMessage-body",
        defaultMessage:
            "Demande de l'aide à ton professeur si tu rencontres des difficultés.",
    },
    "mm-blockedMessage-feedback-answer0": {
        id: "mm-blockedMessage-feedback-answer0",
        defaultMessage: "Oui, je ne comprends pas ce que je dois faire.",
    },
    "mm-blockedMessage-feedback-answer1": {
        id: "mm-blockedMessage-feedback-answer1",
        defaultMessage: "Oui, je ne trouve pas la réponse.",
    },
    "mm-blockedMessage-feedback-answer2": {
        id: "mm-blockedMessage-feedback-answer2",
        defaultMessage: "Non, tout va bien.",
    },

    // WE DONT USE THE TRANSITORY STATE FOR NOW
    // NEED TO IMPROVE THE MECHANIC BEFORE REUSING IT AND SENDING MESSAGE RELATED TO IT

    // "mm-transActivityDeactivationMessage0": {
    //     id: "mm-transActivityDeactivationMessage0",
    //     defaultMessage:
    //         "Bravo, tu viens de terminer cette activité ! Et si tu veux la retrouver, va dans “Révision” !",
    // },
    // "mm-transActivityDeactivationMessage1": {
    //     id: "mm-transActivityDeactivationMessage1",
    //     defaultMessage:
    //         "Tes derniers exercices t’ont permis de terminer cette activité ! Retrouve la dans “Révision” quand tu veux !",
    // },
    // "mm-transObjectiveActivationMessage0": {
    //     id: "mm-transObjectiveActivationMessage0",
    //     defaultMessage:
    //         "Bien joué ! Tes derniers exercices t'ont permis de débloquer un nouvel objectif. Et tu vas bientôt en découvrir les exercices !",
    // },
    // "mm-transStableMessage0": {
    //     id: "mm-transStableMessage0",
    //     defaultMessage: "Continue tes efforts, tu y es presque !",
    // },
    // "mm-transOvercomedBlockingMessage0": {
    //     id: "mm-transOvercomedBlockingMessage0",
    //     defaultMessage:
    //         "Continue comme ça et cette notion sera bientôt maîtrisée !",
    // },
    // "mm-transImprovementInstMessage0": {
    //     id: "mm-transImprovementInstMessage0",
    //     defaultMessage: "Félicitations, tu progresses !",
    // },
    // "mm-transActivityActivationMessage0": {
    //     id: "mm-transActivityActivationMessage0",
    //     defaultMessage:
    //         "Bien joué ! Tes derniers exercices t'ont permis de débloquer une nouvelle activité. Et tu vas bientôt en découvrir les exercices !",
    // },
    // "mm-transImprovementMessage0": {
    //     id: "mm-transImprovementMessage0",
    //     defaultMessage: "Félicitations, tu progresses vraiment bien !",
    // },
    // "mm-transRegressionMessage0": {
    //     id: "mm-transRegressionMessage0",
    //     defaultMessage: "Ne te décourage pas, tu vas y arriver !",
    // },
    // "mm-transObjectiveDeactivationMessage0": {
    //     id: "mm-transObjectiveDeactivationMessage0",
    //     defaultMessage:
    //         "Bravo ! Tu viens de terminer un de tes premiers objectifs ! Tu maîtrises maintenant une nouvelle compétence.",
    // },
    // "mm-transBlockedMessage0": {
    //     id: "mm-transBlockedMessage0",
    //     defaultMessage:
    //         "On dirait que tu as un peu de mal. N'hésites pas à demander de l'aide à ton professeur",
    // },
});

const feedbackForms: {
    [type: string]: (
        athenaEvent: AthenaEvent<"ai", "AIShowMessageEvent">,
        intl: IntlShape,
        publishToAthenaEventStream: AthenaEventStreamPublisher
    ) => ReactNode;
} = {
    blockedMessage: (athenaEvent, intl, publishToAthenaEventStream) => (
        <FeedbackForm
            question={{
                type: "SINGLE_CHOICE",
                possibleAnswers: [
                    "mm-blockedMessage-feedback-answer0",
                    "mm-blockedMessage-feedback-answer1",
                    "mm-blockedMessage-feedback-answer2",
                ].map((id) =>
                    intl.formatMessage(messages[id as keyof typeof messages])
                ),
            }}
            onSubmit={(question, answer) => {
                publishToAthenaEventStream<
                    "player",
                    "PlayerMotivationalMessageFeedbackGivenEvent"
                >({
                    type: "PlayerMotivationalMessageFeedbackGivenEvent",
                    categoryData: {
                        learningItem: {
                            id: athenaEvent.data.exerciseId,
                            version: -1,
                        },
                        parentItems: [
                            { id: athenaEvent.data.activityId, version: -1 },
                            { id: athenaEvent.data.objectiveId, version: -1 },
                            { id: athenaEvent.data.moduleId, version: -1 },
                        ],
                        workmode: athenaEvent.categoryData.workmode,
                    },
                    data: {
                        possibleFeedbacks: question.possibleAnswers,
                        feedback: answer,
                    },
                });
                toast.update(athenaEvent.id, { autoClose: 8000 });
            }}
            submitButtonLabel={intl.formatMessage(
                messages["mm-blockedMessage-feedback-submitButtonLabel"]
            )}
            postSubmitMessageTitle={intl.formatMessage(
                messages["mm-blockedMessage-feedback-postSubmitMessage-title"]
            )}
            postSubmitMessageBody={intl.formatMessage(
                messages["mm-blockedMessage-feedback-postSubmitMessage-body"]
            )}
        />
    ),
};

export function buildMessage(
    athenaEvent: AthenaEvent<"ai", "AIShowMessageEvent">,
    intl: IntlShape,
    athenaEventStreamPublisher: AthenaEventStreamPublisher
) {
    const type: string = athenaEvent.data.messageType;
    const index: number = athenaEvent.data.index || 0;
    let messageId = `${type}${index}`;

    // REMOVED TRANSISTORY STATE FOR NOW
    //const isTransitoryState = !athenaEvent.data.isPermanentState;
    //if isTransitoryState then add trans prefix while preserving camelCase aspect of the string
    // if (isTransitoryState) {
    //     let firstLetter = messageId.charAt(0).toUpperCase();
    //     messageId = `trans${firstLetter}${messageId.slice(1)}`;
    // }

    // add prefix "mm- to the messageId
    messageId = `mm-${messageId}`;

    // throw error if messageId is not found
    let message = messages[messageId as keyof typeof messages];
    if (!message) {
        throw new Error(
            `MessageId ${messageId} not found in the intl dictionnary`
        );
    }

    const feedbackForm =
        type in feedbackForms
            ? feedbackForms[type](athenaEvent, intl, athenaEventStreamPublisher)
            : null;

    const selectedText = intl.formatMessage(message);
    return (
        <>
            {selectedText}
            {feedbackForm}
        </>
    );
}
