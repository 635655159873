import React, { memo } from "react";
import cn from "classnames";
import { HierarchyPath, TreeNode } from "../types";
import {
    getNodeCheckboxId,
    getNodeLabel,
    makeHierarchyPath,
    nodePath,
} from "../utils";
import TriStateCheckbox from "../../../design-system-components/TriStateCheckbox/TriStateCheckbox";

import { VisuallyHidden } from "@evidenceb/athena-common/ui/VisuallyHidden";
import { MessageDescriptor, useIntl } from "react-intl";

type Props = {
    onActivateNode?: (hierarchyPath: HierarchyPath) => void;
    onCheckNode: (hierarchyPath: HierarchyPath) => void;
    selectLeafLabel: (state: boolean) => MessageDescriptor;
    depth: number;
    parentHierarchyPath: HierarchyPath;
    index: number;
    node: Omit<TreeNode, "childrenIds" | "readOnly" | "skipInHierarchy">;
    checked: boolean;
    isActiveNode: boolean;
};

const Leaf = ({
    node,
    onActivateNode,
    onCheckNode,
    selectLeafLabel,
    checked,
    depth,
    parentHierarchyPath,
    index,
    isActiveNode,
}: Props) => {
    const intl = useIntl();

    const hierarchyPath = makeHierarchyPath(
        parentHierarchyPath,
        nodePath(node)
    );
    const label = getNodeLabel(node, index);

    const clickHandler = onActivateNode
        ? () => {
              onActivateNode(hierarchyPath);
          }
        : undefined;
    const checkboxChangeHandler = () => {
        onCheckNode(hierarchyPath);
    };

    return (
        <div
            className={cn("tree-checkbox-list__leaf", `--indent-${depth}`, {
                "--active": isActiveNode,
                "--with-click-handler": clickHandler,
            })}
        >
            {clickHandler ? (
                <button
                    className="tree-checkbox-list__leaf-button"
                    onClick={clickHandler}
                >
                    <Label icon={node.icon} label={label} />
                </button>
            ) : (
                <Label icon={node.icon} label={label} aria-hidden />
            )}
            <TriStateCheckbox
                className="leaf__checkbox"
                id={getNodeCheckboxId(hierarchyPath)}
                checked={checked}
                onChange={checkboxChangeHandler}
                variant={isActiveNode ? "inverted" : "default"}
            >
                <VisuallyHidden>
                    {intl.formatMessage(selectLeafLabel(checked), { label })}
                </VisuallyHidden>
            </TriStateCheckbox>
        </div>
    );
};
export default memo(Leaf);

const Label = ({
    label,
    icon,
    ...props
}: {
    label: string;
    icon?: React.ReactNode;
    "aria-hidden"?: boolean;
}) => {
    return (
        <div {...props} className="leaf__label">
            {icon && icon}
            <span>{label}</span>
        </div>
    );
};
