import React from "react";
import { Helmet } from "react-helmet-async";
import { usePageTitle } from "../../hooks/usePage";
import { MessageDescriptor, useIntl } from "react-intl";

export type Props = { title: string | MessageDescriptor } & Partial<
    Parameters<typeof usePageTitle>[1]
>;

const TabTitle = ({ title: _title, ...opts }: Props) => {
    const intl = useIntl();
    const title =
        typeof _title === "string" ? _title : intl.formatMessage(_title);
    const pageTitle = usePageTitle(title, opts);
    return (
        <Helmet>
            <title>{pageTitle}</title>
        </Helmet>
    );
};
export default TabTitle;
