import { useRadio, useRadioGroup } from "@react-aria/radio";
import React, {
    CSSProperties,
    PropsWithChildren,
    forwardRef,
    useId,
} from "react";
import {
    RadioGroupProps,
    RadioGroupState,
    useRadioGroupState,
} from "react-stately";
import cn from "classnames";
import { ValidationResult } from "@react-types/shared";

import "./RadioGroup.scss";

type Props<T extends string> = PropsWithChildren<{
    className?: string;
    value: T;
    onChange: (nextValue: T) => void;
    orientation?: RadioGroupProps["orientation"];
    label: React.JSX.Element;
    /** @default false */
    isDisabled?: boolean;
}>;

const initialRadioContext: RadioGroupState = {
    name: "",
    isDisabled: true,
    isReadOnly: true,
    isRequired: false,
    validationState: "valid",
    isInvalid: false,
    selectedValue: "",
    setSelectedValue: () => {},
    lastFocusedValue: "",
    setLastFocusedValue: () => {},
    realtimeValidation: {
        isInvalid: false,
        validationErrors: [],
        validationDetails: {
            badInput: false,
            valid: true,
            customError: false,
            patternMismatch: false,
            rangeOverflow: false,
            tooLong: false,
            rangeUnderflow: false,
            stepMismatch: false,
            tooShort: false,
            typeMismatch: false,
            valueMissing: false,
        },
    },
    displayValidation: {
        isInvalid: false,
        validationErrors: [],
        validationDetails: {
            badInput: false,
            valid: true,
            customError: false,
            patternMismatch: false,
            rangeOverflow: false,
            tooLong: false,
            rangeUnderflow: false,
            stepMismatch: false,
            tooShort: false,
            typeMismatch: false,
            valueMissing: false,
        },
    },
    updateValidation: function (_: ValidationResult): void {
        throw new Error("Function not implemented.");
    },
    resetValidation: function (): void {
        throw new Error("Function not implemented.");
    },
    commitValidation: function (): void {
        throw new Error("Function not implemented.");
    },
};
const RadioContext = React.createContext<RadioGroupState>(initialRadioContext);

const RadioGroup = <T extends string>(props: Props<T>) => {
    const { children, label, className } = props;
    // @ts-ignore
    const state = useRadioGroupState(props);
    // @ts-ignore
    const { radioGroupProps, labelProps } = useRadioGroup(props, state);

    const labelWithRadiogroupProps = React.cloneElement(label, labelProps);

    return (
        <>
            {labelWithRadiogroupProps}
            <div
                {...radioGroupProps}
                className={cn(className, radioGroupProps.className)}
            >
                <RadioContext.Provider value={state}>
                    {children}
                </RadioContext.Provider>
            </div>
        </>
    );
};

const Radio = forwardRef<
    HTMLInputElement,
    PropsWithChildren<{
        value: string;
        className?: string;
        style?: CSSProperties;
    }>
>((props, ref) => {
    const { children, className, style } = props;
    const state = React.useContext(RadioContext);
    const _ref = React.useRef<HTMLInputElement | null>(null);
    const id = useId();
    let radio = useRadio({ id, ...props }, state, _ref);
    let {
        inputProps: { tabIndex, ...inputProps },
    } = radio;

    // Fix: When there is no selected value, all items had tabIndex=-1 and the
    // radiogroup couldn't get focus. We manually set to 0 the tabIndex of radio
    // input if no radio is selected of if one radio already has had focus (so
    // that we cannot tab between all radios)
    if (
        !state.selectedValue &&
        (!state.lastFocusedValue || state.lastFocusedValue === id)
    ) {
        tabIndex = 0;
    }

    return (
        <>
            <input
                className="ds-radio-group__input"
                {...inputProps}
                tabIndex={tabIndex}
                ref={(elem) => {
                    _ref.current = elem;
                    if (ref && "current" in ref) {
                        ref.current = elem;
                    } else if (typeof ref === "function") {
                        ref(elem);
                    }
                }}
            />

            <label className={className} style={style} htmlFor={inputProps.id}>
                {children}
            </label>
        </>
    );
});

RadioGroup.Radio = Radio;
export default RadioGroup;
