import { defineMessages } from "react-intl";

export const translateOutOfReactScopeMessages = (messages: {
    [messageKey: string]: string;
}): void => {
    translateNoScript(messages.noScript);
};

const translateNoScript = (text?: string): void => {
    if (!text) return;
    const element = document.getElementsByTagName("noscript")[0];
    if (element.textContent) element.textContent = text;
    else if (element.innerHTML) element.innerHTML = text;
};

defineMessages({
    noScript: {
        id: "noScript",
        defaultMessage: "You need to enable JavaScript to run this app.",
    },
});
