import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";
import useAthenaAPIClient from "./useAthenaAPIClient/useAthenaAPIClient";
import { useVersion } from "../useSessiont";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    UnavailableGameplayError,
    getExercisesWithAvailableGameplays,
} from "../../utils/fetch-gameplays";
import {
    Exercise,
    ExerciseDefinition,
} from "@evidenceb/athena-common/interfaces/Exercise";
import Loader from "../../components/Loader/Loader";
import { Modal } from "@evidenceb/athena-common/design-system/Modal";
import { defineMessages, useIntl } from "react-intl";
import { commonMessages } from "../../utils/messages";
import { useHistory } from "react-router-dom";
import MiniLoader from "../../components/Loader/MiniLoader";

const queryKeys = {
    all: ["content"] as const,
    getExercises: () => [...queryKeys.all, "exercises"] as const,
};

const useFetchExercises = () => {
    const athenaAPIClient = useAthenaAPIClient();
    const initWithAdaptiveTestFlag = useFeatureFlag(
        "init-bandit-manchot-with-adaptive-test"
    );
    const version = useVersion();
    return async () => {
        const { exercises: exercisesDefinition } =
            (await athenaAPIClient.getData(
                version,
                initWithAdaptiveTestFlag,
                "exercises-only"
            )) as unknown as { exercises: ExerciseDefinition[] };
        return await getExercisesWithAvailableGameplays(exercisesDefinition);
    };
};

export const useExercisesQuery = (
    opts?: Partial<{
        /** @default true */
        retry: boolean;
        /** @default true */
        enabled: boolean;
    }>
) => {
    const fetchExercises = useFetchExercises();

    return useQuery({
        queryKey: queryKeys.getExercises(),
        staleTime: Infinity,
        queryFn: fetchExercises,
        retry: opts?.retry,
        enabled: opts?.enabled,
    });
};

const useRefetchOnError = (query: UseQueryResult<any>) => {
    const timeoutIdRef = useRef<any>(undefined);
    const delayRef = useRef<number>(1000);
    useEffect(() => {
        if (query.status !== "error") {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
                timeoutIdRef.current = undefined;
            }
        } else {
            if (timeoutIdRef.current) return;
            const timeoutId = setTimeout(() => {
                query.refetch().catch(() => {
                    /* ignore */
                });
                timeoutIdRef.current = undefined;
                delayRef.current = Math.min(30000, 2 * delayRef.current);
            }, delayRef.current);
            timeoutIdRef.current = timeoutId;
        }
    }, [query]);
    useEffect(() => {
        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, []);
};

const exercisesContext = createContext<Exercise[]>([]);

export const withExercises =
    <T extends React.JSX.IntrinsicAttributes>(
        Component: (props: T) => React.JSX.Element,
        opts?: Partial<{
            ifOfflineFeaturesActive: boolean;
        }>
    ) =>
    (props: T) => {
        const offlineFeatures = useFeatureFlag("offlineFeatures");

        const query = useExercisesQuery({
            enabled: !opts?.ifOfflineFeaturesActive || offlineFeatures,
            // Deactivate default retries because they are not reflected in the
            // query state
            retry: false,
        });
        // Custom handle refetches instead
        useRefetchOnError(query);

        const [isRefetchingAfterError, setIsRefetchingAfterError] =
            useState<boolean>(query.status === "error");
        useEffect(() => {
            if (query.status === "error") {
                setIsRefetchingAfterError(true);
            } else if (query.status === "success") {
                setIsRefetchingAfterError(false);
            }
        }, [query.status]);

        if (isRefetchingAfterError) {
            return <ExercisesError query={query} />;
        }

        return (
            <Loader isLoading={!query.data}>
                {query.data && (
                    <exercisesContext.Provider value={query.data}>
                        <Component {...props} />
                    </exercisesContext.Provider>
                )}
            </Loader>
        );
    };

export const useExercises = () => {
    return useContext(exercisesContext);
};

const ExercisesError = ({ query }: { query: UseQueryResult<Exercise[]> }) => {
    const intl = useIntl();
    const history = useHistory();

    if (query.error instanceof UnavailableGameplayError) {
        return (
            <Modal
                open
                closable={false}
                onClose={() => {}}
                aria-labelledby="exercise-loading-error"
                disableHeader
                footerButtons={[
                    {
                        label: intl.formatMessage(commonMessages.goBack),
                        onClick: () => history.goBack(),
                    },
                ]}
            >
                <h2 id="exercise-loading-error">
                    {intl.formatMessage(messages.unavailableGameplayTitle)}
                </h2>
                <p>
                    {intl.formatMessage(
                        messages.unavailableGameplayDescription
                    )}
                </p>
            </Modal>
        );
    } else {
        return (
            <Modal
                open
                closable={false}
                onClose={() => {}}
                aria-labelledby="exercise-loading-error"
                disableHeader
                okText={intl.formatMessage(messages.retryNow)}
                onOk={() => query.refetch({ cancelRefetch: false })}
                cancelText={intl.formatMessage(commonMessages.goBack)}
                onCancel={() => history.goBack()}
            >
                <h2 id="exercise-loading-error">
                    {intl.formatMessage(messages.fetchFailureTitle)}
                </h2>
                <p>{intl.formatMessage(messages.fetchFailureDescription)}</p>
                <p
                    aria-live="polite"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                        marginTop: 8,
                    }}
                >
                    {query.fetchStatus === "fetching" && (
                        <>
                            <MiniLoader
                                color={"var(--element-teacher-default)"}
                            />
                            {intl.formatMessage(messages.retryInProgress)}
                        </>
                    )}
                    {(query.fetchStatus === "idle" ||
                        query.fetchStatus === "paused") &&
                        intl.formatMessage(messages.waitingForNextRetry)}
                </p>
            </Modal>
        );
    }
};

const messages = defineMessages({
    unavailableGameplayTitle: {
        id: "exercisesLoadingError-gameplay-title",
        defaultMessage: "An error occurred when loading the exercises",
    },
    unavailableGameplayDescription: {
        id: "exercisesLoadingError-gameplay-description",
        defaultMessage:
            "Our teams have been notified and the exercises should be available shortly. Please try again later.",
    },
    fetchFailureTitle: {
        id: "exercisesLoadingError-fetchFailure-title",
        defaultMessage: "An error occurred when loading the exercises",
    },
    fetchFailureDescription: {
        id: "exercisesLoadingError-fetchFailure-description",
        defaultMessage:
            "We will try again in a short while. Thank you for your understanding.",
    },
    retryNow: {
        id: "exercisesLoadingError-retryNow",
        defaultMessage: "Retry now",
    },
    retryInProgress: {
        id: "exercisesLoadingError-retryInProgress",
        defaultMessage: "Retry in progress",
    },
    waitingForNextRetry: {
        id: "exercisesLoadingError-waitingForNextRetry",
        defaultMessage: "Waiting for next retry",
    },
});
