import React from "react";
import { Student } from "../../interfaces/Session";
import { AvatarProps, UserAvatar } from "./Avatar";

import "./Avatar.scss";

export type StackUser = Pick<Student, "firstname" | "lastname" | "extra">;
type Props = Omit<AvatarProps, "name"> & {
    users: [StackUser, StackUser];
};

const AvatarStack = ({ users, ...props }: Props) => {
    return (
        <div className="avatars">
            {users.map((user, i) => (
                <UserAvatar key={i} user={user} {...props} />
            ))}
        </div>
    );
};
export default AvatarStack;
