import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";
import { Statement } from "@xapi/xapi";

export interface Sync {
    /** Array of statements that could not be synced */
    unsyncedStatements?: Statement[];
    /**
     * True if the app is currently trying to sync statements, false otherwise
     */
    syncingStatements: boolean;
    isProcessing: boolean;
}

interface SyncContext {
    sync: Sync;
    setSync: Dispatch<SetStateAction<Sync>>;
}

// Context init
const syncStore = createContext<SyncContext>({
    sync: { syncingStatements: false, isProcessing: false },
    setSync: () => null,
});

// Provider init
const { Provider } = syncStore;

/**
 * Context used in the context of the Bandit Manchot resourceSeries manager to handle
 * statements syncing errors
 */
const SyncProvider = ({ children }: { children: React.ReactNode }) => {
    const [sync, setSync] = useState<Sync>({
        syncingStatements: false,
        isProcessing: false,
    });

    return <Provider value={{ sync, setSync }}>{children}</Provider>;
};

export { syncStore, SyncProvider };
