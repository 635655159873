import { useContext } from "react";
import { configStore } from "../contexts/ConfigContext";
import { WorkMode, WorkModeStatus, WorkModeType } from "../interfaces/Config";

export const useLang = () => {
    const {
        config: { lang },
    } = useContext(configStore);
    return lang as string;
};

/**
 * @returns the workmode configuration, or undefined for legacy versions where
 * there is no workmode configuration
 */
export const useWorkmodeConfig = (
    workmodeType: WorkModeType
): WorkMode | undefined => {
    const {
        config: { workModesConfig },
    } = useContext(configStore);
    if (!workModesConfig) {
        return undefined;
    }
    return (
        workModesConfig?.find((workmode) => workmode.type === workmodeType) ??
        inactiveWorkmode(workmodeType)
    );
};
const inactiveWorkmode = (type: WorkModeType): WorkMode => ({
    type,
    status: WorkModeStatus.Disabled,
});
