import { useCallback, useState } from "react";
import { HierarchyPath, NodeCheckingStrategy, TreeNode } from "./types";
import {
    changeBranchCheckStatus,
    changeLeafCheckStatus,
    getChildLeaves,
    getExtremity,
    getBranchCheckedStatus,
    hierarchyPathToIds,
    isLeaf,
    makeHierarchyPath,
    setCheckedStatusFalseOnAllBranches,
    setCheckedStatusTrueOnAllBranches,
} from "./utils";

export const useNodeCheckingStrategy = (
    nodes: Record<string, TreeNode>,
    strategy: NodeCheckingStrategy
): {
    checkedLeaves: HierarchyPath[];
    toggleNode: (hierarchyPath: HierarchyPath) => void;
    setCheckedLeaves: (leaves: HierarchyPath[], dirty: boolean) => void;
    dirty: boolean;
} => {
    const [dirty, setDirty] = useState<boolean>(false);
    const [checkedLeaves, setCheckedLeaves] = useState<HierarchyPath[]>([]);
    const toggleNode = useCallback(
        (path: HierarchyPath) => {
            setDirty(true);
            setCheckedLeaves((prevCheckedLeaves) =>
                strategy(path, prevCheckedLeaves, nodes)
            );
        },
        [nodes, strategy]
    );
    const setCheckedLeavesCb = useCallback(
        (leaves: HierarchyPath[], dirty: boolean) => {
            let checkedLeaves: HierarchyPath[] = [];
            leaves.forEach((leaf) => {
                checkedLeaves = strategy(leaf, checkedLeaves, nodes);
            });
            setCheckedLeaves(checkedLeaves);
            setDirty(dirty);
        },
        [nodes, strategy]
    );
    return {
        checkedLeaves,
        toggleNode,
        setCheckedLeaves: setCheckedLeavesCb,
        dirty,
    };
};

export const checkSpecificHierarchy: NodeCheckingStrategy = (
    checkedNodePath,
    prevCheckedLeaves,
    nodes
) => {
    const extremity = getExtremity(checkedNodePath, nodes);
    if (isLeaf(extremity))
        return changeLeafCheckStatus(prevCheckedLeaves, checkedNodePath);
    return changeBranchCheckStatus(
        prevCheckedLeaves,
        extremity,
        makeHierarchyPath(...hierarchyPathToIds(checkedNodePath).slice(0, -1)),
        nodes
    );
};

export const checkOnAllBranches: NodeCheckingStrategy = (
    checkedNodePath,
    prevCheckedLeaves,
    nodes
) => {
    const extremity = getExtremity(checkedNodePath, nodes);
    const childLeaves = isLeaf(extremity)
        ? [{ ...extremity, hierarchyPath: checkedNodePath }]
        : getChildLeaves(extremity, nodes);
    const checked = getBranchCheckedStatus(childLeaves, prevCheckedLeaves);
    switch (checked) {
        case true:
            return setCheckedStatusFalseOnAllBranches(
                childLeaves.map((node) => node.id),
                prevCheckedLeaves,
                nodes
            );
        case false:
        case "mixed":
            return setCheckedStatusTrueOnAllBranches(
                childLeaves.map((node) => node.id),
                prevCheckedLeaves,
                nodes
            );
    }
};
