import { AthenaEvent } from "@evidenceb/athena-event-storage-schemas";
import { AthenaEventStream } from "@evidenceb/athena-events-tools";
import compact from "lodash/compact";

export async function logUserEventsSummary(
    athenaEventStream: AthenaEventStream,
    filters?: {
        createdAtFrom?: string;
        createdAtTo?: string;
        insertedAtFrom?: string;
        insertedAtTo?: string;
    }
) {
    const athenaEvents = await athenaEventStream.find({
        $and: compact([
            filters?.createdAtFrom
                ? { createdAt: { $gte: filters.createdAtFrom } }
                : null,
            filters?.createdAtTo
                ? { createdAt: { $lte: filters.createdAtTo } }
                : null,
            filters?.insertedAtFrom
                ? { insertedAt: { $gte: filters.insertedAtFrom } }
                : null,
            filters?.insertedAtTo
                ? { insertedAt: { $lte: filters.insertedAtTo } }
                : null,
        ]),
    });
    console.groupCollapsed("All events");
    console.log(athenaEvents);
    console.groupEnd();
    console.groupCollapsed("Summary");
    console.table(athenaEvents.map(summarizeAthenaEvent));
    console.groupEnd();
}

interface AthenaEventSummary {
    "Created at": Date;
    "Inserted at": Date | null;
    Type: string;
    "Session Id": string;
    Details: string;
}

function summarizeAthenaEvent(
    athenaEvent: AthenaEvent<any, any>
): AthenaEventSummary {
    return {
        "Created at": new Date(athenaEvent.createdAt),
        "Inserted at": athenaEvent.insertedAt
            ? new Date(athenaEvent.insertedAt)
            : null,
        Type: athenaEvent.type,
        "Session Id": athenaEvent.sessionId,
        Details: getAthenaEventDetails(athenaEvent),
    };
}

function getAthenaEventDetails(athenaEvent: AthenaEvent<any, any>): string {
    switch (athenaEvent.type) {
        case "SessionLoginEvent":
            return [
                `Browser: ${athenaEvent.data.browser.name}`,
                `Status: ${
                    athenaEvent.categoryData.online ? "online  " : "offline "
                }`,
            ].join(", ");
        case "ResultExerciseEvent":
            return [
                `Learning Item: ${athenaEvent.categoryData.learningItem.id}`,
                `Correct: ${athenaEvent.data.correct}`,
            ].join(", ");
        default:
            // TODO: add details for other types as needed.
            return "";
    }
}
