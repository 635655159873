import { useContext } from "react";
import { sessionStore } from "../contexts/SessionContext";
import { UserType } from "../interfaces/User";

export const useUserId = (): string => {
    const {
        session: { userId },
    } = useContext(sessionStore);
    return userId;
};

export const useUserType = () => {
    const {
        session: { userType },
    } = useContext(sessionStore);
    return userType;
};

export const useTeacherId = (): string | undefined => {
    const userType = useUserType();
    const userId = useUserId();
    const {
        session: { resourceAssignments },
    } = useContext(sessionStore);

    if (userType === UserType.Teacher) return userId;
    // For now we make the assumption that all assignments come from the same
    // teacher because students have only one teacher
    if (resourceAssignments && resourceAssignments.length > 0)
        return resourceAssignments[0].teacher;
    return undefined;
};

export const useStudentClassroomId = (): string | undefined => {
    const { session } = useContext(sessionStore);
    // We make the assumption that a student can either have 0 classrooms
    // (internal users) or 1 classroom
    return session.classroomIds?.[0];
};
