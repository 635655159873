import React, { useCallback, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { sessionStore } from "../../contexts/SessionContext";
import { useUserExtra } from "../../hooks/queries/useUserExtra";
import { DismissableMessages } from "../../interfaces/Session";

import "./MessageDismiss.scss";

interface Props {
    sessionMessageKey: DismissableMessages;
}

/**
 * This component displays a checkbox that allows the user to say they don't
 * want to see the message again. It updates the session accordingly
 */
const MessageDismiss = ({ sessionMessageKey }: Props) => {
    const [isMessageDismissed, dismissMessage] =
        useDismissMessage(sessionMessageKey);

    return (
        <div>
            <input
                type="checkbox"
                checked={isMessageDismissed}
                onChange={() => {
                    dismissMessage();
                }}
                id="dismiss-message-checkbox"
            />
            <label
                className="message-dismiss__label"
                htmlFor="dismiss-message-checkbox"
            >
                <FormattedMessage
                    id="dontShowMessageAgain"
                    defaultMessage="Do not show this message again"
                />
            </label>
        </div>
    );
};

export default MessageDismiss;

export const useDismissableMessage = (message: DismissableMessages) => {
    const {
        session: { dismissMessages },
    } = useContext(sessionStore);

    return dismissMessages[message];
};

export const useMessageDismiss = (
    message: DismissableMessages,
    forever = false
) => {
    const { setSession } = useContext(sessionStore);
    const [extra, updateExtra] = useUserExtra();

    return useCallback(() => {
        setSession((curr) => ({
            ...curr,
            dismissMessages: {
                ...curr.dismissMessages,
                [message]: !curr.dismissMessages[message],
            },
        }));
        if (forever) {
            const newDismissedMessages = extra?.dismissedMessages
                ? [...extra.dismissedMessages, message]
                : [message];
            updateExtra({
                dismissedMessages: newDismissedMessages,
            });
        }
    }, [message, setSession, extra.dismissedMessages, forever, updateExtra]);
};

export const useDismissMessage = (message: DismissableMessages) => {
    const dismissed = useDismissableMessage(message);
    const dismiss = useMessageDismiss(message);
    return [dismissed, dismiss] as const;
};
