import React, { useReducer } from "react";
import {
    LearningAssetsCacheAction,
    learningSetsIdsAndVersions,
} from "./action";
import { CachedVersion, LearningAssetsCacheState, initialState } from "./state";
import * as localStorage from "../../../utils/localStorage";
import {
    deleteFromCache as deleteFromCacheOperation,
    downloadToCache as downloadToCacheOperation,
    performCacheOperation,
} from "./cacheMethods";
import { initLearningAssetsCacheState } from "./initMethods";

const cacheReducer = (
    state: LearningAssetsCacheState,
    action: LearningAssetsCacheAction
): LearningAssetsCacheState => {
    switch (action.type) {
        case "ASSETS_CACHE_INIT": {
            return initLearningAssetsCacheState(action.payload);
        }

        case "SET_CURRENT_DOWNLOAD_ABORT_SIGNAL": {
            return {
                ...state,
                currentDownloadTask: {
                    id: action.payload.id,
                    abortController: action.payload.abortController,
                },
            };
        }

        case "DOWNLOAD_STATUS_CHANGED": {
            const newLearningSetState = action.payload?.ids.reduce(
                (
                    newLearningSetState: Map<String, CachedVersion>,
                    currentLearningSetId: learningSetsIdsAndVersions
                ) => {
                    const currentLearningSetVersion = action.payload
                        .shoudCacheVersion
                        ? currentLearningSetId?.version
                        : null;

                    if (currentLearningSetId.learningSets.length > 0) {
                        currentLearningSetId.learningSets.map((id: string) => {
                            newLearningSetState.set(
                                id,
                                currentLearningSetVersion
                            );
                        });
                    }

                    return newLearningSetState.set(
                        currentLearningSetId.learningSets[0],
                        currentLearningSetVersion
                    );
                },
                state.learningSetState
            );

            localStorage.setItem(localStorage.Key.LEARNING_SETS_CACHE_STATE, [
                ...newLearningSetState,
            ]);

            return {
                ...state,
                learningSetState: newLearningSetState,
                currentDownloadTask: undefined,
            };
        }

        case "CANCEL_CURRENT_DOWNLOAD": {
            state.currentDownloadTask?.abortController.abort();
            return {
                ...state,
                currentDownloadTask: undefined,
            };
        }

        default:
            return state;
    }
};

const learningAssetsCacheMiddleware = async (
    state: LearningAssetsCacheState,
    action: LearningAssetsCacheAction,
    dispatchCacheAction: React.Dispatch<LearningAssetsCacheAction>
) => {
    switch (action.type) {
        case "START_ASYNC_LEARNING_SET_DOWNLOAD": {
            const abortController = new AbortController();
            dispatchCacheAction({
                type: "SET_CURRENT_DOWNLOAD_ABORT_SIGNAL",
                payload: {
                    abortController: abortController,
                    id: action.payload.id,
                },
            });

            const result = await performCacheOperation(
                action.payload.id,
                abortController.signal,
                state.baseAssetsUrl,
                state.version,
                downloadToCacheOperation,
                state.resourcesEndpoint
            );
            if (result) {
                if (result.status) {
                    dispatchCacheAction({
                        type: "DOWNLOAD_STATUS_CHANGED",
                        payload: {
                            ids: result.everyLearningSetId,
                            shoudCacheVersion: true,
                        },
                    });
                } else {
                    dispatchCacheAction({
                        type: "DOWNLOAD_STATUS_CHANGED",
                        payload: {
                            ids: result.everyLearningSetId,
                            shoudCacheVersion: false,
                        },
                    });
                }
            }
            break;
        }

        case "START_ASYNC_LEARNING_SET_DELETE": {
            const abortController = new AbortController();
            dispatchCacheAction({
                type: "SET_CURRENT_DOWNLOAD_ABORT_SIGNAL",
                payload: {
                    abortController: abortController,
                    id: action.payload.id,
                },
            });
            const result = await performCacheOperation(
                action.payload.id,
                abortController.signal,
                state.baseAssetsUrl,
                state.version,
                deleteFromCacheOperation,
                state.resourcesEndpoint
            );
            if (result) {
                dispatchCacheAction({
                    type: "DOWNLOAD_STATUS_CHANGED",
                    payload: {
                        ids: result.everyLearningSetId,
                        shoudCacheVersion: false,
                    },
                });
            }
            break;
        }
    }
};

export const LearningAssetsCacheReducerWithMiddleware = () => {
    const [learningAssetsCacheState, dispatchLearningAssetsCacheAction] =
        useReducer(cacheReducer, initialState);

    const dispatchCacheActionWithMiddleware: React.Dispatch<
        LearningAssetsCacheAction
    > = (action: LearningAssetsCacheAction) => {
        learningAssetsCacheMiddleware(
            learningAssetsCacheState,
            action,
            dispatchLearningAssetsCacheAction
        );
        return dispatchLearningAssetsCacheAction(action);
    };
    return [learningAssetsCacheState, dispatchCacheActionWithMiddleware];
};
