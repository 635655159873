import {
    openExerciceAssetsCache,
    findModuleForObjectiveId,
    buildModuleStatus,
    buildObjectiveStatus,
    buildExerciceStatus,
    CacheResult,
} from "./cacheCommon";
import {
    CacheState,
    ModuleStatus,
    ObjectiveStatus,
    ExerciceStatus,
    AssetStatus,
} from "./cacheState";
import { StartUncachePayload } from "./cacheAction";
import { ModuleNotFoundError } from "../../errors";

export async function startUncache(
    signal: AbortSignal,
    state: CacheState,
    payload: StartUncachePayload
): Promise<CacheResult> {
    const cache = await openExerciceAssetsCache();
    switch (payload.cacheType) {
        case "module": {
            const moduleStatus = await uncacheModule(
                signal,
                state.modules.find((module) => module.id === payload.id)!,
                cache
            );
            return {
                cacheType: payload.cacheType,
                id: payload.id,
                result: moduleStatus,
            };
        }
        case "objective": {
            const module = findModuleForObjectiveId(state, payload.id);
            if (!module)
                throw new ModuleNotFoundError(
                    `Could not find module ID ${payload.id}`
                );

            const objectiveStatus = await uncacheObjective(
                signal,
                module.objectives.find(
                    (objective) => objective.id === payload.id
                )!,
                cache
            );
            return {
                cacheType: payload.cacheType,
                id: payload.id,
                result: objectiveStatus,
            };
        }
    }
}

async function uncacheModule(
    signal: AbortSignal,
    moduleStatus: ModuleStatus,
    cache: Cache
): Promise<ModuleStatus> {
    const objectiveStatuses = new Array<ObjectiveStatus>();

    for (const previousObjectiveStatus of moduleStatus.objectives) {
        if (!signal.aborted) {
            const newObjectiveStatus = await uncacheObjective(
                signal,
                previousObjectiveStatus,
                cache
            );
            objectiveStatuses.push(newObjectiveStatus);
        } else {
            objectiveStatuses.push(previousObjectiveStatus);
        }
    }

    return buildModuleStatus(moduleStatus.id, objectiveStatuses);
}

async function uncacheObjective(
    signal: AbortSignal,
    objectiveStatus: ObjectiveStatus,
    cache: Cache,
    uncachedAssets: Set<string> = new Set()
): Promise<ObjectiveStatus> {
    const exerciceStatuses = new Array<ExerciceStatus>();

    for (const previousExerciceStatus of objectiveStatus.exercices) {
        if (!signal.aborted) {
            const newExerciceStatus = await uncacheExercice(
                signal,
                previousExerciceStatus,
                cache,
                uncachedAssets
            );
            exerciceStatuses.push(newExerciceStatus);
        } else {
            exerciceStatuses.push(previousExerciceStatus);
        }
    }

    return buildObjectiveStatus(objectiveStatus.id, exerciceStatuses);
}

async function uncacheExercice(
    signal: AbortSignal,
    exerciceStatus: ExerciceStatus,
    cache: Cache,
    uncachedAssets: Set<string> = new Set()
): Promise<ExerciceStatus> {
    const assetStatuses = new Array<AssetStatus>();

    for (const asset of exerciceStatus.assets) {
        const assetUrl = asset.url;

        if (!uncachedAssets.has(assetUrl)) {
            if (!signal.aborted) {
                uncachedAssets.add(assetUrl);
                if (await uncacheAsset(cache, assetUrl)) {
                    assetStatuses.push({
                        url: assetUrl,
                        status: "not_downloaded",
                    });
                } else {
                    assetStatuses.push({ url: assetUrl, status: "downloaded" });
                }
            } else {
                // Not cached but task is aborted case
                assetStatuses.push({ url: assetUrl, status: "downloaded" });
            }
        } else {
            // Already cached case
            assetStatuses.push({ url: assetUrl, status: "downloaded" });
        }
    }

    return buildExerciceStatus(exerciceStatus.id, assetStatuses);
}

function uncacheAsset(cache: Cache, assetUrl: string): Promise<boolean> {
    return cache.delete(assetUrl);
}
