import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { sessionStore } from "../../contexts/SessionContext";
import { useUserType } from "../../hooks/useUserInfo";
import { UserType } from "../../interfaces/User";

import { useBreakpoints } from "@evidenceb/athena-common/helpers/breakpoints";
import { VisuallyHidden } from "@evidenceb/athena-common/ui/VisuallyHidden";
import Avatar, {
    getAvatarBorder,
    getInitialsDefaultTextColors,
} from "../../components/Avatar/Avatar";
import { backgroundColors, getColorAlt } from "./utils";
import { accountMessages } from "./accountMessages";
import { useUserExtra } from "../../hooks/queries/useUserExtra";
import RadioGroup from "../../components/RadioGroup/RadioGroup";
import cn from "classnames";

import "./Account.scss";
import useOnlineStatus from "../../hooks/useOnlineStatus";

type Props = {
    avatarCharacter?: { src: string; alt: string };
};

export default function BackgroundChoice({ avatarCharacter }: Props) {
    const intl = useIntl();
    const isOnline = useOnlineStatus();
    const { isMobile } = useBreakpoints();
    const { session } = useContext(sessionStore);
    const userType = useUserType();
    const userTypeColor =
        userType === UserType.Teacher
            ? "var(--element-teacher-default)"
            : "var(--element-evidenceb-default)";

    const [{ avatarBackground }, updateExtra] = useUserExtra();

    const [selectedBackgroundCode, setSelectedBackgroundCode] =
        useState<string>(avatarBackground?.code ?? "");
    const handleChange = (nextValue: string) =>
        setSelectedBackgroundCode(nextValue);

    useEffect(() => {
        if (
            selectedBackgroundCode === avatarBackground?.code ||
            (!avatarBackground && selectedBackgroundCode === "")
        )
            return;

        let background: { code: string; alt: string } | undefined;
        if (selectedBackgroundCode === "") {
            background = undefined;
        } else {
            background = backgroundColors.find(
                (color) => color.code === selectedBackgroundCode
            );
        }
        const opts = { ignore: false };
        try {
            updateExtra(
                {
                    avatarBackground: background
                        ? {
                              code: background.code,
                              alt: getColorAlt(intl, background.alt),
                          }
                        : undefined,
                },
                opts
            );
        } catch {
            // ignore
        }
        return () => {
            opts.ignore = true;
        };
    }, [selectedBackgroundCode, updateExtra, intl, avatarBackground]);

    return (
        <>
            {isMobile && (
                <div className="character-and-background-choice__selection">
                    <div
                        className="character-and-background-choice__selected-option"
                        style={{ background: "none" }}
                    >
                        <Avatar
                            className="character-and-background-choice__selected-option__avatar"
                            character={avatarCharacter}
                            name={`${session.firstname} ${session.lastname}`}
                            fontSize="40px"
                            colors={{
                                background: avatarBackground?.code
                                    ? avatarBackground.code
                                    : userTypeColor,
                                text: getInitialsDefaultTextColors(
                                    session.theme,
                                    avatarBackground?.code
                                ),
                            }}
                        />
                    </div>
                </div>
            )}
            <RadioGroup
                value={
                    selectedBackgroundCode === ""
                        ? backgroundColors?.[0].code
                        : selectedBackgroundCode
                }
                onChange={handleChange}
                className="character-and-background-choice__options"
                label={
                    <h3>
                        {intl.formatMessage(
                            accountMessages[
                                userType === UserType.Teacher
                                    ? "backgroundChoiceMessageTeacher"
                                    : "backgroundChoiceMessage"
                            ]
                        )}
                    </h3>
                }
                orientation="horizontal"
                isDisabled={!isOnline}
            >
                {backgroundColors.map((color, index) => (
                    <RadioGroup.Radio
                        key={index}
                        value={color.code}
                        className={cn(
                            "character-and-background-choice__option",
                            {
                                "--checked":
                                    color.code === selectedBackgroundCode,
                            }
                        )}
                        style={{
                            background: color.code && color.code,
                            border: getAvatarBorder(session.theme, color.code),
                        }}
                    >
                        <VisuallyHidden>
                            {getColorAlt(intl, color.alt)}
                        </VisuallyHidden>
                    </RadioGroup.Radio>
                ))}
            </RadioGroup>
        </>
    );
}
