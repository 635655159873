import React, { PropsWithChildren, useContext } from "react";
import Header from "../Header/Header";
import MobileNav from "../Header/MobileNav/MobileNav";
import { sessionStore } from "../../contexts/SessionContext";
import TabTitle, { Props as TabTitleProps } from "../TabTitle/TabTitle";
import OfflineBanner from "../../pages/OfflineBanner/OfflineBanner";
import classNames from "classnames";
import "./RouteWithHeaderWrapper.scss";
const RouteWithHeaderWrapper = ({
    children,
    routerClassName,
}: PropsWithChildren & { routerClassName?: string }) => {
    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <BaseRouteWithHeaderWrapper>
                <div
                    className={classNames(
                        "router-with-header-wrapper",
                        routerClassName
                    )}
                >
                    {children}
                </div>
            </BaseRouteWithHeaderWrapper>
        </div>
    );
};
export default RouteWithHeaderWrapper;

export const BaseRouteWithHeaderWrapper = ({
    children,
    title,
    ...titleProps
}: PropsWithChildren<Partial<TabTitleProps>>) => {
    const {
        session: {
            flags: { displaySignIn },
        },
    } = useContext(sessionStore);
    return (
        <>
            {title && <TabTitle title={title} {...titleProps} />}
            {!displaySignIn && <Header />}
            <OfflineBanner />
            {children}
            {!displaySignIn && <MobileNav />}
        </>
    );
};
