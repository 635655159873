import { ExerciseDefinition } from "@evidenceb/athena-common/interfaces/Exercise";

export enum LearningSetTypes {
    Tutorial = "tutorial",
    Module = "module",
    Workshop = "workshop",
}

export type LearningSet<
    Item = string | undefined,
    Set = string | undefined,
    Context = unknown,
    Status = unknown,
    Type extends string = string,
    Tags = unknown
> = {
    type: "set";
    id: string;
    learning_set_type: Type;
    name: string;
    status: Status;
    description: string;
    learning_set_context: Context;
    learning_set_tags: Tags;
    learning_sets: Set extends undefined ? undefined : Set[];
    learning_items: Item extends undefined ? undefined : Item[];
    created: string;
    updated: string;
    /** From lowest specificity (subject) to highest specificity */
    mapping_nodes?: string[];
};

export type LearningItem<
    Data = Omit<ExerciseDefinition, "type" | "id">,
    Context = unknown,
    Type extends string = string
> = {
    type: "item";
    id: string;
    learning_item_type: Type;
    learning_item_data: Data;
    learning_item_context: Context;
};

export type Resources<
    Item extends LearningItem = LearningItem,
    Set extends LearningSet<
        string | undefined,
        string | undefined
    > = LearningSet<string | undefined, string | undefined>
> = Record<string, Item | Set>;
