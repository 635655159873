export const checkIsOnline = async (
    onError?: (e: unknown) => void
): Promise<boolean> => {
    if (!window.navigator.onLine) {
        // false is always reliable for no network. true might lie
        return false;
    } else {
        try {
            const params = new URLSearchParams({
                offlineTest: Date.now().toString(), // random value to prevent cached responses
            });
            const requestURL = `/?${params.toString()}`;
            await fetch(requestURL, {
                method: "HEAD",
            });
            return true;
        } catch (err) {
            if (onError) onError(err);
            return false;
        }
    }
};
