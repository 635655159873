import React from "react";
import PopoverMenu from "../../../design-system-components/PopoverMenu/PopoverMenu";
import useAthenaAPIClient from "../../../hooks/queries/useAthenaAPIClient/useAthenaAPIClient";

import { useFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import { useHistory } from "react-router";
import { useContext } from "react";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { ROUTES } from "../../../router/routing";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import AccessibleButton from "../../AccessibleButton/AccessibleButton";
import { useAccountSections } from "../../../pages/Account/utils";
import { fullName } from "../../../utils/format";
import { OwnAvatar } from "../../Avatar/Avatar";
import { redirectAfterLogout } from "../../../utils/auth";

const UserSettings = () => {
    const intl = useIntl();
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const athenaAPIClient = useAthenaAPIClient();
    const history = useHistory();
    const accountSections = useAccountSections();
    const showAccount = Object.values(accountSections).some((show) => show);
    const isSpecimen = useFeatureFlag("isSpecimen");

    const handleLogout = async () => {
        let isLoggedOut = await athenaAPIClient.logout<string>();
        if (!isLoggedOut) return;
        localStorage.clear();
        redirectAfterLogout(config, session.appProvider);
    };
    const popoverMenuHandler = async (key: React.Key) => {
        if (key === "account") history.push(`/${ROUTES.account}`);
        if (key === "logout") handleLogout();
    };

    if (!showAccount && isSpecimen) {
        return (
            <div className="header__right-part_user-avatar">
                <OwnAvatar aria-hidden className="big" />
                {fullName(session)}
            </div>
        );
    }

    return (
        <PopoverMenu
            className="header__right-part_user-menu"
            trigger={
                <AccessibleButton className="header__right-part_user-avatar">
                    <OwnAvatar aria-hidden className="big" />
                    {fullName(session)}
                    <Icon path="chevron_down" size="medium" />
                </AccessibleButton>
            }
            popoverPlacement="bottom end"
            onAction={popoverMenuHandler}
        >
            <PopoverMenu.Item>
                <span>
                    <OwnAvatar aria-hidden className="big" />
                    {fullName(session)}
                </span>
            </PopoverMenu.Item>
            {showAccount && (
                <PopoverMenu.Item
                    key="account"
                    textValue={intl.formatMessage(messages.account)}
                >
                    <Link to={`/${ROUTES.account}`}>
                        <Icon
                            path="user_outline"
                            size="medium"
                            color="var(--text-main-default)"
                        />
                        <span>{intl.formatMessage(messages.account)}</span>
                        <Icon
                            className="account_chevron_right"
                            path="chevron_right"
                        />
                    </Link>
                </PopoverMenu.Item>
            )}
            {!isSpecimen && (
                <PopoverMenu.Item
                    key="logout"
                    textValue={intl.formatMessage(messages.logout)}
                >
                    <button
                        className="header__menu_logout"
                        onClick={handleLogout}
                    >
                        <Icon path="logout" size="medium" />
                        <span>{intl.formatMessage(messages.logout)}</span>
                    </button>
                </PopoverMenu.Item>
            )}
        </PopoverMenu>
    );
};
export default UserSettings;

const messages = defineMessages({
    logout: {
        id: "misc-logout",
        defaultMessage: "Log Out",
    },
    account: {
        id: "misc-manageAccount",
        defaultMessage: "Manage Account",
    },
});
