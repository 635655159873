import React from "react";
import cn from "classnames";
import { VisuallyHidden } from "@evidenceb/athena-common/ui/VisuallyHidden";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import { IntlShape, defineMessages, useIntl } from "react-intl";
import { UserType } from "../../../interfaces/User";
import { TooltipWrapper } from "../../../design-system-components/ToolTip/ToolTip";
import classNames from "classnames";
import {
    NotificationType,
    NotificationAggregate,
    NotificationStatus,
    StudentInfo,
    ResourceType,
} from "../Types";
import { Link } from "react-router-dom";
import { UserAvatar } from "../../Avatar/Avatar";
import AvatarStack, { StackUser } from "../../Avatar/AvatarStack";
import MiniLoader from "../../Loader/MiniLoader";
import { useUserType } from "../../../hooks/useUserInfo";
import { workmodesMessages } from "../../../pages/Workmodes/workmodesMessages";
import { ROUTES } from "../../../pages/Workmodes/Workshop/const";
import { useUrl } from "../../../hooks/usePage";
import { Pagetype } from "../../../interfaces/Config";
import useTimeFormat from "../../../hooks/useTimeFormat";

import "./Notification.scss";

interface Props {
    notification: NotificationAggregate;
    onNotificationReadStatusChange: (
        id: string | undefined,
        students: StudentInfo[],
        status: NotificationStatus
    ) => void;
}

const Notification = ({
    notification,
    onNotificationReadStatusChange,
}: Props) => {
    const intl = useIntl();
    const { relativeTimeFormat } = useTimeFormat();
    const userType = useUserType();

    const notificationType = notification.content.type;
    const resourceType = notification.content.resource?.type;
    const name = notification.content.resource?.name;
    const students = notification.students;

    const workshopBasePath = useUrl(Pagetype.WORKMODE_WORKSHOP);
    const duoBasePath = useUrl(Pagetype.WORKMODE_DUO);
    const playlistBasePath = useUrl(Pagetype.WORKMODE_PLAYLIST);

    const getLink = () => {
        if (resourceType === ResourceType.PLAYLIST)
            return `${playlistBasePath}/${
                userType === UserType.Teacher ? "play" : "player"
            }/${notification.id}`;
        else if (resourceType === ResourceType.DUO)
            return `${duoBasePath}/${
                userType === UserType.Teacher
                    ? notification.content.resource?.extra.moduleId
                    : `play/${notification.id}`
            }`;
        else if (resourceType === ResourceType.WORKSHOP)
            return `${workshopBasePath}/${ROUTES.preview}/${notification.content?.resource?.extra?.workshop_id}`;
        return "";
    };

    return (
        <article
            className={cn("notification", `--status-${notification.status}`)}
        >
            <div className="notification__main">
                <Link
                    to={{
                        pathname: getLink(),
                        state: { from: window.location.pathname },
                    }}
                >
                    <VisuallyHidden>
                        {intl.formatMessage(messages.goToResource, {
                            resourceName:
                                notification.content.resource?.name ?? "",
                        })}
                    </VisuallyHidden>
                </Link>
                <div className="notification__message-container">
                    <div className="notification__message">
                        {userType === UserType.Teacher && (
                            <>
                                <div className="notification__avatar">
                                    {students.length >= 2 && (
                                        <AvatarStack
                                            users={
                                                students.slice(0, 2) as any as [
                                                    StackUser,
                                                    StackUser
                                                ]
                                            }
                                        />
                                    )}
                                    {students.length === 1 && (
                                        <UserAvatar user={students[0]} />
                                    )}
                                </div>
                                {resourceType &&
                                    generateMessage(
                                        notificationType,
                                        resourceType,
                                        intl,
                                        undefined,
                                        students
                                    )}
                            </>
                        )}
                        {userType === UserType.Student && (
                            <>
                                {resourceType && (
                                    <Icon
                                        path={`notifs_${resourceType}`}
                                        size="large"
                                    />
                                )}
                                <p>
                                    {resourceType && name ? (
                                        generateMessage(
                                            notificationType,
                                            resourceType,
                                            intl,
                                            name
                                        )
                                    ) : (
                                        <MiniLoader />
                                    )}
                                </p>
                            </>
                        )}
                    </div>
                    {userType === UserType.Teacher && (
                        <div className="notification__resource">
                            <Icon
                                path={`notifs_${resourceType}`}
                                size="medium"
                            />
                            {!name ? <MiniLoader /> : name}
                        </div>
                    )}
                    <div
                        className={`notification__timestamp --${userType.toLocaleLowerCase()}`}
                    >
                        {relativeTimeFormat(notification.timestamp)}
                    </div>
                </div>
            </div>
            <div className="notification__read-status">
                <TooltipWrapper
                    wrapper="div"
                    tooltip={
                        notification.status === NotificationStatus.READ
                            ? intl.formatMessage(messages.markAsUnread)
                            : intl.formatMessage(messages.markAsRead)
                    }
                >
                    <button
                        className={classNames({
                            "--read":
                                notification.status === NotificationStatus.READ,
                            "--unread":
                                notification.status !== NotificationStatus.READ,
                        })}
                        onClick={() => {
                            const newStatus =
                                notification.status === NotificationStatus.READ
                                    ? NotificationStatus.CREATED
                                    : NotificationStatus.READ;
                            onNotificationReadStatusChange(
                                notification.id,
                                notification.students,
                                newStatus
                            );
                        }}
                    >
                        <VisuallyHidden as="span">
                            {intl.formatMessage(
                                messages[
                                    notification.status ===
                                    NotificationStatus.READ
                                        ? "markAsUnread"
                                        : "markAsRead"
                                ]
                            )}
                        </VisuallyHidden>
                        <Icon
                            className="notification__read-status--read"
                            path="notif_read"
                            size="large"
                            style={{
                                color: "var(--text-main-off)",
                            }}
                        />
                        <Icon
                            className="notification__read-status--read-hover"
                            path="notif_read_hover"
                            size="large"
                            style={{
                                color: "var(--text-main-off)",
                            }}
                        />
                        <Icon
                            className="notification__read-status--unread"
                            path="notif_unread"
                            size="large"
                            style={{
                                color:
                                    userType === UserType.Teacher
                                        ? "var(--element-teacher-default)"
                                        : "var(--element-evidenceb-default)",
                            }}
                        />
                        <Icon
                            className="notification__read-status--unread-hover"
                            path="notif_unread_hover"
                            size="large"
                            style={{
                                color:
                                    userType === UserType.Teacher
                                        ? "var(--element-teacher-default)"
                                        : "var(--element-evidenceb-default)",
                            }}
                        />
                    </button>
                </TooltipWrapper>
            </div>
        </article>
    );
};

export default Notification;

const getResourceName = (type: string, intl: IntlShape) => {
    switch (type) {
        case ResourceType.PLAYLIST:
            return intl.formatMessage(workmodesMessages.PLAYLIST);
        case ResourceType.DUO:
            return intl.formatMessage(workmodesMessages.DUO);
        case ResourceType.WORKSHOP:
            return intl.formatMessage(workmodesMessages.WORKSHOP);
        case ResourceType.ADAPTIVE_SET:
            return intl.formatMessage(
                workmodesMessages.STANDALONE_ADAPTIVE_TEST
            );
        case ResourceType.MODULE:
            return intl.formatMessage(workmodesMessages.SOLO_AI);
        case ResourceType.TUTORIAL:
            return intl.formatMessage(workmodesMessages.TUTO);
    }
};

const generateMessage = (
    notificationType: NotificationType,
    resourceType: ResourceType,
    intl: IntlShape,
    name?: string,
    students?: StudentInfo[]
) => {
    const type = getResourceName(resourceType, intl);

    switch (notificationType) {
        case NotificationType.ASSIGNED:
            return intl.formatMessage(messages.studentMsgResourceReceived, {
                resourceName: name,
                resourceType: type,
            });

        case NotificationType.DONE:
            if (!students || students.length === 0) return;
            else if (students.length >= 3)
                return intl.formatMessage(
                    messages.multipleStudentsResourceCompleted,
                    {
                        students: students.map((s, i) => {
                            if (i <= 1)
                                return `${s.firstname} ${s.lastname}${
                                    i === 0 ? ", " : ""
                                }`;
                        }),
                        otherStudentsCount: students.length - 2,
                        type: type,
                    }
                );
            else if (students.length === 2) {
                return intl.formatMessage(
                    messages.twoStudentsResourceCompleted,
                    {
                        student1: `${students[0].firstname} ${students[0].lastname}`,
                        student2: `${students[1].firstname} ${students[1].lastname}`,
                        type: type,
                    }
                );
            } else
                return intl.formatMessage(
                    messages.oneStudentResourceCompleted,
                    {
                        student: `${students[0].firstname} ${students[0].lastname}`,
                        type: type,
                    }
                );

        default: {
            throw new Error(
                `No message for ${notificationType} type notification`
            );
        }
    }
};

const messages = defineMessages({
    markAsRead: {
        id: "notifications-markAsRead",
        defaultMessage: "Mark as read",
    },
    markAsUnread: {
        id: "notifications-markAsUnread",
        defaultMessage: "Mark as unread",
    },
    studentMsgResourceReceived: {
        id: "notifications-studentMsgResourceReceived",
        defaultMessage: `Your teacher has sent you the "{resourceName}" {resourceType}.`,
    },
    multipleStudentsResourceCompleted: {
        id: "notifications-multipleStudentsResourceCompleted",
        defaultMessage:
            "{students} and {otherStudentsCount} {otherStudentsCount, plural, one {other} other {others}} have completed a {type}",
    },
    twoStudentsResourceCompleted: {
        id: "notifications-twoStudentsResourceCompleted",
        defaultMessage: "{student1} and {student2} have completed a {type}",
    },
    oneStudentResourceCompleted: {
        id: "notifications-oneStudentResourceCompleted",
        defaultMessage: `{student} has completed a {type}`,
    },
    goToResource: {
        id: "notifications-goToResource",
        defaultMessage: "Go to the resource {resourceName}",
    },
});
