import React from "react";
import "./Error.scss";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import classNames from "classnames";

interface Props {
    message: React.JSX.Element;
    icon: string;
    color: string;
    className?: string;
}

const Error = ({ message, icon, color, className }: Props) => {
    return (
        <div
            className={classNames("ds-error", className)}
            style={{ borderLeft: `2px solid ${color}` }}
        >
            <Icon
                className="ds-error__icon"
                path={icon}
                color={color}
                size="medium"
            />
            <p>{message}</p>
        </div>
    );
};

export default Error;
