import React, { useEffect, useReducer, useRef } from "react";
import { Props as NotificationsTabProps } from "./NotificationsTab/NotificationsTab";
import {
    NotificationAggregate,
    NotificationStatus,
    StudentInfo,
} from "./Types";
import { defineMessages, useIntl } from "react-intl";
import { useUserType } from "../../hooks/useUserInfo";
import { UserType } from "../../interfaces/User";
import { commonMessages } from "../../utils/messages";

import { Chip } from "@evidenceb/athena-common/design-system/Chip";
import { deepCompare } from "../../utils/compare";

export enum TabKeys {
    all = "all",
    unread = "unread",
}

type TabProps = {
    tab: {
        key: string;
        title: React.JSX.Element;
    };
    content: NotificationsTabProps;
};

/**
 * Helps to handle which notifications are shown in the unread tab. The rules
 * governing tabs are :
 * - notifications are automatically marked as read when arriving on the unread
 *   tab, but they are kept in the tab
 * - read notification are cleared from the unread tab when leaving the unread
 *   tab
 * - when in the all notifications tab, marking a notification as read or unread
 *   respectively removes or adds them to the unread tab For optimal sync, the
 *   status of the notifications in the unread tab is automatically updated as
 *   the notifications are updated in a useEffect. The reducer handles
 *   add/removing notifications from the list
 */
export const useNotificationsTabs = (
    notifications: NotificationAggregate[],
    notificationStatusChangeHandler: (
        id: string | undefined,
        students: StudentInfo[],
        status: NotificationStatus
    ) => Promise<void>,
    onError: (err: unknown) => void
): {
    [tab in TabKeys]: TabProps;
} & {
    clearReadFromUnreadTab: (opts?: { ignoreCurrentState?: boolean }) => void;
} => {
    const intl = useIntl();
    const userType = useUserType();

    const [notificationsShownInUnread, dispatch] = useReducer(
        notificationsShownInUnreadTabReducer,
        notifications.filter(
            (notification) => notification.status === NotificationStatus.CREATED
        )
    );

    // Keep statuses in sync
    useEffect(() => {
        if (
            notificationsShownInUnread.length > 0 &&
            notifications.length > 0 &&
            notificationsShownInUnread.some((notifInUnread) =>
                notifications.some(
                    (notification) =>
                        notifInUnread.id === notification.id &&
                        notifInUnread.status !== notification.status
                )
            )
        )
            dispatch({
                type: "SYNC_STATUSES",
                notifications,
            });
    }, [notifications, notificationsShownInUnread]);

    // sync if new notifications are obtained from backend
    const notificationsIdsRef = useRef<string[]>(
        notifications.map((notification) => notification.id)
    );
    useEffect(() => {
        const newIds = notifications.map((notification) => notification.id);
        if (!deepCompare(notificationsIdsRef.current, newIds)) {
            const newUnreadNotifications = notifications
                .filter(
                    (notification) =>
                        !notificationsIdsRef.current.includes(notification.id)
                )
                .filter(
                    (notification) =>
                        notification.status === NotificationStatus.CREATED
                );
            if (newUnreadNotifications.length > 0)
                dispatch({
                    type: "RECEIVE_NEW_UNREAD_NOTIFICATIONS",
                    notifications: newUnreadNotifications,
                });
        }
    }, [notifications]);

    return {
        clearReadFromUnreadTab: (opts) => {
            if (opts?.ignoreCurrentState) dispatch({ type: "REMOVE_ALL" });
            dispatch({ type: "REMOVE_ALL_READ" });
        },

        [TabKeys.all]: {
            tab: {
                key: TabKeys.all,
                title: (
                    <span className="notifications-tab-title">
                        {intl.formatMessage(commonMessages.all)}
                    </span>
                ),
            },
            content: {
                notifications,
                emptyMessage: intl.formatMessage(
                    userType === UserType.Teacher
                        ? messages.noNotificationsTeacher
                        : messages.noNotificationsStudent
                ),
                onNotificationReadStatusChange: async (id, student, status) => {
                    try {
                        await notificationStatusChangeHandler(
                            id,
                            student,
                            status
                        );
                        if (status === NotificationStatus.CREATED) {
                            const notification = notifications.find(
                                (notification) => notification.id === id
                            );
                            if (!notification) return;
                            dispatch({
                                type: "ADD_TO_UNREAD_IF_NOT_ALREADY_THERE",
                                notification: { ...notification, status },
                            });
                        } else {
                            if (!id) return;
                            dispatch({
                                type: "REMOVE_FROM_UNREAD_IF_THERE",
                                id,
                            });
                        }
                    } catch (err) {
                        onError(err);
                    }
                },
            },
        },
        [TabKeys.unread]: {
            tab: {
                key: TabKeys.unread,
                title: (
                    <span className="notifications__tab--unread">
                        <span className="notifications-tab-title">
                            {intl.formatMessage(messages.unread)}
                        </span>

                        <Chip
                            className="notifications-tab-unread__nb-chip"
                            input={
                                notifications.filter(
                                    (n) => n.status !== NotificationStatus.READ
                                ).length
                            }
                        />
                    </span>
                ),
            },
            content: {
                emptyMessage: intl.formatMessage(messages.noUnread),
                notifications: notificationsShownInUnread,
                onNotificationReadStatusChange: async (
                    id,
                    students,
                    status
                ) => {
                    try {
                        await notificationStatusChangeHandler(
                            id,
                            students,
                            status
                        );
                        if (!id) return;
                        if (status === NotificationStatus.READ) {
                            dispatch({
                                type: "REMOVE_FROM_UNREAD_IF_THERE",
                                id,
                            });
                        }
                    } catch (err) {
                        onError(err);
                    }
                },
            },
        },
    };
};

type NotificationsShownInUnreadTabActions =
    | {
          type: "SYNC_STATUSES";
          notifications: NotificationAggregate[];
      }
    | {
          type: "ADD_TO_UNREAD_IF_NOT_ALREADY_THERE";
          notification: NotificationAggregate;
      }
    | {
          type: "REMOVE_FROM_UNREAD_IF_THERE";
          id: string;
      }
    | {
          type: "RECEIVE_NEW_UNREAD_NOTIFICATIONS";
          notifications: NotificationAggregate[];
      }
    | {
          type: "REMOVE_ALL_READ";
      }
    | {
          type: "REMOVE_ALL";
      };
const notificationsShownInUnreadTabReducer = (
    state: NotificationAggregate[],
    action: NotificationsShownInUnreadTabActions
): NotificationAggregate[] => {
    switch (action.type) {
        case "SYNC_STATUSES": {
            return state.map((notifInUnread) => {
                const correspondingNotification = action.notifications.find(
                    (notification) => notifInUnread.id === notification.id
                );
                if (correspondingNotification?.status !== notifInUnread.status)
                    return {
                        ...notifInUnread,
                        status: correspondingNotification!.status,
                    };
                return notifInUnread;
            });
        }

        case "ADD_TO_UNREAD_IF_NOT_ALREADY_THERE": {
            const notificationAlreadyThere = state.find(
                (notification) => notification.id === action.notification.id
            );
            if (notificationAlreadyThere) {
                if (
                    notificationAlreadyThere.status ===
                    action.notification.status
                )
                    return state;
                return state.map((notification) => {
                    if (notification.id === action.notification.id)
                        return action.notification;
                    return notification;
                });
            }
            return [action.notification, ...state];
        }

        case "REMOVE_FROM_UNREAD_IF_THERE": {
            if (state.some((notification) => notification.id === action.id))
                return state.filter(
                    (notification) => notification.id !== action.id
                );
            return state;
        }

        case "RECEIVE_NEW_UNREAD_NOTIFICATIONS": {
            const actuallyNew = action.notifications.filter((notification) =>
                state.every(
                    (existingNotification) =>
                        existingNotification.id !== notification.id
                )
            );
            if (actuallyNew.length > 0) return [...actuallyNew, ...state];
            return state;
        }

        case "REMOVE_ALL_READ": {
            if (
                state.some(
                    (notification) =>
                        notification.status === NotificationStatus.READ
                )
            )
                return state.filter(
                    (notification) =>
                        notification.status === NotificationStatus.CREATED
                );
            return state;
        }

        case "REMOVE_ALL":
            return [];

        default: {
            const _: never = action;
            throw new Error(`Action type ${action} doesn't exist`);
        }
    }
};

const messages = defineMessages({
    noNotificationsTeacher: {
        id: "notification-noNotificationsTeacher",
        defaultMessage:
            "You will recieve notifications on the progress on your students",
    },
    noNotificationsStudent: {
        id: "notification-noNotificationsStudent",
        defaultMessage: "You don't have any notifications at the moment",
    },
    unread: {
        id: "notifications-unread",
        defaultMessage: "unread",
    },
    noUnread: {
        id: "notification-noUnread",
        defaultMessage: "You don't have any unread notifications",
    },
});
