import React, { createContext, Dispatch } from "react";
import { LearningAssetsCacheState, initialState } from "./LearningAssets/state";
import { LearningAssetsCacheAction } from "./LearningAssets/action";
import { LearningAssetsCacheReducerWithMiddleware } from "./LearningAssets/redux";

interface LearningAssetsCacheContext {
    learningAssetsCacheState: LearningAssetsCacheState;
    dispatchLearningAssetsCacheAction: Dispatch<LearningAssetsCacheAction>;
}

const learningAssetsCacheStore = createContext<LearningAssetsCacheContext>({
    learningAssetsCacheState: initialState,
    dispatchLearningAssetsCacheAction: () => null,
});

const { Provider } = learningAssetsCacheStore;
const LearningAssetsCacheProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [learningAssetsCacheState, dispatchCacheAction] =
        LearningAssetsCacheReducerWithMiddleware();

    const value: LearningAssetsCacheContext = {
        learningAssetsCacheState:
            learningAssetsCacheState as LearningAssetsCacheState,
        dispatchLearningAssetsCacheAction:
            dispatchCacheAction as React.Dispatch<LearningAssetsCacheAction>,
    };
    return <Provider value={value}>{children}</Provider>;
};

export { learningAssetsCacheStore, LearningAssetsCacheProvider };
