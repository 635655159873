export interface CacheState {
    modules: Array<ModuleStatus>;
    assetsUrl: string;
    version: string;
    currentTask: CacheTask | undefined;
}

export interface CacheTask {
    abortController: AbortController;
    cacheType: "module" | "objective";
    id: string;
}

export type ModuleStatus = {
    id: string;
    status: CacheStatus;
    objectives: Array<ObjectiveStatus>;
};

export type ObjectiveStatus = {
    id: string;
    status: CacheStatus;
    exercices: Array<ExerciceStatus>;
};

export type ExerciceStatus = {
    id: string;
    status: CacheStatus;
    assets: Array<AssetStatus>;
};

export type AssetStatus = {
    url: string;
    status: CacheStatus;
};

export type CacheStatus = "downloaded" | "not_downloaded";

export const initialState: CacheState = {
    modules: new Array<ModuleStatus>(),
    assetsUrl: "",
    version: "",
    currentTask: undefined,
};
