import appConfig from "../config";
import { filterDefined } from "./array-manipulations";

/**
 * Returns true if the environment is not clearly a preprod or local or tempdev environment
 * Not 100% accurate especially for speciifc preprod urls
 */
export const isProductionEnvironment = (hostname: string) => {
    if (
        hostname.includes("localhost") ||
        hostname.includes("preprod") ||
        hostname.includes("tempdev") ||
        hostname.includes("qa")
    )
        return false;
    return true;
};

export const isLocal = (hostname: string) => {
    if (hostname.includes("localhost") || hostname.includes("localtest.me"))
        return true;
    return false;
};

/**
 * Returns the host with the basepath
 */
export const getHost = () => {
    return [window.location.host, appConfig.basePath]
        .filter(filterDefined)
        .join("/");
};
