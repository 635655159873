import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { getLocalStorageFlags } from "./utils/flags";

let matomoInstance;

if (process.env.NODE_ENV === "production") {
    if (window.location.hostname.endsWith("miaseconde.fr")) {
        // miaseconde is used in a more restricted environment that has its own matomo
        matomoInstance = createInstance({
            urlBase:
                "https://rufwgpchskepjzgwuclh-matomo.services.clever-cloud.com/",
            siteId: 5,
            linkTracking: false,
        });
    } else {
        matomoInstance = createInstance({
            urlBase: "https://charlemagne.evidenceb-services.com",
            siteId: 1,
            linkTracking: false,
        });
    }
}

const rootElement = document.getElementById("root");
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
        <>
            {typeof matomoInstance === "undefined" ? (
                <App />
            ) : (
                <MatomoProvider value={matomoInstance}>
                    <App />
                </MatomoProvider>
            )}
        </>
    );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const offlineFeatures = !!getLocalStorageFlags()["offlineFeatures"];
if (offlineFeatures) {
    serviceWorkerRegistration.register();
} else {
    serviceWorkerRegistration.unregister();
}

// TEMP: See https://github.com/EvidenceB/evb_application/pull/1695 This
// localStorage key used to be used for storing statements but the
// implementation was flawed and caused the localStorage to overflow. This is to
// make sure we clear the storage entry for users who might have a corrupted
// localStorage
localStorage.removeItem("unsync_statements");
