import { useContext, useMemo } from "react";
import {
    RawNotification,
    NotificationStatus,
    NotificationType,
} from "../Types";
import { UserType } from "../../../interfaces/User";
import * as localStorage from "../../../utils/localStorage";
import { axiosClient } from "../../../utils/axios-client/axios-client";
import { configStore } from "../../../contexts/ConfigContext";

const useNotificationsApiClient = () => {
    const {
        config: { apiUrls },
    } = useContext(configStore);

    const notificationsApiClient = useMemo(() => {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem<string>(
                localStorage.Key.TOKEN
            )}`,
            "Content-Type": "application/json",
        };

        return {
            async getNotifications(
                userId: string,
                userType: UserType
            ): Promise<RawNotification[]> {
                const res = await axiosClient.axios.get(
                    `${
                        apiUrls.endpoints.users
                    }/api/notifications/?${userType.toLowerCase()}=${userId}`,
                    {
                        headers: headers,
                    }
                );
                return res.data;
            },
            async updateNotification(
                id: string,
                status: NotificationStatus
            ): Promise<RawNotification> {
                const res = await axiosClient.axios.patch(
                    `${apiUrls.endpoints.users}/api/notifications/${id}/`,
                    { status: status },
                    {
                        headers: headers,
                    }
                );
                return res.data;
            },
            async notifyResourceCompleted(
                resourceId: string,
                studentId: string,
                notificationType: NotificationType,
                teacherId: string
            ): Promise<RawNotification> {
                const headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "Content-Type": "application/json",
                };
                const res = await axiosClient.axios.post(
                    `${apiUrls.endpoints.users}/notify-resource-completed/`,
                    {
                        notification_type: notificationType,
                        notified_content: {
                            resource_id: resourceId,
                            student_id: studentId,
                        },
                        notified_object: teacherId,
                    },
                    {
                        headers: headers,
                    }
                );
                return res.data;
            },
        };
    }, [apiUrls]);

    return notificationsApiClient;
};

export default useNotificationsApiClient;
