import { useMutation } from "@tanstack/react-query";
import { NotificationStatus } from "../Types";
import useNotificationsApiClient from "./useNotificationsApiClient";

export const useNotificationStatusMutation = () => {
    const { updateNotification } = useNotificationsApiClient();
    return useMutation(
        (data: { notificationId: string; status: NotificationStatus }) =>
            updateNotification(data.notificationId, data.status)
    );
};
