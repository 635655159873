export enum CacheNames {
    config = "config",
    userInfo = "user-info",
    userStudent = "student",
    userTeacher = "teacher",
    data = "questions",
    statements = "statements",
    mappingTree = "mapping-tree",
    learningSets = "learningSets",
}

export const updateCache = (
    cacheName: CacheNames,
    updateRequest: () => void
) => {
    caches.delete(cacheName);
    try {
        updateRequest();
    } catch {
        // ignore
    }
};
