export const mediumBreakpoint = () => {
    const breakpointMedium = getComputedStyle(document.body).getPropertyValue(
        "--breakpoint-medium"
    );
    return +breakpointMedium.replace("px", "");
};

export const largeBreakpoint = () => {
    const breakpointLarge = getComputedStyle(document.body).getPropertyValue(
        "--breakpoint-large"
    );
    return +breakpointLarge.replace("px", "");
};

export const isMobileView = () => {
    return window.innerWidth < mediumBreakpoint();
};

export const isTabletView = () => {
    const width = window.innerWidth;
    return width >= mediumBreakpoint() && width < largeBreakpoint();
};

export const isDesktopView = () => {
    return window.innerWidth >= largeBreakpoint();
};
