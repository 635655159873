import { useCallback, useContext } from "react";
import { sessionStore } from "../../contexts/SessionContext";
import useAthenaAPIClient from "./useAthenaAPIClient/useAthenaAPIClient";
import Session from "../../interfaces/Session";

export const useUserExtra = () => {
    const {
        session: { extra },
        setSession,
    } = useContext(sessionStore);
    const { updateUserExtra } = useAthenaAPIClient();
    const updateExtra = useCallback(
        async (
            extraPart: Partial<Session["extra"]>,
            opts?: { ignore: boolean }
        ) => {
            await updateUserExtra({ ...extra, ...extraPart });
            if (!opts?.ignore) {
                setSession((curr) => ({
                    ...curr,
                    extra: { ...curr.extra, ...extraPart },
                }));
            }
        },
        [updateUserExtra, setSession, extra]
    );
    return [extra, updateExtra] as const;
};
