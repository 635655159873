export const joinPath = (...parts: string[]): string => {
    const firstPartHasBeginningSlash = parts[0].startsWith("/");
    const prefix = firstPartHasBeginningSlash ? "/" : "";
    return (
        prefix +
        parts
            .map(stripBeginningAndEndSlash)
            .filter((s) => s)
            .join("/")
    );
};

const stripBeginningAndEndSlash = (path: string): string => {
    if (path.startsWith("/")) {
        path = path.slice(1);
    }
    if (path.endsWith("/")) {
        path = path.slice(0, -1);
    }
    return path;
};
