import { AILoadingInfo } from "@evidenceb/ai-handler";
import { PRLockStatus } from "../interfaces/PedagogicalResourcesManagement";

/**
 * Adds the required keys in case the locked status obtained from the backends
 * lacks them
 */
export const completeLockStatus = (
    partial: Partial<PRLockStatus> | undefined
): PRLockStatus => {
    const completeDeactivations: PRLockStatus = {
        moduleIds: partial?.moduleIds ?? [],
        objectiveIds: partial?.objectiveIds ?? [],
        activityIds: partial?.activityIds ?? [],
    };
    return completeDeactivations;
};

/**
 * Returns true if the ai has pedagogical resources locking capabilities
 */
export const isAIWithLockStatus = (ai: AILoadingInfo): boolean => {
    return !ai.error && !!ai.instance.lock;
};
