import { VisuallyHidden } from "@evidenceb/athena-common/ui/VisuallyHidden";
import { useFocusRing } from "@react-aria/focus";
import { AriaRadioProps, useRadio, useRadioGroup } from "@react-aria/radio";
import { mergeProps } from "@react-aria/utils";
import React, {
    CSSProperties,
    PropsWithChildren,
    createContext,
    useContext,
    useRef,
} from "react";
import {
    RadioGroupProps,
    RadioGroupState,
    useRadioGroupState,
} from "react-stately";
import cn from "classnames";

import "./SegmentedControl.scss";

type Props = PropsWithChildren<
    {
        /** @default "primary" */
        variant?: "primary" | "secondary";
        color?: string;
        className?: string;
    } & RadioGroupProps
>;

const RadioContext = createContext<RadioGroupState>({} as RadioGroupState);

export const SegmentedControl = ({
    variant = "primary",
    color,
    className,
    children,
    ...props
}: Props) => {
    const state = useRadioGroupState(props);
    const { radioGroupProps } = useRadioGroup(
        {
            ...props,
            orientation: "horizontal",
        },
        state
    );

    return (
        <div
            {...radioGroupProps}
            className={cn(
                `ds-segmented-control ds-segmented-control--${variant}`,
                className
            )}
            style={
                {
                    "--custom-background-color": color,
                } as CSSProperties
            }
        >
            <RadioContext.Provider value={state}>
                {children}
            </RadioContext.Provider>
        </div>
    );
};

export const Control = (props: AriaRadioProps) => {
    const state = useContext(RadioContext);
    const ref = useRef<HTMLInputElement>(null);
    const { inputProps } = useRadio(props, state, ref);
    const { focusProps, isFocused: focused } = useFocusRing();
    const selected = state?.selectedValue === props.value;

    return (
        <label
            className={cn("ds-segmented-control__button", {
                "ds-segmented-control__button--selected": selected,
                "ds-segmented-control__button--focused": focused,
            })}
        >
            <VisuallyHidden>
                <input {...mergeProps(inputProps, focusProps)} ref={ref} />
            </VisuallyHidden>
            {props.children}
        </label>
    );
};
