import React from "react";
import cn from "classnames";

import "./VisuallyHidden.scss";

interface Props {
    /** @default "div" */
    as?: "div" | "span";
}

const VisuallyHidden = ({
    as,
    children,
    className,
    ...props
}: Props &
    React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >) => {
    const Container = as ?? "div";

    return (
        <Container className={cn("visually-hidden", className)} {...props}>
            {children}
        </Container>
    );
};

export default VisuallyHidden;
