export type CachedVersion = number | null;

export interface LearningAssetsCacheState {
    learningSetState: Map<String, CachedVersion>;
    baseAssetsUrl: string;
    resourcesEndpoint: string;
    version: string;
    currentDownloadTask: CurrentDownloadTask | undefined;
}

export interface CurrentDownloadTask {
    abortController: AbortController;
    id: string;
}

export interface LearningItemState {
    id: string;
    status: CachedVersion;
    assets: AssetState[];
}

export interface AssetState {
    url: string;
    status: CachedVersion;
}

export const initialState: LearningAssetsCacheState = {
    learningSetState: new Map(),
    baseAssetsUrl: "",
    resourcesEndpoint: "",
    version: "",
    currentDownloadTask: undefined,
};
