import { useContext, useEffect, useReducer } from "react";
import { axiosClient } from "../../utils/axios-client/axios-client";
import { configStore } from "../../contexts/ConfigContext";
import { errorStore } from "../../contexts/ErrorContext";
import { LoaderStatus } from "../../interfaces/Status";
import useSetInvalidatedTokenErrorModal from "../../utils/axios-client/useSetInvalidatedTokenErrorModal";

const useAxiosClientInit = (appStatus: LoaderStatus | undefined): boolean => {
    const { setConfig } = useContext(configStore);
    const { setErrorInfo } = useContext(errorStore);
    const setInvalidatedErrorModal = useSetInvalidatedTokenErrorModal();

    const [axiosClientInitialized, markAxiosClientInitialized] = useReducer(
        () => true,
        false
    );
    useEffect(() => {
        (async () => {
            const apiUrls = await axiosClient.init();
            setConfig((config) => {
                return { ...config, apiUrls };
            });
            markAxiosClientInitialized();
        })();
    }, [setConfig]);

    const [
        tokenInvalidInterceptorInitialized,
        markTokenInvalidInterceptorInitialized,
    ] = useReducer(() => true, false);
    useEffect(() => {
        if (!axiosClientInitialized) return;

        // Add an interceptor that handles invalidated token errors. While the
        // app is loading, it displays an error page. When the app is loaded, it
        // displays a modal prompting the user to log back in.
        // See `useDelayInvalidatedTokenModal` for specific usage in the player
        if (appStatus !== LoaderStatus.Success) {
            axiosClient.updateTokenInvalidatedInterceptor(() => {
                setErrorInfo({
                    displayModal: false,
                    page: {
                        code: "token",
                    },
                });
            });
        } else {
            axiosClient.updateTokenInvalidatedInterceptor(() =>
                setInvalidatedErrorModal()
            );
        }
        markTokenInvalidInterceptorInitialized();
    }, [
        appStatus,
        setInvalidatedErrorModal,
        setErrorInfo,
        axiosClientInitialized,
    ]);

    return axiosClientInitialized && tokenInvalidInterceptorInitialized;
};
export default useAxiosClientInit;
