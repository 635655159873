import { VERSION_PREFIXES_FOR_NEW_RESOURCE_SYSTEM } from "./const";

export const isTokenInvalidatedError = (error: any): boolean => {
    if (
        error?.response?.data?.detail?.message ===
        "User session was invalidated."
    ) {
        return true;
    } else {
        return false;
    }
};

export const isNewResourcesSystem = (version: string): boolean => {
    return VERSION_PREFIXES_FOR_NEW_RESOURCE_SYSTEM.some((prefix) =>
        version.startsWith(prefix)
    );
};
