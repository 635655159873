import { useCallback, useEffect, useMemo, useState } from "react";
import useDefault from "./useDefault";
import useSwipe from "./ui/useSwipe";

const THRESHOLD = 10;

type ScrollDirection = "up" | "down" | null;

export type Opts = {
    /**
     * Use mousewheel and touch events rather than scroll events to make sure to
     * detect the user movement despite overflow being actually present in the
     * element
     * @default false
     */
    forceScrollDetection: boolean;
};
export const defaultOpts: Opts = {
    forceScrollDetection: false,
};

const useScrollDirection = (
    /**
     * Element on which the scroll listener should be applies
     * @default window
     */
    scrollElem: HTMLElement | Window = window,
    opts?: Partial<Opts>
) => {
    const { forceScrollDetection } = useDefault(opts, defaultOpts);

    const [scrollDirection, setScrollDirection] =
        useState<ScrollDirection>(null);

    useEffect(() => {
        if (forceScrollDetection || !scrollElem) return;

        let lastScrollY = getScrollY(scrollElem);
        let lastHeight = getHeight(scrollElem);
        const updateScrollDirection = () => {
            const height = getHeight(scrollElem);
            if (height !== lastHeight) {
                // Ignore height change that may be caused by height changeParam
                lastHeight = height;
                return;
            }

            const scrollY =
                "scrollY" in scrollElem
                    ? scrollElem.scrollY
                    : scrollElem?.scrollTop;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (
                scrollY - lastScrollY > THRESHOLD ||
                scrollY - lastScrollY < -THRESHOLD
            ) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        scrollElem?.addEventListener("scroll", updateScrollDirection);
        return () => {
            scrollElem?.removeEventListener("scroll", updateScrollDirection);
        };
    }, [scrollElem, forceScrollDetection]);

    // When forceScrollDetection is true, use touch and mousewheel events instead
    const onSwipeUp = useCallback(() => setScrollDirection("up"), []);
    const onSwipeDown = useCallback(() => setScrollDirection("down"), []);
    useSwipe({
        container:
            scrollElem === window
                ? undefined
                : { current: scrollElem as HTMLElement },
        onSwipeUp: forceScrollDetection ? onSwipeUp : undefined,
        onSwipeDown: forceScrollDetection ? onSwipeDown : undefined,
    });
    useEffect(() => {
        if (!forceScrollDetection || !scrollElem) return;

        const handleWheelEvent = (e: WheelEvent) => {
            if (e?.deltaY > THRESHOLD) {
                setScrollDirection("down");
            } else if (e?.deltaY < -THRESHOLD) {
                setScrollDirection("up");
            }
        };
        // @ts-ignore
        scrollElem?.addEventListener("wheel", handleWheelEvent);
        return () => {
            // @ts-ignore
            scrollElem?.removeEventListener("wheel", handleWheelEvent);
        };
    }, [forceScrollDetection, scrollElem]);

    return useMemo(
        () => ({
            direction: scrollDirection,
            reset: () => setScrollDirection(null),
        }),
        [scrollDirection]
    );
};
export default useScrollDirection;

const getScrollY = (elem: HTMLElement | Window) =>
    "scrollY" in elem ? elem.scrollY : elem?.scrollTop;
const getHeight = (elem: HTMLElement | Window) =>
    "clientHeight" in elem ? elem.clientHeight : elem.innerHeight;
