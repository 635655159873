import { Agent, Statement } from "@xapi/xapi";
import isArray from "lodash/isArray";
import mergeWith from "lodash/mergeWith";
import { v4 as uuid } from "uuid";
import { Emotion } from "../../interfaces/EmotionalReport";
import { XAPI_REGISTRY } from "./const";
import { HistoryStatementInfo, WorkmodesWithStatements } from "./types";
import { AIType } from "@evidenceb/ai-handler";
import {
    makeAIStatementParts,
    makeActivityVideoTutorialStatementParts,
    makeAdaptiveTestDiagnosisStatementParts,
    makeEmotionalStatementParts,
    makeEntryLearningSetStatementParts,
    makeExerciseResultStatementParts,
    makeInternalResourceViewedStatementParts,
    makeLoggedInStatementParts,
    makeRecommendationsClickStatementParts,
    makeStandaloneAdaptiveTestStatementParts,
    makeWorkmodeStatementParts,
} from "./statement-parts";
import { AI_IDS } from "../ai";
import { WorkModeType } from "../../interfaces/Config";

export const getCurrentAgent = (
    declinaison: string,
    evidencebId: string
): Agent => {
    return {
        account: {
            name: evidencebId,
            homePage: `${XAPI_REGISTRY}/homepages/${declinaison}`,
        },
        objectType: "Agent",
    };
};

const makeStatement = (
    variation: string,
    evidencebId: string,
    sessionId: string,
    ...statementParts: Partial<Statement>[]
): Statement => {
    if (
        !statementParts.some((part) => typeof part.verb !== "undefined") ||
        !statementParts.some((part) => typeof part.object !== "undefined")
    )
        throw new Error("Incomplete statement");

    const statementBasis: Partial<Statement> = {
        timestamp: new Date().toISOString(),
        id: uuid(),
        actor: getCurrentAgent(variation, evidencebId),
        context: {
            registration: sessionId,
        },
    };

    return mergeWith(
        {},
        statementBasis,
        ...statementParts,
        (objValue: any, srcValue: any) => {
            if (isArray(objValue) && isArray(srcValue))
                return [...objValue, ...srcValue];
        }
    );
};

export const makeEmotionalReportStatement = (
    variation: string,
    evidencebId: string,
    emotion: Emotion,
    sessionId: string,
    reportType: "FORCED" | "SELF-REPORT"
): Statement => {
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        makeEmotionalStatementParts(emotion, reportType)
    );
};

export const makeLoggedInStatement = (
    variation: string,
    evidencebId: string,
    sessionId: string
): Statement => {
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        makeLoggedInStatementParts(variation)
    );
};

export const makeRecommendationsClickStatement = (
    variation: string,
    evidencebId: string,
    linkUrl: string,
    sessionId: string,
    moduleId: string
): Statement => {
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        makeRecommendationsClickStatementParts(linkUrl, moduleId)
    );
};

export const makeAdaptiveTestDiagnosisStatement = (
    variation: string,
    evidencebId: string,
    sessionId: string,
    moduleId: string,
    adaptiveTestNumber: number | undefined,
    diagnosis: any,
    /**
     * This is not used with the standalone adaptive test (MaSpeMath)
     */
    workmode?: WorkmodesWithStatements
): Statement => {
    const aiType =
        workmode === WorkModeType.SoloAI
            ? AIType.AdaptiveTest
            : AIType.CNEDAdaptiveTest;
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        makeAIStatementParts(AI_IDS[aiType](), AIType.AdaptiveTest),
        workmode ? makeWorkmodeStatementParts(workmode) : {},
        adaptiveTestNumber
            ? makeStandaloneAdaptiveTestStatementParts(adaptiveTestNumber)
            : {},
        makeAdaptiveTestDiagnosisStatementParts(moduleId, diagnosis)
    );
};

export const makeHistoryStatement = ({
    historyItem,
    sessionId,
    evidencebId,
    variation,
    aiId,
    workmode,
    aiType,
    adaptiveTestNumber,
    entryLearningSetId,
}: HistoryStatementInfo): Statement => {
    if (adaptiveTestNumber) aiType = AIType.AdaptiveTest;
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        aiId ? makeAIStatementParts(aiId, aiType) : {},
        makeExerciseResultStatementParts(historyItem),
        workmode ? makeWorkmodeStatementParts(workmode) : {},
        adaptiveTestNumber
            ? makeStandaloneAdaptiveTestStatementParts(adaptiveTestNumber)
            : {},
        makeEntryLearningSetStatementParts(entryLearningSetId)
    );
};

export const makeActivityVideoTutorialWatchedStatement = (
    variation: string,
    evidencebId: string,
    sessionId: string,
    videoUrl: string,
    activityId: string,
    viewingType: "voluntary" | "compulsory"
): Statement => {
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        makeInternalResourceViewedStatementParts(viewingType),
        makeActivityVideoTutorialStatementParts(videoUrl, activityId)
    );
};
