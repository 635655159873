import React, { CSSProperties } from "react";

import "./MiniLoader.scss";

interface Props {
    size?: number;
    borderWidth?: number;
    color?: string;
}

const MiniLoader = ({ size = 24, borderWidth = 3, color }: Props) => {
    return (
        <span
            className="evb-spinner--small"
            style={
                {
                    "--evb-spinner--small-size": size + "px",
                    "--evb-spinner--small-border-width": borderWidth + "px",
                    "--evb-spinner--small-color": color,
                } as CSSProperties
            }
        >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </span>
    );
};
export default MiniLoader;
