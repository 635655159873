import { defineMessages } from "react-intl";
import { WorkMode, WorkModeType } from "../../interfaces/Config";
import { UserType } from "../../interfaces/User";
import { hasWorkmode } from "../../hooks/useWorkmode";

export const workmodesMessages = defineMessages({
    [WorkModeType.Duo]: {
        id: "mode-duo",
        defaultMessage: "Duo",
    },
    [WorkModeType.SoloAI]: {
        id: "mode-solo-ai",
        defaultMessage: "Solo AI",
    },
    [WorkModeType.Revision]: {
        id: "mode-revision",
        defaultMessage: "Revision",
    },
    [WorkModeType.Playlist]: {
        id: "mode-teacher",
        defaultMessage: "Playlist",
    },
    [WorkModeType.Test]: {
        id: "mode-test",
        defaultMessage: "Test",
    },
    [WorkModeType.Tuto]: {
        id: "mode-tuto",
        defaultMessage: "Tuto",
    },
    [WorkModeType.Workshop]: {
        id: "mode-workshop",
        defaultMessage: "Workshop",
    },
    [WorkModeType.StandaloneAdaptiveTest]: {
        id: "mode-standaloneAdaptiveTest",
        defaultMessage: "Adaptive test",
    },
});
export const workmodeDescriptionKey = (
    workmode: WorkModeType,
    userType: UserType
) => {
    return workmode + "-" + userType;
};
export const workmodesDescriptions = defineMessages({
    [workmodeDescriptionKey(WorkModeType.Duo, UserType.Teacher)]: {
        id: "mode-duo-teacherDesc",
        defaultMessage:
            "Form pairs of students so that they collaborate together on a resource",
    },
    [workmodeDescriptionKey(WorkModeType.SoloAI, UserType.Teacher)]: {
        id: "mode-solo-ai-teacherDesc",
        defaultMessage:
            "Explore the modules that will be automatically personalized by an Artificial Intelligence (AI) algorithm for each of your students",
    },
    [workmodeDescriptionKey(WorkModeType.Playlist, UserType.Teacher)]: {
        id: "mode-playlists-teacherDesc",
        defaultMessage:
            "Compile and order the resources of your choice within a compilation and share it with your students",
    },
    [workmodeDescriptionKey(WorkModeType.Test, UserType.Teacher)]: {
        id: "mode-test-teacherDesc",
        defaultMessage: "Test",
    },
    [workmodeDescriptionKey(WorkModeType.Tuto, UserType.Teacher)]: {
        id: "mode-tuto-teacherDesc",
        defaultMessage:
            "Browse the catalog of audio and video resources made available to your students",
    },
    [workmodeDescriptionKey(WorkModeType.Workshop, UserType.Teacher)]: {
        id: "mode-workshop-teacherDesc",
        defaultMessage:
            "Browse the catalog of collaborative workshops to run in class or to carry out online",
    },
    [workmodeDescriptionKey(
        WorkModeType.StandaloneAdaptiveTest,
        UserType.Teacher
    )]: {
        id: "mode-standaloneAdaptiveTest-teacherDesc",
        defaultMessage: "Adaptive test",
    },
    [workmodeDescriptionKey(WorkModeType.Duo, UserType.Student)]: {
        id: "mode-duo-studentDesc",
        defaultMessage: "Collaborate on a resource with your partner",
    },
    [workmodeDescriptionKey(WorkModeType.SoloAI, UserType.Student)]: {
        id: "mode-solo-ai-studentDesc",
        defaultMessage:
            "Learn at your own pace thanks to an Artificial Intelligence (AI) algorithm",
    },
    [workmodeDescriptionKey(WorkModeType.Playlist, UserType.Student)]: {
        id: "mode-playlists-studentDesc",
        defaultMessage:
            "Learn thanks to the compilations created by your teacher",
    },
    [workmodeDescriptionKey(WorkModeType.Test, UserType.Student)]: {
        id: "mode-test-studentDesc",
        defaultMessage: "Test",
    },
    [workmodeDescriptionKey(WorkModeType.Tuto, UserType.Student)]: {
        id: "mode-tuto-studentDesc",
        defaultMessage: "Learn with audio and video resources",
    },
    [workmodeDescriptionKey(WorkModeType.Workshop, UserType.Student)]: {
        id: "mode-workshop-studentDesc",
        defaultMessage: "Participate in workshops led by your teacher",
    },
    [workmodeDescriptionKey(
        WorkModeType.StandaloneAdaptiveTest,
        UserType.Student
    )]: {
        id: "mode-standaloneAdaptiveTest-studentDesc",
        defaultMessage: "Adaptive test",
    },
});

export const teacherWhenNoStudentSoloAi = defineMessages({
    name: {
        id: "mode-soloAi-teacherWhenNoStudentSoloAi-name",
        defaultMessage: "Modules",
    },
    description: {
        id: "mode-soloAi-teacherWhenNoStudentSoloAi-description",
        defaultMessage: "Explore the modules",
    },
});

export const getWorkmodeNaming = (mode: WorkMode, userType: UserType) => {
    if (!mode) {
        return {
            descriptionMessage:
                workmodesDescriptions[
                    workmodeDescriptionKey(WorkModeType.SoloAI, userType)
                ],
            nameMessage: workmodesMessages[WorkModeType.SoloAI],
        };
    }
    if (isTeacherWhenNoStudentSoloAi(mode, userType)) {
        return {
            descriptionMessage: teacherWhenNoStudentSoloAi.description,
            nameMessage: teacherWhenNoStudentSoloAi.name,
        };
    } else {
        return {
            descriptionMessage:
                workmodesDescriptions[
                    workmodeDescriptionKey(mode.type, userType)
                ],
            nameMessage: workmodesMessages[mode.type],
        };
    }
};

export const isTeacherWhenNoStudentSoloAi = (
    mode: WorkMode | undefined,
    userType: UserType
) => {
    return (
        mode?.type === WorkModeType.SoloAI &&
        userType === UserType.Teacher &&
        !hasWorkmode(UserType.Student, mode)
    );
};
