import { PlaylistableResources } from "./EditPlaylist/usePlaylistableResources";

export const ROUTES = {
    create: "create",
    edit: "edit",
    play: "play",
    editAssignment: "edit-recipients",
};

export const CURRENT_RESOURCE_PARTS = [
    "resourceType",
    "resourceId1",
    "resourceId2",
    "resourceId3",
    "resourceId4",
] as const;
export const CURRENT_RESOURCE_PARAMS = CURRENT_RESOURCE_PARTS.map(
    (part) => `:${part}?`
).join("/");
export type CreateURLParams = {
    resourceType?: PlaylistableResources;
    resourceId1?: string;
    resourceId2?: string;
    resourceId3?: string;
    resourceId4?: string;
};

export const TITLE_MAX_LEN = 50;
export const DESCRIPTION_MAX_LEN = 330;
