import React, { useRef } from "react";
import { AriaButtonProps, useButton } from "@react-aria/button";

const AccessibleButton = React.forwardRef<
    HTMLButtonElement,
    React.HTMLProps<HTMLButtonElement> & AriaButtonProps<"button">
>((props, ref) => {
    if (typeof ref !== "object") {
        throw new Error("This component may only be used with an object ref");
    }
    let fallbackRef = useRef<HTMLButtonElement>(null);
    let domRef = ref ?? fallbackRef;

    let { buttonProps } = useButton(props, domRef);
    return (
        <button
            {...buttonProps}
            ref={domRef}
            className={props.className}
            onKeyDown={(e) => e.stopPropagation()}
        >
            {props.children}
        </button>
    );
});

export default AccessibleButton;
