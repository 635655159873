import { useMutation } from "@tanstack/react-query";
import { useUserExtra } from "../../../../hooks/queries/useUserExtra";

export const useSubjectChoice = () => {
    const [extra, updateExtra] = useUserExtra();
    const mutation = useMutation((subjectId: string) =>
        updateExtra({ activeSubject: subjectId })
    );
    return [extra.activeSubject, mutation] as const;
};
