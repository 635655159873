import React from "react";
import FeedbackQuestion from "./FeedbackQuestion";
import "./SingleChoiceQuestion.scss";

interface Props {
    question: FeedbackQuestion;
    value: string | null;
    onChange: (newValue: string) => void;
}

const SingleChoiceQuestion = ({ question, value, onChange }: Props) => {
    return (
        <fieldset className="motivational-message__single-choice-question">
            {question.possibleAnswers.map((possibleAnswer) => (
                <div key={possibleAnswer}>
                    <label>
                        <input
                            type="radio"
                            value={possibleAnswer}
                            checked={value === possibleAnswer}
                            onChange={() => onChange(possibleAnswer)}
                        />
                        {possibleAnswer}
                    </label>
                </div>
            ))}
        </fieldset>
    );
};
export default SingleChoiceQuestion;
