import React, {
    DetailedHTMLProps,
    HTMLAttributes,
    PropsWithChildren,
} from "react";
import cn from "classnames";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";

import "./TriStateCheckbox.scss";

// React-aria doesn't seme to have a tri-state checkbox
// A11y reference: https://www.w3.org/WAI/ARIA/apg/patterns/checkbox/

type BaseProps = Required<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>;
export type Props = PropsWithChildren<{
    id?: BaseProps["id"];
    className?: BaseProps["className"];
    checked: BaseProps["aria-checked"];
    "aria-controls"?: BaseProps["aria-controls"];
    onChange?: () => void;
    /** @default "default" */
    variant?: "default" | "inverted";
}>;

const TriStateCheckbox = ({
    children,
    onChange,
    checked,
    variant,
    ...props
}: Props) => {
    return (
        <div
            {...props}
            className={cn("ds-tri-state-checkbox", props.className)}
            role="checkbox"
            aria-checked={checked}
            tabIndex={0}
            onClick={(e) => {
                if (!onChange) return;
                onChange();
                e.stopPropagation();
            }}
            onKeyDown={(e) => {
                if (!onChange) return;
                if (e.key === " ") {
                    onChange();
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
        >
            {children}
            <div
                aria-hidden
                className={cn(
                    "checkbox",
                    `checkbox--${checked} checkbox--variant-${variant}`
                )}
            >
                {(checked === "true" || checked === true) && (
                    <Icon path="checkmark" size={12} />
                )}
                {checked === "mixed" && <span className="mixed-icon">-</span>}
            </div>
        </div>
    );
};
export default TriStateCheckbox;
