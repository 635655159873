import { useContext, useMemo } from "react";
import { configStore } from "../../../../contexts/ConfigContext";
import * as localStorage from "../../../../utils/localStorage";
import { DuoSession } from "../../../../interfaces/User";
import { axiosClient } from "../../../../utils/axios-client/axios-client";
import { QueryFunctionContext } from "@tanstack/react-query";
import queryKeys from "./queryKeys";

const useDuoAPIClient = () => {
    const {
        config: { apiUrls },
    } = useContext(configStore);

    const duoAPIClient = useMemo(() => {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem<string>(
                localStorage.Key.TOKEN
            )}`,
            "x-evidenceb-app": "athena",
            "Content-Type": "application/json",
            "x-evb-origin": window.location.origin,
        };

        return {
            async getDuo(
                context: QueryFunctionContext<
                    ReturnType<(typeof queryKeys)["getDuo"]>
                >
            ) {
                const duoId: string = context.queryKey[1];
                const response = await axiosClient.axios.get<DuoSession>(
                    `${apiUrls.endpoints.users}/api/multiplayer-sessions/${duoId}/`,
                    { headers: { Authorization: headers.Authorization } }
                );
                return response.data;
            },
        };
    }, [apiUrls]);
    return duoAPIClient;
};

export default useDuoAPIClient;
