import { useQueries } from "@tanstack/react-query";
import queryKeys from "./queryKeys";
import { isAxiosErrorStatus } from "../../../../utils/api-client-utils";
import { AxiosError } from "axios";
import useDuoAPIClient from "./useDuoApiClient";

export const useDuosQueries = (
    duos: string[] | undefined,
    options?: { enabled?: boolean }
) => {
    const { getDuo } = useDuoAPIClient();
    return useQueries({
        queries:
            duos?.map((duoId) => ({
                queryKey: queryKeys.getDuo(duoId),
                queryFn: getDuo,
                staleTime: 3 * 60 * 1000,
                retry: (failureCount: number, error: AxiosError) =>
                    failureCount < 3 && !isAxiosErrorStatus(error, 404),
                ...options,
            })) ?? [],
    });
};
