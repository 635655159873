export const BASE_ROUTE = "auth";

// Update isSemiAutonomousRoute function if changing routes
export const ROUTES = {
    studentAuth: "student",
    teacherPwdChange: "teacher/pwd/:type/:actionToken",
    teacherForgottenPwd: "teacher/pwd",
    teacherAuth: "teacher",
    logoutConfirmation: "logout-confirmation",
    customError: "error/:errorType",
    redirectionQueryLogin: `redirectionquerylogin`,
};

const trimTrailingSlash = (path: string) => {
    while (path && path[path.length - 1] === "/") path = path.slice(0, -1);
    return path;
};

export const isSemiAutonomousRoute = (_location?: string) => {
    const location = trimTrailingSlash(_location ?? window.location.pathname);
    if (location.endsWith(BASE_ROUTE)) return true;
    for (const route of Object.values(ROUTES)) {
        if (route.includes(":")) {
            const routeWithoutParams = trimTrailingSlash(route.split(":")[0]);
            if (location.includes(routeWithoutParams)) return true;
        } else if (location.endsWith(route)) return true;
    }
    return false;
};
